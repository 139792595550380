import React, { useMemo } from 'react'
import { Box, Row, Column, Badge, Spinner } from 'src/ui'
import * as utils from 'src/utils'
import * as deliveryUtils from 'src/deliveries/utils'
import Images, { ImageDropzone } from 'src/deliveries/images'
import { SubscriptionRow } from 'src/billing/shared/subscription-row'
import { useConfig } from 'src/utils/config'
import { useCustomerBillingById } from 'src/queries'

interface DetailsProps {
  delivery: Delivery
}

export default function DeliveryDetails({ delivery }: DetailsProps) {
  const { clientConfig, text } = useConfig()
  const usesSubscriptionBilling = deliveryUtils.isTaskSubscription(delivery)
  const subscriptionId = usesSubscriptionBilling
    ? delivery.service_variant_id?.replace('subscription#', '')
    : null

  const billingQuery = useCustomerBillingById({
    customerId: delivery.customer_id,
    enabled: !!delivery.customer_id && !!subscriptionId,
  })

  const customerSubscriptions = billingQuery.data?.subscriptions

  const subscription = useMemo(() => {
    if (!customerSubscriptions || customerSubscriptions.length === 0) return null

    return customerSubscriptions.find((s) => s.id === subscriptionId)
  }, [subscriptionId, customerSubscriptions])

  return (
    <Column>
      <Row alignItems="flex-end" justifyContent="space-between" fontSize={16} fontWeight={500}>
        <Column>
          <Box mb={2} fontSize={14}>
            {delivery.driver_name}
            <span css={{ paddingLeft: 8, fontSize: 12 }}>{delivery.vehicle_name}</span>
          </Box>

          <Box fontSize={20}>
            {delivery.price ? utils.formatMoney(delivery.price) : '$ -'}
            {/* {clientConfig('features.tasks.price_recurring') && delivery.price_recurring ? (
              <span css={{ paddingLeft: 8, fontSize: 14 }}>
                (Recurs at {utils.formatMoney(delivery.price_recurring)})
              </span>
            ) : (
              ''
            )} */}

            {clientConfig('task_attrs', []).map((attr) => {
              if (!delivery[attr.name]) return null

              return (
                <Badge key={attr.name} ml={2} colorScheme={attr.color}>
                  {attr.label}
                </Badge>
              )
            })}
          </Box>
        </Column>

        <Column alignItems="flex-end">
          <Row mb={2} fontSize={14}>
            {utils.formatLocal(delivery.starts_at, 'ddd, MMMM D')}
          </Row>

          <Row fontSize={20}>
            {utils.formatLocal(delivery.starts_at, 'h:mm')}
            {' - '}
            {utils.formatLocal(delivery.ends_at, 'h:mm A')}
          </Row>
        </Column>
      </Row>

      {delivery.invoice && (
        <Row alignItems="flex-end" fontSize={14} mt={1}>
          <Box>
            <strong>Invoice</strong> {delivery.invoice}
          </Box>
        </Row>
      )}

      {usesSubscriptionBilling && (
        <>
          {billingQuery.isLoading && <Loading />}
          {billingQuery.isSuccess && !!subscription && (
            <Box mt={4}>
              <SubscriptionRow title="Using Subscription" subscription={subscription} />
            </Box>
          )}
        </>
      )}

      <Box my={3} height="1px" borderBottom="1px solid #eee" />

      {!clientConfig('features.tasks.single_address') && (
        <>
          <Row mb={1} justifyContent="space-between" alignItems="center">
            <Box fontSize={16} color="#888" fontWeight={500}>
              {text('task_form.vendor_label')}
            </Box>

            <Box fontSize={16} fontWeight={500}>
              {delivery.vendor_name}
            </Box>
          </Row>

          <Row justifyContent="space-between">
            <Column>
              <Box fontSize={16}>
                {delivery.pickup_address && delivery.pickup_address.split(',').slice(0, 1)}
                <br />
                {delivery.pickup_address && delivery.pickup_address.split(',').slice(1).join(', ')}
              </Box>
            </Column>

            <Column justifyContent="flex-end" alignItems="flex-end">
              <Box>{delivery.vendor_contact}</Box>
              <Box>{utils.prettyPhoneNumber(delivery.vendor_phone)}</Box>
            </Column>
          </Row>

          <Row pt={3}>{delivery.description}</Row>

          <Box my={3} height="1px" borderBottom="1px solid transparent" />
        </>
      )}

      <Row mb={1} justifyContent="space-between" alignItems="center">
        <Box fontSize={16} color="#888" fontWeight={500}>
          {text('task_form.customer_label')}
        </Box>

        <Box fontSize={16} fontWeight={500}>
          {delivery.customer_name}
        </Box>
      </Row>

      <Row justifyContent="space-between">
        <Column>
          <Box fontSize={16}>
            {delivery.delivery_address && delivery.delivery_address.split(',').slice(0, 1)}
            <br />
            {delivery.delivery_address && delivery.delivery_address.split(',').slice(1).join(', ')}
          </Box>
        </Column>

        <Column justifyContent="flex-end" alignItems="flex-end">
          <Box>{utils.prettyPhoneNumber(delivery.customer_phone)}</Box>
          <Box>{delivery.customer_email}</Box>
        </Column>
      </Row>

      <Box pt={3} mb={2}>
        {delivery.instructions}
      </Box>

      <Divider />

      <Row mb={1} justifyContent="space-between" alignItems="center">
        <Box fontSize={16} color="#888" fontWeight={500}>
          {!delivery.images?.length && 'No'} Images
        </Box>

        <Box>
          <ImageDropzone deliveryId={delivery.id} showEmptyState={false} />
        </Box>
      </Row>

      {!!delivery.images?.length && <Images delivery={delivery} />}
    </Column>
  )
}

function Divider() {
  return <Box height="1px" my={4} borderBottom="1px solid #eee" />
}

function Loading() {
  return (
    <Row w="100%" mt={8} justifyContent="center">
      <Spinner speed="0.65s" size="md" />
    </Row>
  )
}

// function Error() {
//   return (
//     <Row w="100%" mt="20vh" justifyContent="center">
//       There was an error loading billing information.
//     </Row>
//   )
// }
