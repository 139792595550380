import React, { useEffect, useRef, FC } from 'react'
import { RouteComponentProps } from '@reach/router'
import { Formik, Form, Field, FormikProps } from 'formik'
import * as Yup from 'yup'
import useAuth from 'src/store/auth'
import { Box, Column, Row, Button, FormikElements, Heading } from 'src/ui'
import { useConfig } from 'src/utils/config'

const LoginSchema = Yup.object().shape({
  email: Yup.string().required('Required'),
  password: Yup.string().required('Required'),
})

interface FormValues {
  email: string
  password: string
}

const Login: FC<RouteComponentProps> = () => {
  let authenticate = useAuth((state) => state.authenticate)
  let emailRef = useRef(null)
  const { text } = useConfig()

  useEffect(() => {
    if (emailRef.current) emailRef.current.focus()
  }, [])

  return (
    <Column height="100vh" justifyContent="center" alignItems="center">
      <Box
        d="flex"
        flexDirection="column"
        width="100%"
        maxWidth="400px"
        p={6}
        bg="white"
        boxShadow="md"
        borderRadius={3}
      >
        <Heading mb={8} textAlign="center">
          {text('app_title')}
        </Heading>

        <Formik
          validateOnBlur={false}
          initialValues={{ email: '', password: '' }}
          validationSchema={LoginSchema}
          onSubmit={async (
            { email, password }: FormValues,
            formikActions: FormikProps<FormValues>
          ) => {
            let [err] = await authenticate({ email, password })

            if (err) {
              formikActions.setSubmitting(false)
              formikActions.setFieldError('password', 'Invalid email or password')
            }
          }}
        >
          {(formikBag: FormikProps<FormValues>) => (
            <Form>
              <Column>
                <Box height="95px" mb={2}>
                  <Field
                    label="Email"
                    name="email"
                    id="email"
                    autoComplete="off"
                    component={FormikElements.Input}
                    inputRef={(ref) => (emailRef.current = ref)}
                  />
                </Box>
                <Box height="95px" mb={0}>
                  <Field
                    label="Password"
                    name="password"
                    id="password"
                    type="password"
                    autoComplete="off"
                    component={FormikElements.Input}
                  />
                </Box>

                <Row justifyContent="space-between" alignItems="center">
                  <Button variant="link" size="xs">
                    Forgot Password
                  </Button>

                  <Button
                    type="submit"
                    variant="solid"
                    colorScheme="purple"
                    isLoading={formikBag.isSubmitting}
                  >
                    Log In
                  </Button>
                </Row>
              </Column>
            </Form>
          )}
        </Formik>
      </Box>
    </Column>
  )
}

export default Login
