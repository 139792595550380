import React, { useState } from 'react'
import { Row, Button, Heading, Spinner } from 'src/ui'
import { useClientBilling } from 'src/queries'
import { BillingActivity } from 'src/billing/shared/billing-activity'
import { Subscriptions } from 'src/billing/shared/subscriptions'
import { LS_KEYS, getLocalStorageKey } from 'src/utils/local-storage'
import * as api from 'src/api'

const API_BASE_URL = api.apiUrl()

interface CustomerBillingProps {
  customer: Customer
}

export default function CustomerBilling({ customer }: CustomerBillingProps) {
  const billingQuery = useClientBilling()

  if (billingQuery.isLoading) return <Loading />
  if (billingQuery.isError) return <Error />

  const { subscriptions, activity } = billingQuery.data ?? {}
  const hasStartedBilling = !!customer.billing_status || activity?.length > 0

  return (
    <>
      <Row justifyContent="space-between" alignItems="center" mb={4}>
        <Heading fontWeight={400} fontSize="2xl">
          Billing
        </Heading>

        {hasStartedBilling && customer.billing_status === 'active' && customer.stripe_id && (
          <UpdatePaymentMethodButton customerId={customer.id} />
        )}
      </Row>

      {!hasStartedBilling && (
        <Row mb={4} color="gray.600" fontStyle="italic">
          Billing not enabled yet, please call us for further assistance.
        </Row>
      )}

      {/* {hasStartedBilling && customer.billing_status === 'active' && (
        <Row my={4} color="gray.600" fontStyle="italic">
          Your service is currently being set up. Please check back soon.
        </Row>
      )} */}

      {hasStartedBilling && subscriptions.length > 0 && (
        <Subscriptions {...{ subscriptions, allowCancel: false }} />
      )}

      {hasStartedBilling && <BillingActivity items={activity} allowActions />}
    </>
  )
}

function UpdatePaymentMethodButton({ customerId }) {
  const [isLoading, setIsLoading] = useState(false)
  const storedAuth = getLocalStorageKey(LS_KEYS.AUTH)

  return (
    <form
      action={`${API_BASE_URL}/client/billing/setup-session`}
      method="POST"
      onSubmit={() => setIsLoading(true)}
    >
      <input type="hidden" name="authorization" value={storedAuth?.token} />
      <Button type="submit" size="xs" colorScheme="purple" isLoading={isLoading}>
        Update Payment Method
      </Button>
    </form>
  )
}

function Loading() {
  return (
    <Row w="100%" mt="20vh" justifyContent="center">
      <Spinner speed="0.65s" size="xl" />
    </Row>
  )
}

function Error() {
  return (
    <Row w="100%" mt="20vh" justifyContent="center">
      There was an error loading billing information.
    </Row>
  )
}
