import React from 'react'
import { Box, BoxProps, forwardRef } from '@chakra-ui/react'

const Row: React.FC<BoxProps> = forwardRef((props, ref) => <Box {...props} ref={ref} />)

Row.defaultProps = {
  display: 'flex',
}

Row.displayName = 'Row'

export default Row
