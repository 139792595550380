import React from 'react'
import { css } from '@emotion/core'
import { Formik, Form, Field, FormikProps } from 'formik'
import * as Yup from 'yup'
import { Box, Row, Column, Button, ButtonGroup, FormikElements, Modal } from 'src/ui'
import { useMutationHandlers } from 'src/utils/queries'

interface FormValues {
  name: string
}

const LoginSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
})

interface FormProps {
  isOpen: boolean
  obj?: Vehicle
  saveObject: any
  onDismiss: () => void
}

const VehicleForm: React.SFC<FormProps> = ({ isOpen, obj, saveObject, onDismiss }) => {
  let dialogRef = React.useRef(null)
  let { onSuccess, onError } = useMutationHandlers({
    invalidateQueries: ['vehicles'],
    successMessage: 'Vehicle Saved',
    errorMessage: 'Failed to save vehicle',
  })

  return (
    <>
      <Formik
        initialValues={{ name: obj?.name || '' }}
        validationSchema={LoginSchema}
        enableReinitialize
        onSubmit={async (values: FormValues, formikActions: FormikProps<FormValues>) => {
          await saveObject(
            {
              id: obj?.id,
              name: values.name,
            },
            {
              onSuccess: (...args) => {
                formikActions.resetForm()
                onDismiss()
                // @ts-ignore
                onSuccess()
              },
              onError: () => {
                // @ts-ignore
                onError()
              },
              onSettled: () => {
                formikActions.setSubmitting(false)
              },
            }
          )
        }}
      >
        {(formikBag: FormikProps<FormValues>) => (
          <Modal
            isOpen={isOpen}
            title={obj?.id ? 'Update Vehicle' : 'Create Vehicle'}
            onClose={() => {
              formikBag.resetForm()
              onDismiss()
            }}
            footer={
              <ButtonGroup spacing={2}>
                <Button
                  size="sm"
                  variant="ghost"
                  ref={dialogRef}
                  onClick={() => {
                    formikBag.resetForm()
                    onDismiss()
                  }}
                >
                  Cancel
                </Button>

                <Button
                  size="sm"
                  colorScheme="purple"
                  isLoading={formikBag.isSubmitting}
                  onClick={formikBag.submitForm}
                >
                  Save
                </Button>
              </ButtonGroup>
            }
          >
            <Form>
              <Column width="400px">
                <Row justifyContent="flex-end" alignItems="center" pb={4}>
                  {obj?.created_at && (
                    <Box>
                      <TimeLabel
                        label="Created"
                        value={new Date(obj?.created_at).toLocaleDateString()}
                      />
                      {obj?.updated_at && (
                        <>
                          &nbsp;&nbsp;-&nbsp;&nbsp;
                          <TimeLabel
                            label="Updated"
                            value={new Date(obj?.updated_at).toLocaleDateString()}
                          />
                        </>
                      )}
                    </Box>
                  )}
                </Row>
                <Box height="72px" mb={2}>
                  <Field
                    label="Name"
                    name="name"
                    id="name"
                    size="sm"
                    component={FormikElements.Input}
                  />
                </Box>
              </Column>
            </Form>
          </Modal>
        )}
      </Formik>
    </>
  )
}

export default VehicleForm

///////////////////////////////////////////////////////////////////////////////

const TimeLabel = ({ label, value }) => {
  return (
    <small>
      <span
        css={css`
          color: '#777';
        `}
      >
        {label}
      </span>{' '}
      {value}
    </small>
  )
}
