import React from 'react'
import { Formik, Form, Field, FormikProps } from 'formik'
import * as Yup from 'yup'
import { queryCache } from 'react-query'
import { Box, Row, Column, Button, ButtonGroup, FormikElements } from 'src/ui'
import { useClientMutations } from 'src/queries'
import { useMutationHandlers } from 'src/utils/queries'
import * as utils from 'src/utils'

const FormSchema = Yup.object().shape({
  name: Yup.string().nullable().required('Required'),
  phone: Yup.string().nullable(),
  email: Yup.string().nullable(),
  address: Yup.string().nullable(),
})

interface FormValues {
  name: string
  phone: string
  email: string
  address: string
}

interface CustomerDetailFormProps {
  customer: Customer
  stopEditing: () => void
}

export function CustomerDetailForm({ customer, stopEditing }: CustomerDetailFormProps) {
  const { updateClient } = useClientMutations()
  const mutationHandlers = useMutationHandlers({
    invalidateQueries: [['customer-billing', { customerId: customer.id }]],
    successMessage: 'Details updated',
    errorMessage: 'There was a problem performing update',
  })

  return (
    <Formik
      initialValues={{
        name: customer?.name || '',
        phone: customer?.phone ? utils.parseToNationalNumber(customer?.phone) : '',
        email: customer?.email || '',
        address: customer?.address || '',
      }}
      validationSchema={FormSchema}
      enableReinitialize
      onSubmit={async (values: FormValues, formikActions: FormikProps<FormValues>) => {
        await updateClient(
          {
            name: values.name,
            phone: utils.e164PhoneNumber(values.phone),
            email: values.email,
            address: values.address,
          },
          {
            onSuccess: async (customer: Customer, _args) => {
              queryCache.setQueryData('me', customer)
              formikActions.resetForm()
              stopEditing()
            },
            onError: mutationHandlers.onError,
            // onSettled: () => formikActions.setSubmitting(false),
          }
        )
      }}
    >
      {(formikBag: FormikProps<FormValues>) => (
        <>
          <Form>
            <Column maxW={400}>
              <Box height="72px" mb={2}>
                <Field
                  id="name"
                  name="name"
                  label="Name"
                  size="sm"
                  component={FormikElements.Input}
                />
              </Box>

              <Box height="72px" mb={2}>
                <Field
                  id="email"
                  name="email"
                  label="Email"
                  size="sm"
                  component={FormikElements.Input}
                />
              </Box>

              <Box height="72px" mb={2} mr={2}>
                <Field
                  id="phone"
                  name="phone"
                  label="Phone"
                  size="sm"
                  component={FormikElements.PhoneNumber}
                />
              </Box>

              <Box height="72px" mb={2}>
                <Field
                  id="address"
                  name="address"
                  label="Address"
                  size="sm"
                  component={FormikElements.Input}
                />
              </Box>

              {/*
              <Box height="72px" mb={2}>
                <Field
                  id="address"
                  name="address"
                  label="Address"
                  component={FormikElements.SelectCreatableAsync}
                  selectProps={{
                    width: '100%',
                    loadOptions: addressLoadOptions,
                  }}
                />
              </Box> */}
            </Column>

            <Row maxW={400} justifyContent="flex-end">
              <ButtonGroup spacing={2}>
                <Button size="sm" variant="ghost" onClick={() => stopEditing()}>
                  Cancel
                </Button>

                <Button
                  size="sm"
                  colorScheme="purple"
                  isLoading={formikBag.isSubmitting}
                  onClick={formikBag.submitForm}
                >
                  Update account
                </Button>
              </ButtonGroup>
            </Row>
          </Form>
        </>
      )}
    </Formik>
  )
}
