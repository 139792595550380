import React from 'react'
import Color from 'color'
import { Box } from 'src/ui'

interface AvatarProps {
  user: Partial<User>
  hasLetters?: boolean
  size?: number
  icon?: React.ReactNode
  onClick?: () => void
}

// const url = ({ avatar_color = '8888FC', name }) =>
//   `https://ui-avatars.com/api/?background=${avatar_color}&color=fff&bold=true&rounded=true&name=${name}`

const Tooltip = ({ children, label: _label }) => children

const letters = (name) => {
  if (!name) return ''
  if (name.length <= 2) return name
  if (name.split(' ').length === 1) return name.slice(0, 2)
  if (name.split(' ')[1] === '') return name.slice(0, 2)
  return name.split(' ')[0][0] + name.split(' ')[1][0]
}

const Avatar: React.FC<AvatarProps> = ({ user, size = 24, hasLetters = true, icon, onClick }) => {
  let backgroundColor = `#${user.avatar_color || '8888FC'}`
  let bgColor = Color(backgroundColor)

  return (
    <Tooltip label={user.name}>
      <Box
        css={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: size,
          height: size,
          borderRadius: '50%',
          color: bgColor.luminosity() > 0.5 ? '#666' : '#fff',
          backgroundColor,
          fontSize: 12,
          fontWeight: 500,
          cursor: !!onClick ? 'pointer' : undefined,
          textTransform: 'uppercase',
        }}
        onClick={onClick}
      >
        {icon || (hasLetters && letters(user.name))}
      </Box>
    </Tooltip>
  )
}

export default Avatar
