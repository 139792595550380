import React, { useState, useCallback, useRef, useEffect } from 'react'
import { RouteComponentProps } from '@reach/router'
import { queryCache } from 'react-query'
import CustomerList from 'src/manage/customers/list'
import { CustomerSidebar } from 'src/manage/customers/sidebar'
import { DeleteDialog } from 'src/manage/dialogs'
import { useCustomerMutations } from 'src/queries'
import { useMutationHandlers } from 'src/utils/queries'
import { useDisclosure } from 'src/ui'

const Customers: React.FC<RouteComponentProps> = () => {
  const [sidebarId, setSidebarId] = useState<string>()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { createCustomer, updateCustomer, deleteCustomer: _deleteCustomer } = useCustomerMutations()
  const { deleteDialog, setDeleteDialog, resetDeleteDialog, deleteCustomer } = useDeleteDialog({
    deleteCustomer: _deleteCustomer,
  })

  const startCreate = useCallback(() => {
    setSidebarId('new')
    onOpen()
  }, [onOpen])

  const startEdit = useCallback(
    (user) => {
      queryCache.setQueryData(['customers', { id: user.id }], user)
      setSidebarId(user.id)
      onOpen()
    },
    [onOpen]
  )

  const startDelete = useCallback(
    (obj) => setDeleteDialog((state) => ({ ...state, obj })),
    [setDeleteDialog]
  )

  return (
    <>
      <CustomerList {...{ startCreate, startEdit, startDelete }} />

      <CustomerSidebar
        {...{ sidebarId, isOpen, updateCustomer, createCustomer, setSidebarId }}
        onDismiss={() => {
          setSidebarId(null)
          onClose()
        }}
      />

      <DeleteDialog
        isOpen={!!deleteDialog.obj}
        isLoading={deleteDialog.fetching}
        title="Delete Customer"
        body={
          <>Are you sure you want to delete "{deleteDialog.obj ? deleteDialog.obj.name : ''}"?</>
        }
        onDismiss={resetDeleteDialog}
        onConfirm={async () => {
          setDeleteDialog((state) => ({ ...state, fetching: true }))

          await deleteCustomer(deleteDialog.obj.id)

          resetDeleteDialog()
        }}
      />
    </>
  )
}

export default Customers

///////////////////////////////////////////////////////////////////////////////////////////////////

const DIALOG_INIT = { fetching: false, obj: null }

export const useDeleteDialog = ({ deleteCustomer }) => {
  const isMountedRef = useRef(true)
  const [deleteDialog, setDeleteDialog] = useState(DIALOG_INIT)

  useEffect(() => {
    return () => (isMountedRef.current = false)
  }, [])

  const deleteMutationHandlers = useMutationHandlers({
    invalidateQueries: ['customers'],
    successMessage: 'Customer Deleted',
    errorMessage: 'Failed to delete customer',
  })

  return {
    deleteDialog,
    setDeleteDialog,
    deleteCustomer: async (id: string) => deleteCustomer({ id }, deleteMutationHandlers),
    resetDeleteDialog: () => {
      if (!isMountedRef.current) return
      setDeleteDialog(DIALOG_INIT)
    },
  }
}
