import React from 'react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { ChevronLeftIcon, ChevronRightIcon, ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons'
import { Box, Row, ButtonGroup, IconButton } from 'src/ui'
import { Button, Menu, MenuButton, MenuList, MenuOptionGroup, MenuItemOption, Text } from 'src/ui'

const Pagination = ({
  // mine
  totalCount,
  preFilterCount,
  isFiltered = false,
  allowSelectPageSize,

  // column selection
  showColumnSelection = false,
  columns,
  hiddenColumns,
  setHiddenColumns,

  // react-table
  canPreviousPage,
  canNextPage,
  pageOptions,
  pageCount,
  gotoPage,
  nextPage,
  previousPage,
  setPageSize,
  pageIndex,
  pageSize,
}: any) => {
  let hasPages = pageOptions.length > 1

  return (
    <Row
      alignItems="center"
      py={2}
      px={4}
      bg="white"
      borderBottomLeftRadius={3}
      borderBottomRightRadius={3}
      boxShadow="sm"
    >
      {allowSelectPageSize && (hasPages || totalCount > 10) && (
        <Box color="gray.500">
          <PageSizeControls pageSize={pageSize} setPageSize={setPageSize} />
        </Box>
      )}

      {showColumnSelection && (
        <Box color="gray.500">
          <ColumnSelection {...{ columns, hiddenColumns, setHiddenColumns }} />
        </Box>
      )}

      <Box flex={1} />

      <ItemCount isFiltered={isFiltered} totalCount={totalCount} preFilterCount={preFilterCount} />

      {hasPages && (
        <PagingControls
          pageOptions={pageOptions}
          pageIndex={pageIndex}
          pageCount={pageCount}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          previousPage={previousPage}
          nextPage={nextPage}
          gotoPage={gotoPage}
        />
      )}

      {/* <GoToPageControl pageIndex={pageIndex} gotoPage={gotoPage} /> */}
    </Row>
  )
}

export default Pagination

///////////////////////////////////////////////////////////////////////////////

const ItemCount = ({ isFiltered = false, totalCount, preFilterCount }: any) => (
  <Box px={2} color="gray.500" fontWeight={600}>
    {isFiltered ? (
      <>
        Filtered to {totalCount} items from {preFilterCount}
      </>
    ) : (
      <>{totalCount} Items</>
    )}
  </Box>
)

const PagingControls = ({
  pageOptions,
  pageIndex,
  pageCount,
  canPreviousPage,
  canNextPage,
  previousPage,
  nextPage,
  gotoPage,
}) => (
  <>
    <Box px={2} color="gray.500" fontWeight={500}>
      {`Page ${pageIndex + 1} of ${pageOptions.length}`}
    </Box>

    <ButtonGroup spacing={1} color="gray.500">
      <IconButton
        variant="outline"
        // colorScheme="gray"
        size="sm"
        icon={<ArrowLeftIcon />}
        aria-label="First"
        fontSize="0.8rem"
        isDisabled={!canPreviousPage}
        onClick={() => gotoPage(0)}
      />

      <IconButton
        variant="outline"
        // colorScheme="gray"
        size="sm"
        icon={<ChevronLeftIcon />}
        aria-label="Previous"
        fontSize="1.5rem"
        isDisabled={!canPreviousPage}
        onClick={() => previousPage()}
      />

      <IconButton
        variant="outline"
        // colorScheme="gray"
        size="sm"
        icon={<ChevronRightIcon />}
        aria-label="Next"
        fontSize="1.5rem"
        isDisabled={!canNextPage}
        onClick={() => nextPage()}
      />

      <IconButton
        variant="outline"
        // colorScheme="gray"
        size="sm"
        icon={<ArrowRightIcon />}
        aria-label="First"
        fontSize="0.8rem"
        isDisabled={!canNextPage}
        onClick={() => gotoPage(pageCount - 1)}
      />
    </ButtonGroup>
  </>
)

const PageSizeControls = ({
  pageSize,
  setPageSize,
}: {
  pageSize: number
  setPageSize: (v: number) => void
}) => (
  <Menu closeOnSelect>
    <MenuButton
      as={Button}
      {...{
        size: 'sm',
        rightIcon: <ChevronDownIcon />,
        variant: 'ghost',
      }}
    >
      Show {pageSize}
    </MenuButton>

    <MenuList
      width="100px"
      minWidth="100px"
      maxHeight="300px"
      overflow="auto"
      placement="bottom-end"
    >
      <MenuOptionGroup
        // title="Vendor"
        type="radio"
        value={String(pageSize)}
        onChange={(value) => setPageSize(Number(value))}
      >
        {[10, 25, 50, 100].map((pageSize) => (
          <MenuItemOption key={pageSize} value={String(pageSize)}>
            <Text>{pageSize}</Text>
          </MenuItemOption>
        ))}
      </MenuOptionGroup>
    </MenuList>
  </Menu>
)

const ColumnSelection = ({ width = 150, columns, hiddenColumns = [], setHiddenColumns }: any) => {
  const visibleColumns = columns.filter((c) => !hiddenColumns.includes(c.id)).map((c) => c.id)

  return (
    <Menu closeOnSelect={false}>
      <MenuButton
        as={Button}
        {...{
          size: 'sm',
          rightIcon: <ChevronDownIcon />,
          variant: 'ghost',
        }}
      >
        Columns
      </MenuButton>

      <MenuList
        width={width}
        minWidth={width}
        maxHeight="300px"
        overflow="auto"
        placement="bottom-end"
      >
        <MenuOptionGroup
          type="checkbox"
          value={visibleColumns}
          onChange={(value) => {
            setHiddenColumns(columns.filter((c) => !value.includes(c.id)).map((c) => c.id))
          }}
        >
          {columns
            .filter((column) => !!column.Header)
            .map((column) => ({ id: column.id, name: column.Header }))
            .map((item) => (
              <MenuItemOption key={item.id} value={item.id}>
                <Text maxWidth={width - 55} isTruncated>
                  {item.name}
                </Text>
              </MenuItemOption>
            ))}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  )
}

// const GoToPageControl = ({ pageIndex, gotoPage }) => (
//   <span>
//     | Go to page:
//     <input
//       type="number"
//       defaultValue={pageIndex + 1}
//       onChange={(e) => {
//         const page = e.target.value ? Number(e.target.value) - 1 : 0
//         gotoPage(page)
//       }}
//       style={{ width: '100px' }}
//     />
//   </span>
// )
