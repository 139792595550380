import React from 'react'
import ReactDatePicker from 'react-datepicker'
import { Input } from '@chakra-ui/react'

interface DatePickerProps {
  id: string
  name: string
  value: Date
  onChange: any
  onBlur?: any
  disabled?: boolean
  hasError?: boolean
  dateFormat?: string
  timeFormat?: string
  showTimeSelect?: boolean
  timeCaption?: string
  timeIntervals?: number
  inputProps?: any
  popperPlacement?: any
}

const DatePicker: React.FC<DatePickerProps> = ({
  id,
  name,
  value,
  onChange,
  onBlur,
  disabled = false,
  hasError = false,
  dateFormat = 'EEE - MMM d, yyyy - h:mm aa',
  timeFormat = 'hh:mm aa',
  timeCaption = null,
  timeIntervals = 30,
  showTimeSelect = false,
  popperPlacement,
  inputProps,
}) => {
  return (
    <ReactDatePicker
      id={id}
      name={name}
      selected={value}
      ref={(el) => {
        // actually make the input readOnly
        // https://github.com/Hacker0x01/react-datepicker/issues/1480#issuecomment-455125776
        if (el && el.input) el.input.readOnly = true
      }}
      popperPlacement={popperPlacement}
      customInput={
        <Input
          variant="outline"
          focusBorderColor="primary"
          isReadOnly
          isDisabled={disabled}
          borderColor={hasError ? 'red' : 'inherit'}
          onBlur={onBlur}
          {...inputProps}
        />
      }
      onChange={onChange}
      onBlur={onBlur}
      showTimeSelect={showTimeSelect}
      dateFormat={dateFormat}
      timeFormat={timeFormat}
      timeCaption={timeCaption}
      timeIntervals={timeIntervals}
    />
  )
}

export default DatePicker
