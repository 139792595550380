import moment, { Moment } from 'moment-timezone'
import { momentLocalizer } from 'react-big-calendar'
import * as utils from 'src/utils'

export const localizer = momentLocalizer(moment)

///////////////////////////////////////////////////////////////////////////////////////////////////

export const driverDaySchedule = ({ date: mDate, driver }) => {
  if (!driver.schedule) return 'off'

  let event = driver.events.find((e) => mDate.isSame(moment.utc(e.date), 'day'))
  let value = event ? event.type : driver.schedule[mDate.day()] ? 'working' : 'off'

  return value
}

///////////////////////////////////////////////////////////////////////////////////////////////////

export const mapEvents = ({ drivers, list, view }) => {
  return (
    [...list]
      // Somehow they were coming back un-ordered from dynamo?
      .sort((a, b) => (a.starts_at < b.starts_at ? -1 : 1))
      .map((item) => {
        return {
          ...item,
          view,
          driver: drivers.find((d) => d.id === item.driver_id),
          title: `${item.vendor_name} - ${item.customer_name}`,
          allDay: false,
          // RBC timezone nonsense: https://github.com/jquense/react-big-calendar/issues/118
          start: utils.utcStringToLocalDate(item.starts_at),
          end: utils.utcStringToLocalDate(item.ends_at),
        }
      })
  )
}

export const mapResources = (drivers: User[], schedules: Schedule[]) => {
  if (!drivers || !schedules) return []

  return drivers.map((driver) => {
    let sched = schedules.find((s) => s.driver.id === driver.id)

    return {
      driver_id: driver.id,
      driver_name: driver.name,
      avatar_color: driver.avatar_color,
      events: sched ? sched.events : [],
      schedule: sched ? sched.schedule : Array(7).fill(true),
    }
  })
}

const MAX_CALENDAR_HEIGHT = 1000

export const decideHeight = (measured: number) => {
  if (!measured) return 'auto'
  if (measured > MAX_CALENDAR_HEIGHT) return MAX_CALENDAR_HEIGHT
  return measured
}

export const hasDateIntersect = (events, event, start, end): boolean => {
  let thisStart = moment(event.start)
  let thisEnd = moment(event.end)

  return events
    .filter((e) => e.id !== event.id)
    .some(
      (e) =>
        (thisStart.isSameOrAfter(e.start, 'minute') && thisStart.isBefore(e.end, 'minute')) ||
        (thisEnd.isAfter(e.start, 'minute') && thisEnd.isSameOrBefore(e.end, 'minute'))
    )
}

export const eventPropGetter =
  ({ events, resources, clampWidth = true }) =>
  (delivery, start, end) => {
    // let driver = resources.find(r => r.driver_id === delivery.driver_id)
    // let backgroundColor = driver.avatar_color ? `#${driver.avatar_color}` : '#e1dcff'
    // let bgColor = Color(backgroundColor)

    return {
      style: {
        border: 'none',
        borderBottom: '1px solid #ddd',
        // backgroundColor,
        // borderColor: bgColor.desaturate(0.3).hex(),
        // color: bgColor.luminosity() > 0.5 ? '#666' : '#fff',
        color: '#666',
        backgroundColor: '#EDF2F7',
        ...(clampWidth && hasDateIntersect(events, delivery, start, end)
          ? {
              // should be `1 / (number of [visible] drivers)`
              width: '50%',
              minWidth: '50%',
              maxWidth: '50%',
            }
          : {}),
      },
    }
  }

export const createNewObject = ({
  start,
  end,
  driver_id = '',
  drivers = [],
  vehicles = [],
}): Partial<Delivery> => {
  let driver = driver_id ? drivers.find((d) => d.id === driver_id) : null
  let vehicle = driver ? vehicles.find((v) => v.id === driver.default_vehicle_id) : null

  return {
    starts_at: start.utc().format(),
    ends_at: end.utc().format(),
    price: 0,
    is_corporate: false,
    has_return: false,
    has_exchange: false,
    description: '',
    instructions: '',
    driver_id,
    driver_name: driver ? driver.name || '' : '',
    customer_id: '',
    customer_name: '',
    customer_phone: '',
    customer_email: '',
    delivery_address: '',
    vehicle_id: vehicle ? vehicle.id || '' : '',
    vehicle_name: vehicle ? vehicle.name : '',
    vendor_id: '',
    vendor_phone: '',
    pickup_address: '',
  } as Partial<Delivery>
}

export const STEP_FUNCTIONS = {
  day: {
    prev: (date: Moment) => date.clone().subtract(1, 'days'),
    next: (date: Moment) => date.clone().add(1, 'days'),
  },
  '3day': {
    prev: (date: Moment) => date.clone().subtract(1, 'days'),
    next: (date: Moment) => date.clone().add(1, 'days'),
  },
  week: {
    prev: (date: Moment) => date.clone().subtract(7, 'days'),
    next: (date: Moment) => date.clone().add(7, 'days'),
  },
}

///////////////////////////////////////////////////////////////////////////////////////////////////

interface viewAndMarkerArgs {
  view: 'day' | '3day' | 'week' | string
  markerDate: Moment
}

export const viewLabel = ({ view, markerDate }: viewAndMarkerArgs) => {
  // let mDate = moment(date)

  switch (view) {
    case 'day':
      return markerDate.format('ddd, MMM D')

    case '3day':
      return `${markerDate.clone().format('MMM D')} - ${markerDate
        .clone()
        .add(2, 'days')
        .format('D')}`

    case 'week':
      let start = markerDate.clone().startOf('week')
      let end = markerDate.clone().endOf('week')
      let sameMonth = start.isSame(end, 'month')

      return (
        `${start.format('MMM D')} - ` + (sameMonth ? '' : `${end.format('MMM')} `) + end.format('D')
      )

    default:
      return ''
  }
}

export const shouldShowJumpToToday = ({ view, markerDate }: viewAndMarkerArgs) => {
  let now = utils.momentLocalizedParse()

  switch (view) {
    case 'day':
      return !now.isSame(markerDate, 'day')

    case '3day':
      return now.isBefore(markerDate) || now.isAfter(markerDate.clone().add(3, 'days'))

    case 'week':
      return now.isBefore(markerDate) || now.isAfter(markerDate.clone().add(7, 'days'))

    default:
      return false
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////

// export const CustomToolbar = ({ date, label, onNavigate, ...rest }) => {
//   return (
//     <Row id="root-custom-toolbar">
//       <Box>
//         <button
//           onClick={() => {
//             date.setDate(date.getDate() - 1)
//             onNavigate('prev')
//           }}
//         >
//           prev
//         </button>
//         <button
//           onClick={() => {
//             date.setDate(date.getDate() + 1)
//             onNavigate('next')
//           }}
//         >
//           next
//         </button>
//       </Box>

//       <Box px={3}>{label}</Box>
//     </Row>
//   )
// }
