import React from 'react'
import { WarningIcon } from '@chakra-ui/icons'
import { Button, Icon, Stack } from '@chakra-ui/react'
import { Box } from 'src/ui'

export default class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    console.error('[app][error-boundary]', { error, errorInfo })
  }

  render() {
    if (this.state.hasError) return <ErrorMessage />

    return this.props.children
  }
}

const ErrorMessage = () => (
  <Box position="relative" width="100%" height="100vh">
    <Stack spacing={6} width="420px" mt="25%" mx="auto" textAlign="center">
      <Box fontSize="lg" color="red.700">
        <Icon as={WarningIcon} size="4rem" color="red.700" />
      </Box>

      <Box fontSize="2em" color="gray.600">
        Application Error
      </Box>

      <Box>
        <Button colorScheme="gray" onClick={() => window.location.reload()}>
          Reload Page
        </Button>
      </Box>
    </Stack>
  </Box>
)
