import React from 'react'
import { AddIcon, DeleteIcon } from '@chakra-ui/icons'
import { Box, Row, Button, IconButton, Table, useTable, TimeDate } from 'src/ui'

interface ListProps {
  data: TaskDocument[]
  isLoading: boolean
  startCreate: () => void
  startEdit: (obj: any) => void
  startDelete: (args: any) => void
}

const DocumentsList: React.FC<ListProps> = ({
  data,
  isLoading,
  startCreate,
  startEdit,
  startDelete,
}) => {
  let columns = React.useMemo(
    () => [
      {
        id: 'name',
        Header: 'Name',
        accessor: 'name',
      },

      {
        id: 'template',
        Header: 'Template',
        accessor: 'template',
      },
      {
        id: 'created_at',
        Header: 'Created',
        accessor: 'created_at',
        Cell: ({ cell }) => <TimeDate date={cell.value} />,
      },
      {
        Header: 'Updated',
        accessor: 'updated_at',
        id: 'updated_at',
        Cell: ({ cell }) => (cell.value ? <TimeDate date={cell.value} /> : '-'),
      },
      {
        id: '_actions',
        Header: '',
        accessor: '_actions',
        disableSorting: true,
        Cell: ({ row }) => {
          let obj = row.original

          return (
            <Row justifyContent="flex-end">
              <IconButton
                size="sm"
                color="gray.400"
                variant="ghost"
                icon={<DeleteIcon />}
                aria-label="Delete"
                children={null}
                onClick={e => {
                  e.stopPropagation()
                  startDelete(obj)
                }}
              />
            </Row>
          )
        },
      },
    ],
    [startDelete]
  )

  let {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    // rows,
    page,
    pagination,
    totalCount,
  } = useTable({
    data,

    tableOptions: {
      initialState: {
        sortBy: [{ id: 'name' }],
      },
    },

    columns,
  })

  return (
    <>
      <Row alignItems="center" mb={2}>
        <Box flex={1} color="gray.500" px={2}>
          {/* <Heading size="md">Customers</Heading> */}
        </Box>

        <Box>
          <Button
            size="sm"
            color="gray.400"
            variant="ghost"
            leftIcon={<AddIcon />}
            onClick={startCreate}
          >
            New
          </Button>
        </Box>
      </Row>

      <Table {...getTableProps()}>
        <Table.Header headerGroups={headerGroups} />
        <Table.Body
          isLoading={isLoading}
          rows={page}
          getTableBodyProps={getTableBodyProps}
          prepareRow={prepareRow}
          onRowClick={obj => startEdit(obj)}
        />
      </Table>

      {!isLoading && <Table.Pagination totalCount={totalCount} {...pagination} />}
    </>
  )
}

export default DocumentsList
