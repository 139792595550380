import React from 'react'
import { useDropzone } from 'react-dropzone'
import { queryCache } from 'react-query'
import { FiImage, FiMaximize } from 'react-icons/fi'
import { Box, Row, Column, Grid, Button, Spinner } from 'src/ui'
import * as api from 'src/api'

interface ImageDropzoneProps {
  deliveryId: string
  showEmptyState: boolean
}

export const ImageDropzone: React.FC<ImageDropzoneProps> = ({
  deliveryId,
  showEmptyState = false,
}) => {
  let [status, setStatus] = React.useState(api.STATUS.idle)
  let isLoading = status === api.STATUS.loading

  let onDrop = React.useCallback(
    async (acceptedFiles) => {
      setStatus(api.STATUS.loading)

      let reads = acceptedFiles.map((file) => {
        return new Promise((yah, nah) => {
          let reader = new FileReader()

          reader.onabort = () => nah('file reading was aborted')
          reader.onerror = () => nah('file reading has failed')
          reader.onload = () => yah(reader.result)

          reader.readAsBinaryString(file)
        })
      })

      let results = await Promise.all(reads)

      for (let result of results) {
        let [err] = await api.createDeliveryImage({
          id: deliveryId,
          image: window.btoa(result as string),
        })

        if (err) {
          // @todo: problem
          continue
        }

        // let delivery = response.data.data
      }

      await queryCache.invalidateQueries('deliveries')

      setStatus(api.STATUS.idle)
    },
    [deliveryId]
  )

  let { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })
  let showAlt = isDragActive || isLoading

  let rootProps = getRootProps()

  return (
    <Column
      justifyContent="center"
      alignItems="center"
      borderRadius={4}
      color={showAlt ? 'gray.400' : 'gray.500'}
      backgroundColor={showAlt ? 'gray.50' : undefined}
      fontWeight={500}
      cursor={isLoading ? 'wait' : 'pointer'}
      pointerEvents={isLoading ? 'none' : 'auto'}
      {...rootProps}
      onClick={(e) => {
        // chakra v0.8 workaround (https://github.com/chakra-ui/chakra-ui/issues/530)
        rootProps.onClick(e)
        e.stopPropagation()
      }}
    >
      {showEmptyState && (
        <Row justifyContent="center" mb={1}>
          <Row height="56px" justifyContent="center" alignItems="center">
            {isDragActive && <FiMaximize size={50} strokeWidth="1" />}
            {isLoading && <Spinner speed="0.65s" size="lg" />}
            {!showAlt && <FiImage size={50} strokeWidth="1" />}
          </Row>
        </Row>
      )}

      <input {...getInputProps()} />

      <Box
        css={{
          display: 'flex',
          justifyContent: 'center',
          padding: 8,
          // color: '#fff',
          // backgroundColor: isDragActive || isLoading ? lighten(0.1, '#627D98') : '#627D98',
          borderRadius: 2,
          fontWeight: 500,
          cursor: isLoading ? 'wait' : 'pointer',
          pointerEvents: isLoading ? 'none' : 'auto',
        }}
      >
        {isDragActive ? (
          <Row alignItems="center">
            {!showEmptyState && <FiMaximize size="1.1rem" />}
            <Box px={2}>Drop File(s)</Box>
          </Row>
        ) : isLoading ? (
          <Row alignItems="center">
            {!showEmptyState && <Spinner speed="0.65s" size="sm" />}
            <Box px={2}>Uploading</Box>
          </Row>
        ) : (
          <Row alignItems="center">
            {!showEmptyState && <FiImage size="1.1rem" />}
            <Box px={2}>Add Image</Box>
          </Row>
        )}
      </Box>
    </Column>
  )
}

///////////////////////////////////////////////////////////////////////////////

function useImageDelete() {
  let [deletingId, setDeletingId] = React.useState(null)
  let [status, setStatus] = React.useState(api.STATUS.idle)

  const confirmDelete = (image: Image) => {
    setDeletingId(image.id)
    setStatus(api.STATUS.confirming)
  }

  const cancelDelete = () => {
    setDeletingId(null)
    setStatus(api.STATUS.idle)
  }

  const deleteImage = async ({ deliveryId, imageId }) => {
    setStatus(api.STATUS.loading)

    let [err] = await api.deleteDeliveryImage({ deliveryId, imageId })

    if (err) return // @todo: problem

    // @todo: do more surgical reeact-query cache update
    await queryCache.invalidateQueries('deliveries')

    setStatus(api.STATUS.idle)
    setDeletingId(null)
  }

  return {
    status,
    setStatus,
    deletingId,
    setDeletingId,
    deleteImage,
    cancelDelete,
    confirmDelete,
  }
}

interface ImagesProps {
  delivery?: Delivery
}

const Images: React.FC<ImagesProps> = ({ delivery: obj }) => {
  let { status, deletingId, confirmDelete, cancelDelete, deleteImage } = useImageDelete()

  return (
    <Grid templateColumns="repeat(3, 1fr)" alignItems="center" gridGap="1rem">
      {obj.images.map((image) => {
        let isBeingDeleted = deletingId === image.id

        return (
          <Column key={image.id} alignItems="center">
            <Row>
              <a href={image.url_original} target="_blank" rel="noopener noreferrer">
                {/*
                  // this was causing double-requests in chakra-ui v0.8
                  <Image shadow="md" src={image.url_thumbnail} /> */}

                <img
                  src={image.url_thumbnail}
                  alt="Delivery"
                  css={{
                    boxShadow: '0 4px 6px -1px rgba(0,0,0,0.1), 0 2px 4px -1px rgba(0,0,0,0.06)',
                  }}
                />
              </a>
            </Row>

            <Row height="28px" pt={2}>
              {!isBeingDeleted && (
                <Button size="xs" variant="link" onClick={() => confirmDelete(image)}>
                  Delete
                </Button>
              )}

              {isBeingDeleted && status === api.STATUS.loading && (
                <Row color="gray.500">
                  <Spinner speed="0.65s" size="sm" /> &nbsp;Deleting
                </Row>
              )}

              {isBeingDeleted && status === api.STATUS.confirming && (
                <>
                  <Button size="xs" variant="ghost" mr={1} onClick={cancelDelete}>
                    Cancel
                  </Button>

                  <Button
                    size="xs"
                    variant="ghost"
                    colorScheme="red"
                    onClick={() => deleteImage({ deliveryId: obj.id, imageId: image.id })}
                  >
                    Delete
                  </Button>
                </>
              )}
            </Row>
          </Column>
        )
      })}
    </Grid>
  )
}

export default Images
