import React, { useState, useCallback } from 'react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { queryCache } from 'react-query'
import {
  Box,
  Row,
  Button,
  ButtonGroup,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  Fade,
  Menu,
  MenuButton,
  MenuDivider,
  MenuList,
  MenuItem,
  Spacer,
  useToast,
} from 'src/ui'
import { useDeliveryMutations } from 'src/queries'
import DeliveryDetails from 'src/deliveries/delivery-details'
import DeliveryForm from 'src/deliveries/delivery-form'
import { useCalendar } from 'src/store/calendar'
import * as deliveryUtils from 'src/deliveries/utils'
import { TASK_STATUSES, PAYMENT_METHODS } from 'src/utils/const'
import { useConfig } from 'src/utils/config'

export function CalendarSidebar({ isOpen, onClose, header, footer, children }) {
  return (
    <Drawer isOpen={isOpen} placement="left" onClose={onClose} size="md">
      <DrawerOverlay />

      <DrawerContent>
        {header && <DrawerHeader bg="gray.700">{header}</DrawerHeader>}
        <DrawerBody width="100%">{children}</DrawerBody>
        {footer && <DrawerFooter>{footer}</DrawerFooter>}
      </DrawerContent>
    </Drawer>
  )
}

interface ScheduleSidebarProps {
  deliveries: Delivery[]
}

const calendarSelector = ({ delivery, status, closeSidebar, startEditing, stopEditing }) => ({
  delivery,
  status,
  closeSidebar,
  startEditing,
  stopEditing,
})

export default function ScheduleSidebar({ deliveries }: ScheduleSidebarProps) {
  const {
    delivery: storeDelivery,
    status,
    closeSidebar,
    startEditing,
    stopEditing,
  } = useCalendar(calendarSelector)
  const scheduleDeliveryId = storeDelivery?.id
  const delivery = deliveries.find((d) => d.id === scheduleDeliveryId) || storeDelivery

  return (
    <CalendarSidebar
      isOpen={status !== 'idle'}
      onClose={closeSidebar}
      header={<Header {...{ delivery, closeSidebar }} />}
      footer={<Footer {...{ status, closeSidebar, startEditing, stopEditing }} />}
    >
      {!delivery && <Box>Select a delivery</Box>}

      <Fade in={delivery && status === 'info'} unmountOnExit>
        <DeliveryDetails delivery={delivery} />
      </Fade>

      <Fade in={delivery && status === 'edit'} unmountOnExit>
        <DeliveryForm
          {...{
            delivery,
            stopEditing,
          }}
        />
      </Fade>
    </CalendarSidebar>
  )
}

///////////////////////////////////////////////////////////////////////////////

function Header({ delivery, closeSidebar }) {
  const { text } = useConfig()
  const { updateDelivery, deleteDelivery } = useDeliveryMutations()
  const isSubscription = deliveryUtils.isTaskSubscription(delivery)
  const hasStripeBilling = delivery.payment_method === 'stripe'

  return (
    <Row alignItems="center">
      {delivery?.id ? (
        <>
          <DeleteButton
            {...{
              delivery,
              closeSidebar,
              deleteObject: deleteDelivery,
            }}
          />

          <Spacer />

          <Box mr={2}>
            {!isSubscription && !hasStripeBilling && (
              <PaymentStatusMenuButton {...{ obj: delivery, updateObject: updateDelivery }} />
            )}

            {/*
            <Badge py={2} px={4} borderRadius={4}>
              Subscription
            </Badge>
            */}
          </Box>

          <Box>
            <StatusMenuButton {...{ obj: delivery, updateObject: updateDelivery }} />
          </Box>
        </>
      ) : (
        <Box color="white">Create New {text('task_label')}</Box>
      )}
    </Row>
  )
}

///////////////////////////////////////////////////////////////////////////////

function Footer({ status, closeSidebar, startEditing, stopEditing }) {
  return (
    <ButtonGroup spacing={2}>
      {status === 'info' && (
        <>
          <Button width="100px" size="md" variant="ghost" onClick={closeSidebar}>
            Close
          </Button>

          <Button size="md" colorScheme="purple" width="100px" onClick={() => startEditing()}>
            Edit
          </Button>
        </>
      )}

      {status === 'edit' && (
        <>
          <Button width="100px" size="md" variant="ghost" onClick={stopEditing}>
            Cancel
          </Button>

          <Button
            type="submit"
            form="delivery-form"
            width="100px"
            size="md"
            colorScheme="purple"
            // isLoading={formikBag.isSubmitting}
            // onClick={formikBag.submitForm}
          >
            Save
          </Button>
        </>
      )}
    </ButtonGroup>
  )
}

///////////////////////////////////////////////////////////////////////////////

function DeleteButton({ delivery, closeSidebar, deleteObject }) {
  let toast = useToast()
  let [isDeleting, setIsDeleting] = useState(false)
  let [isAttemptingDelete, setIsAttemptingDelete] = useState(false)

  return (
    <>
      {isAttemptingDelete ? (
        <Row alignItems="center">
          <Button
            size="sm"
            // variant="ghost"
            mr={2}
            isDisabled={isDeleting}
            onClick={() => setIsAttemptingDelete(false)}
          >
            Cancel
          </Button>

          <Button
            size="sm"
            width="72px"
            colorScheme="red"
            isLoading={isDeleting}
            isDisabled={isDeleting}
            onClick={async () => {
              setIsDeleting(true)

              await deleteObject(
                { id: delivery.id },
                {
                  onSuccess: async (_response, _args) => {
                    await queryCache.invalidateQueries('deliveries')

                    closeSidebar()

                    toast({
                      title: 'Success',
                      description: 'Delivery Deleted',
                      status: 'success',
                      duration: 4000,
                      isClosable: false,
                      position: 'top',
                    })
                  },

                  onError: async (...args) => {
                    toast({
                      title: 'Whoops',
                      description: 'Unable to delete delivery',
                      status: 'error',
                      duration: 4000,
                      isClosable: false,
                      position: 'top',
                    })
                  },

                  onSettled: async (...args) => setIsDeleting(false),
                }
              )
            }}
          >
            Delete
          </Button>
        </Row>
      ) : (
        <Row alignItems="center">
          <Button size="sm" width="72px" mr={2} onClick={() => setIsAttemptingDelete(true)}>
            Delete
          </Button>
        </Row>
      )}
    </>
  )
}

///////////////////////////////////////////////////////////////////////////////

function PaymentStatusMenuButton({ obj, updateObject }) {
  const { updateObjectHandler, isLoading } = useUpdateObject({ updateObject })

  return (
    <Menu>
      <MenuButton
        as={Button}
        size="sm"
        isLoading={isLoading}
        rightIcon={<ChevronDownIcon />}
        colorScheme={obj.is_paid ? 'green' : undefined}
      >
        {obj.is_paid ? 'Paid' : 'Unpaid'} - {PAYMENT_METHODS[obj.payment_method] || 'Unknown'}
      </MenuButton>

      <MenuList placement="bottom-end" fontSize={14}>
        {!obj.is_paid && (
          <MenuItem onClick={() => updateObjectHandler({ id: obj.id, is_paid: true })}>
            Mark Paid
          </MenuItem>
        )}
        {obj.is_paid && (
          <MenuItem onClick={() => updateObjectHandler({ id: obj.id, is_paid: false })}>
            Mark Unpaid
          </MenuItem>
        )}

        <MenuDivider />

        {Object.keys(PAYMENT_METHODS)
          .filter((method) => method !== obj.payment_method)
          .map((method) => (
            <MenuItem
              key={method}
              onClick={() => updateObjectHandler({ id: obj.id, payment_method: method })}
            >
              {PAYMENT_METHODS[method]}
            </MenuItem>
          ))}
      </MenuList>
    </Menu>
  )
}

///////////////////////////////////////////////////////////////////////////////

function StatusMenuButton({ obj, updateObject }) {
  const { updateObjectHandler, isLoading } = useUpdateObject({ updateObject })

  return (
    <Menu>
      <MenuButton as={Button} size="sm" rightIcon={<ChevronDownIcon />} isLoading={isLoading}>
        {TASK_STATUSES[obj.status]}
      </MenuButton>

      <MenuList placement="bottom-end" fontSize={14}>
        {Object.keys(TASK_STATUSES)
          .filter((status) => status !== obj.status)
          .map((status) => (
            <MenuItem key={status} onClick={() => updateObjectHandler({ id: obj.id, status })}>
              {TASK_STATUSES[status]}
            </MenuItem>
          ))}
      </MenuList>
    </Menu>
  )
}

function useUpdateObject({ updateObject }) {
  const toast = useToast()
  const [isLoading, setIsLoading] = useState(false)

  const updateObjectHandler = useCallback(
    async (args) => {
      setIsLoading(true)

      await updateObject(args, {
        onSuccess: async (_response, _args) => {
          await queryCache.invalidateQueries('deliveries')

          toast({
            title: 'Success',
            description: 'Delivery Updated',
            status: 'success',
            duration: 4000,
            isClosable: false,
            position: 'top',
          })
        },

        onError: async (...args) => {
          toast({
            title: 'Whoops',
            description: 'Unable to save delivery',
            status: 'error',
            duration: 4000,
            isClosable: false,
            position: 'top',
          })
        },

        onSettled: async (...args) => setIsLoading(false),
      })
    },
    [toast, updateObject, setIsLoading]
  )

  return { updateObjectHandler, isLoading, setIsLoading }
}
