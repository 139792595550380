import React, { useEffect, useCallback } from 'react'
import { Box, Badge, Heading, Table, useTable } from 'src/ui'
import { caseInsensitiveSort } from 'src/ui/table/helpers'
import * as utils from 'src/utils'
import { formatDate, formatTime } from 'src/deliveries/utils'
import { useSettings } from 'src/utils/settings'
import { useConfig } from 'src/utils/config'

interface DeliveryListProps {
  isLoading: boolean
  deliveries: Delivery[]
  selectedVendors: string[]
}

export default function DeliveryList({
  isLoading,
  deliveries,
  selectedVendors,
}: DeliveryListProps) {
  const { text } = useConfig()
  const columns = useColumns()

  return (
    <>
      <Heading size="md" color="gray.600">
        {text('task_labe_plural')}
      </Heading>

      <DeliveryTable
        columns={columns}
        isLoading={isLoading}
        data={deliveries || []}
        selectedVendors={selectedVendors}
        // maxHeight={maxHeight - 148}
        maxHeight="500px"
      />
    </>
  )
}

///////////////////////////////////////////////////////////////////////////////////////////////////

const nullAwareAccessor = (key) => (row) => row[key] || undefined
const filterVendors = (rows: any[], _id: string, filterValue: string[]) =>
  rows.filter((row) => filterValue.includes(row.original.vendor_id))

const DeliveryTable = ({ columns, isLoading, data, selectedVendors, maxHeight }) => {
  const { get: getSetting, set: setSetting } = useSettings()
  const hiddenColumns = getSetting('deliveries.delivery_table.hidden', [])

  let {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    // rows,
    page,
    pagination,
    setFilter,
    setHiddenColumns,
    totalCount,
  } = useTable({
    columns,
    data,
    tableOptions: {
      initialState: {
        sortBy: [{ id: 'date', desc: true }],
        hiddenColumns,
      },
      autoResetFilters: false,
    },
  })

  const _setHiddenColumns = useCallback(
    (columnIds: string[]) => {
      setHiddenColumns(columnIds)
      setSetting('deliveries.delivery_table.hidden', columnIds)
    },
    [setHiddenColumns, setSetting]
  )

  useEffect(() => {
    setFilter('vendor_name', selectedVendors.length > 0 ? selectedVendors : undefined)
  }, [selectedVendors, setFilter])

  return (
    <Box>
      <Table.ScrollableWrapper maxHeight={maxHeight}>
        <Table {...getTableProps()}>
          <Table.Header headerGroups={headerGroups} />
          <Table.Body
            isLoading={isLoading}
            rows={page}
            getTableBodyProps={getTableBodyProps}
            prepareRow={prepareRow}
            // onRowClick={(obj) => startEdit(obj)}
          />
        </Table>
      </Table.ScrollableWrapper>

      {!isLoading && (
        <Table.Pagination
          totalCount={totalCount}
          allowSelectPageSize
          {...pagination}
          showColumnSelection
          columns={columns}
          hiddenColumns={hiddenColumns}
          setHiddenColumns={_setHiddenColumns}
        />
      )}
    </Box>
  )
}

function useColumns() {
  return React.useMemo(
    () => [
      {
        id: 'id',
        Header: 'ID',
        accessor: 'id',
      },
      {
        id: 'vendor_id_starts_at',
        Header: 'Vendor Key',
        accessor: 'vendor_id',
        Cell: ({ row: { original: data } }) => {
          return `${data.vendor_id}#${data.starts_at}`
        },
      },
      {
        id: 'date',
        Header: 'Date',
        accessor: 'starts_at',
        Cell: ({ row: { original: data } }) => {
          return formatDate(data.starts_at)
        },
      },
      {
        id: 'time',
        Header: 'Time',
        accessor: 'starts_at',
        disableSortBy: true,
        Cell: ({ row: { original: data } }) => {
          return (
            <>
              {formatTime(data.starts_at)}
              {' - '}
              {formatTime(data.ends_at)}
            </>
          )
        },
      },
      {
        id: 'vendor_name',
        Header: 'Vendor',
        accessor: nullAwareAccessor('vendor_name'),
        filter: filterVendors,
        sortType: caseInsensitiveSort,
      },
      {
        id: 'customer_name',
        Header: 'Customer',
        accessor: nullAwareAccessor('customer_name'),
      },
      {
        id: 'price',
        Header: 'Price',
        accessor: 'price',
        Cell: ({ row: { original: data } }) => {
          return (
            <>
              {data.price && utils.formatMoney(data.price)}

              {data.is_corporate && (
                <Badge colorScheme="purple" ml={data.price ? 2 : undefined}>
                  Corp
                </Badge>
              )}
            </>
          )
        },
      },
    ],
    []
  )
}
