import React from 'react'
import * as utils from 'src/utils'

interface TimeProps {
  date: string
}

export const TimeDate: React.FC<TimeProps> = ({ date: dateString }) => {
  let formatted = utils.formatLocal(dateString, 'M/D/YYYY')

  return (
    <time dateTime={formatted} title={formatted}>
      {formatted}
    </time>
  )
}
