import { AxiosError } from 'axios'
import { useCallback } from 'react'
import { queryCache } from 'react-query'
import { useToast } from 'src/ui'
export const areAnyLoading = (...queries) => queries.some(({ status }) => status === 'loading')
export const areAnyFailed = (...queries) => queries.some(({ error }) => !!error)
export const areAllLoaded = (...queries) =>
  queries.every(({ status, error, data }) => status === 'success' && !error && !!data)

export const useMutationHandlers = ({
  invalidateQueries = [],
  successMessage,
  errorMessage,
}: {
  invalidateQueries?: string[] | [string, Record<string, string>?][]
  successMessage: string
  errorMessage: string
}) => {
  let toast = useToast()

  return {
    onSuccess: useCallback(
      async (_response, _args) => {
        for (let key of invalidateQueries) {
          console.log('[queries] invalidating key', key)
          await queryCache.invalidateQueries(key)
        }

        toast({
          title: 'Success',
          description: successMessage,
          status: 'success',
          duration: 4000,
          isClosable: false,
          position: 'top',
        })
      },
      [toast, invalidateQueries, successMessage]
    ),

    onError: useCallback(
      async (error: AxiosError<{ error?: string }>, _args) => {
        const apiErrorMessage = error?.response?.data?.error

        toast({
          title: 'Whoops',
          description: errorMessage + (apiErrorMessage ? `: ${apiErrorMessage}` : ''),
          status: 'error',
          duration: 4000,
          isClosable: false,
          position: 'top',
        })
      },
      [toast, errorMessage]
    ),
  }
}
