import React from 'react'
import { css } from '@emotion/core'
import { queryCache } from 'react-query'
import { Formik, Form, Field, FormikProps } from 'formik'
import * as Yup from 'yup'
import { Box, Row, Column, Button, ButtonGroup, FormikElements, Modal } from 'src/ui'

type FormValues = Partial<TaskDocument>

const FormSchema = Yup.object().shape({
  name: Yup.string()
    .nullable()
    .required('Required'),
  phone: Yup.string().nullable(),
  email: Yup.string().nullable(),
  address: Yup.string().nullable(),
})

interface FormProps {
  isOpen: boolean
  obj?: Partial<TaskDocument>
  saveObject: any
  onDismiss: () => void
}

const DocumentForm: React.SFC<FormProps> = ({ isOpen, obj, saveObject, onDismiss }) => {
  let dialogRef = React.useRef(null)

  return (
    <Formik
      initialValues={{
        name: obj?.name || '',
        template: obj?.template || '',
      }}
      validationSchema={FormSchema}
      enableReinitialize
      onSubmit={async (values: FormValues, formikActions: FormikProps<FormValues>) => {
        await saveObject(
          {
            id: obj?.id, // will be undefined on new users
            name: values.name,
            template: values.template,
            meta: {},
          },
          {
            onSuccess: async () => {
              await queryCache.invalidateQueries('documents')
              formikActions.resetForm()
              onDismiss()
            },
            onError: async () => null,
            onSettled: () => {
              formikActions.setSubmitting(false)
            },
          }
        )
      }}
    >
      {(formikBag: FormikProps<FormValues>) => (
        <Modal
          isOpen={isOpen}
          title={obj?.id ? 'Update Document' : 'Create Document'}
          onClose={() => {
            formikBag.resetForm()
            onDismiss()
          }}
          footer={
            <ButtonGroup spacing={2}>
              <Button
                size="sm"
                variant="ghost"
                ref={dialogRef}
                onClick={() => {
                  formikBag.resetForm()
                  onDismiss()
                }}
              >
                Cancel
              </Button>

              <Button
                size="sm"
                colorScheme="purple"
                isLoading={formikBag.isSubmitting}
                onClick={formikBag.submitForm}
              >
                Save
              </Button>
            </ButtonGroup>
          }
        >
          <Form>
            <Column width="400px">
              <Row justifyContent="flex-end" alignItems="center" pt={2} pb={4}>
                {obj?.created_at && (
                  <Box>
                    <TimeLabel
                      label="Created"
                      value={new Date(obj?.created_at).toLocaleDateString()}
                    />
                    {obj?.updated_at && (
                      <>
                        &nbsp;&nbsp;-&nbsp;&nbsp;
                        <TimeLabel
                          label="Updated"
                          value={new Date(obj?.updated_at).toLocaleDateString()}
                        />
                      </>
                    )}
                  </Box>
                )}
              </Row>

              <Box height="72px" mb={2}>
                <Field
                  id="name"
                  name="name"
                  label="Name"
                  size="sm"
                  component={FormikElements.Input}
                />
              </Box>

              <Box height="72px" mb={2}>
                <Field
                  id="template"
                  name="template"
                  label="Template"
                  size="sm"
                  component={FormikElements.Input}
                />
              </Box>
            </Column>
          </Form>
        </Modal>
      )}
    </Formik>
  )
}

export default DocumentForm

///////////////////////////////////////////////////////////////////////////////

// const Loading = () => (
//   <Row width="100%" height="250px" alignItems="center" justifyContent="center">
//     <Spinner speed="0.65s" size="xl" />
//   </Row>
// )

const TimeLabel = ({ label, value }) => {
  return (
    <small>
      <span
        css={css`
          color: '#777';
        `}
      >
        {label}
      </span>{' '}
      {value}
    </small>
  )
}
