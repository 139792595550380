import { useState } from 'react'
import { useCustomerMutations } from 'src/queries'
import { useMutationHandlers } from 'src/utils/queries'

export function useBillingHandlers({ customerId }) {
  // @todo: this shouldn't have to exist. react-query v3 supports all mutation states (like `isLoading`) on a single object (then call `mutation.mutate()`)
  const [isEnabling, setIsEnabling] = useState(false)
  const [isCanceling, setIsCanceling] = useState(false)
  const [isStarting, setIsStarting] = useState(false)

  const { updateCustomer, createCustomerSubscription, cancelCustomerSubscription } =
    useCustomerMutations()

  const enableMutationHandlers = useMutationHandlers({
    invalidateQueries: [['customer-billing', { customerId: customerId }], ['customers']],
    successMessage: 'Billing enabled',
    errorMessage: 'Failed to enable billing',
  })

  async function enableBilling() {
    setIsEnabling(true)

    await updateCustomer(
      { id: customerId, billing_status: 'active' },
      { ...enableMutationHandlers, onSettled: () => setIsEnabling(false) }
    )
  }

  const startMutationHandlers = useMutationHandlers({
    invalidateQueries: [['customer-billing', { customerId: customerId }], ['customers']],
    successMessage: 'Customer subscription started',
    errorMessage: 'Failed to start subscription',
  })

  async function createSubscription({ serviceVariantId }) {
    setIsStarting(true)

    await createCustomerSubscription(
      {
        customer_id: customerId,
        service_variant_id: serviceVariantId,
      },
      { ...startMutationHandlers, onSettled: () => setIsStarting(false) }
    )
  }

  const cancelMutationHandlers = useMutationHandlers({
    invalidateQueries: [['customer-billing', { customerId: customerId }]],
    successMessage: 'Subscription canceled',
    errorMessage: 'Failed to cancel subscription',
  })

  async function cancelSubscription({ subscriptionId }) {
    setIsCanceling(true)

    await cancelCustomerSubscription(
      { customer_id: customerId, subscription_id: subscriptionId },
      { ...cancelMutationHandlers, onSettled: () => setIsCanceling(false) }
    )
  }

  return {
    enableBilling,
    isEnabling,
    createSubscription,
    isStarting,
    cancelSubscription,
    isCanceling,
    updateCustomer,
  }
}

export function statusInfo(status: BillingStatusOption): [string, string] {
  return (
    {
      init: ['purple', 'Setting Up'],
      // init_sub_await_pay: ['purple', 'Awaiting Payment'],
      active: ['green', 'Active'],
      canceled: ['gray', 'Canceled'],
    }[status] ?? ['gray', 'Unknown']
  )
}
