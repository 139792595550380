import React, { useState, useEffect, useMemo, useRef } from 'react'
import { RouteComponentProps } from '@reach/router'
import { navigate, useLocation } from '@reach/router'
import useComponentSize from '@rehooks/component-size'
import qs from 'query-string'
import { Box, Row, Stack } from 'src/ui'
import { ItemMenu } from 'src/ui/item-menu'
import { useRanger, RangerControls } from 'src/ui/ranger'
import { createRangerArgs } from 'src/deliveries/utils'
import { useVendors, useDeliveries } from 'src/queries'
import DeliveryStats from 'src/deliveries/delivery-stats'
import VendorSummary from 'src/deliveries/vendor-list'
import DeliveryList from 'src/deliveries/delivery-list'

interface DeliveriesProps extends RouteComponentProps {}

/**
 * Make column selections persist via localStorage/cookie
 *
 * Clicking on vendor name shows their deliveries
 *
 * Show options on delivery list: show all, hide/show
 *
 * Show the exact FROM <-> TO values in the ranger
 */
export default function Deliveries(_props: DeliveriesProps) {
  const location = useLocation()
  const queryArgs = useMemo(() => qs.parse(location.search), [location.search])
  const rangerBag = useRanger(createRangerArgs({ queryArgs }))
  const { rootRef } = useHeightMeasure()

  const deliveryFilter = {
    range_start: rangerBag.startDate.format(),
    range_end: rangerBag.endDate.format(),
  }

  const vendorsQuery = useVendors()
  const deliveriesQuery = useDeliveries(deliveryFilter)
  const [selectedVendors, setSelectedVendors] = useState<string[]>([])

  useUrlSync({ rangerBag, queryArgs, deliveryFilter })

  return (
    <Stack ref={rootRef} flex={1} spacing={6}>
      <Row alignItems="center" p={2} bg="white" borderRadius={3} boxShadow="sm">
        <Box flex={1}>
          <RangerControls {...rangerBag} />
        </Box>

        <ItemMenu
          width={300}
          items={(vendorsQuery.data || []).map((vendor) => ({
            id: vendor.id,
            name: vendor.name,
          }))}
          label="Vendors"
          buttonLabel={({ selected }) =>
            `${selected === 0 ? 'All' : selected} ${selected === 1 ? 'Vendor' : 'Vendors'}`
          }
          selectedItems={selectedVendors}
          setSelectedItems={setSelectedVendors}
        />
      </Row>

      <DeliveryStats isLoading={deliveriesQuery.isLoading} deliveries={deliveriesQuery.data} />

      <VendorSummary
        isLoading={vendorsQuery.isLoading || deliveriesQuery.isLoading}
        deliveries={deliveriesQuery.data}
        vendors={vendorsQuery.data}
        selectedVendors={selectedVendors}
      />

      <DeliveryList
        isLoading={vendorsQuery.isLoading || deliveriesQuery.isLoading}
        deliveries={deliveriesQuery.data}
        selectedVendors={selectedVendors}
      />
    </Stack>
  )
}

function useHeightMeasure() {
  const rootRef = useRef(null)
  const containerSize = useComponentSize(rootRef)
  const [maxHeight, setMaxHeight] = useState(0)

  useEffect(() => {
    if (containerSize.height === 0) return
    if (maxHeight !== 0) return

    setMaxHeight(containerSize.height)
  }, [containerSize.height, maxHeight, setMaxHeight])

  return { rootRef, maxHeight }
}

function useUrlSync({ rangerBag, queryArgs, deliveryFilter }) {
  const rangerMonths = rangerBag.months
  const startDate = deliveryFilter.range_start
  const endDate = deliveryFilter.range_end

  useEffect(() => {
    let query =
      rangerMonths === -1
        ? { startDate: stripDate(startDate), endDate: stripDate(endDate) }
        : { range: rangerMonths }

    if (qs.stringify(query) === qs.stringify(queryArgs)) return

    navigate(qs.stringifyUrl({ url: '/deliveries', query }), { replace: true })
  }, [rangerMonths, startDate, endDate, queryArgs])
}

// Dirtily format `2021-02-25T00:00:00.000Z` into `2021-02-25`
function stripDate(date: string) {
  return date.slice(0, 10)
}
