import React from 'react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { DeleteIcon } from '@chakra-ui/icons'
import moment from 'moment'
import { queryCache } from 'react-query'
import * as dates from 'date-arithmetic'
import TimeGrid from 'react-big-calendar/lib/TimeGrid'
import useComponentSize from '@rehooks/component-size'
import { Event as BigCalendarEvent, Navigate as BigCalendarNavigateType } from 'react-big-calendar'
import { FaEdit } from 'react-icons/fa'
import * as utils from 'src/utils'
import * as deliveryUtils from 'src/deliveries/utils'
import Images, { ImageDropzone } from 'src/deliveries/images'
import { driverDaySchedule } from 'src/schedule/calendars/utils'
import { TASK_STATUSES, PAYMENT_METHODS } from 'src/utils/const'
import {
  Box,
  Row,
  Column,
  Button,
  Avatar,
  Badge,
  ConfirmDialog,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuList,
  MenuItem,
  Stack,
  useToast,
} from 'src/ui'
import { useDeliveryMutations } from 'src/queries'
import { useConfig } from 'src/utils/config'

const BigCalendarNavigate: BigCalendarNavigateType = {
  PREVIOUS: 'PREV',
  NEXT: 'NEXT',
  TODAY: 'TODAY',
  DATE: 'DATE',
}

interface CalendarEvent extends Delivery, BigCalendarEvent {}

//////////////////////////////////////////////////////////////////////////////////////////////////////

const moneyFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })

export const Empty = () => null

export class ThreeDayWeek extends React.Component<any, any> {
  range = (date) => {
    let start = moment(date).startOf('day').toDate()
    let end = dates.add(start, 2, 'day')

    let current = start
    let range = []

    while (dates.lte(current, end, 'day')) {
      range.push(current)
      current = dates.add(current, 1, 'day')
    }

    return range
  }

  render() {
    return <TimeGrid {...this.props} range={this.range(this.props.date)} />
  }
}

Object.assign(ThreeDayWeek, {
  title: (date) => '',
  navigate: (date, action) => {
    switch (action) {
      case BigCalendarNavigate.PREVIOUS:
        return dates.add(date, -3, 'day')

      case BigCalendarNavigate.NEXT:
        return dates.add(date, 3, 'day')

      default:
        return date
    }
  },
})

// EVENTS | WEEEK
///////////////////////////////////////////////////////////////////

export const CustomResourceHeader = ({ date, label, view, drivers, resource, ...rest }) => {
  // let driver = drivers.find(d => d.driver_id === resource.driver_id)
  // let scheduleStatus = driver ? driverDaySchedule({ date, driver }) : undefined

  return (
    <Row justifyContent="center" alignItems="center">
      <Row justifyContent="center" alignItems="center">
        <Avatar
          size={24}
          hasLetters={false}
          user={{ avatar_color: resource.avatar_color, name: resource.driver_name }}
          icon={
            // scheduleStatus === 'off' ? (
            //   <FiHome size={15} />
            // ) : scheduleStatus === 'vacation' ? (
            //   <FiSun size={15} />
            // ) : (
            //   undefined
            // )
            undefined
          }
        />
        &nbsp;&nbsp;
        {label}
      </Row>
    </Row>
  )
}

interface CustomHeaderProps {
  date: Date
  label: string
  view: 'day' | '3day' | 'week' | string
  drivers: Driver[]
}

export const CustomHeader: React.FC<CustomHeaderProps> = ({ date, label, view, drivers }) => {
  return (
    <Row alignItems="center" height="20px">
      {view === 'week' ? (
        <>
          <Box width="50%" px={2}>
            {label}
          </Box>
        </>
      ) : (
        <>
          <Box width="33.33%" />
          <Box width="33.33%" textAlign="center">
            {label}
          </Box>
        </>
      )}

      {view === 'week' ? (
        <>
          <Box flex={1} />
          {drivers.map((driver) => {
            if (driverDaySchedule({ date: utils.momentUtcParseDate(date), driver }) === 'working') {
              let backgroundColor = `#${driver.avatar_color || '8888FC'}`
              return (
                <Box
                  key={driver.driver_id}
                  bg={backgroundColor}
                  height="36px"
                  width="10px"
                  my={-10}
                />
              )
            }

            return null
          })}
        </>
      ) : (
        <Row width="33.33%" justifyContent="flex-end" pr={2}>
          {drivers.map((driver, i) => {
            if (driverDaySchedule({ date: utils.momentUtcParseDate(date), driver }) === 'working') {
              return (
                <Box key={driver.driver_id} pr={i < drivers.length - 1 ? 1 : undefined}>
                  <Avatar
                    size={26}
                    user={{ ...driver, id: driver.driver_id, name: driver.driver_name }}
                  />
                </Box>
              )
            }

            return null
          })}
        </Row>
      )}
    </Row>
  )
}

const IS_WIDE_WIDTH = 200
const calcIsWide = ({ width }: { width: number }) => width >= IS_WIDE_WIDTH

// CUSTOM EVENT FOR ALL THREE VIEWS: WEEK, 3DAY, DAY
///////////////////////////////////////////////////////////////////

interface CustomEventProps {
  event: CalendarEvent
  isDayView: boolean
  isThreeDayView: boolean
  isWeekView: boolean
}

export const CustomEvent = ({
  event,
  isDayView = false,
  isThreeDayView = false,
  isWeekView = false,
}: CustomEventProps) => {
  const containerRef = React.useRef(null)
  const containerSize = useComponentSize(containerRef)
  const { clientConfig } = useConfig()

  const isSingleAddress = clientConfig('features.tasks.single_address')
  const isWide = calcIsWide(containerSize)
  // const duration = moment(event.ends_at).diff(moment(event.starts_at), 'hours')
  const duration = moment(event.ends_at).diff(moment(event.starts_at), 'minutes')
  const notShort = duration > 115

  return (
    <Column
      ref={containerRef}
      data-eventid={event.id}
      height="100%"
      justifyContent="flex-start"
      fontSize={isDayView ? undefined : isThreeDayView ? 12 : 11}
    >
      {!isWeekView && <PaymentDistanceRow isWide={isWide} event={event} />}

      {!isSingleAddress && (
        <>
          {/* VENDOR */}
          <Row
            alignItems="flex-start"
            pt={isWide && notShort && event.description ? 2 : 1}
            pb={isWide && notShort && event.description ? 2 : undefined}
            css={{ flexDirection: isWide ? 'row' : 'column' }}
          >
            <Row width={isWide ? '50%' : '100%'} alignItems="center" pb={isWide ? undefined : 1}>
              <Box fontSize={isDayView ? '120%' : '110%'}>{event.vendor_name}</Box>
            </Row>

            {notShort && isWide && (
              <Row
                width={isWide ? '50%' : '100%'}
                justifyContent={isWide ? 'flex-end' : 'flex-start'}
                alignItems="center"
              >
                <Box>{utils.prettyPhoneNumber(event.vendor_phone)}</Box>
              </Row>
            )}
          </Row>

          {isWide && notShort && event.description && (
            <Row alignItems="center">
              <Box fontSize={11} whiteSpace="pre-wrap">
                {event.description}
              </Box>
            </Row>
          )}

          {/* SEPARATOR */}
          <Box py={notShort ? 2 : 1} />
        </>
      )}

      {/* CUSTOMER */}
      <Row
        alignItems="flex-start"
        pt={1}
        pb={isWide && notShort && event.description ? 2 : undefined}
        css={{ flexDirection: isWide ? 'row' : 'column' }}
      >
        <Row width={isWide ? '50%' : '100%'} alignItems="center" pb={isWide ? undefined : 1}>
          <Box fontSize={isDayView ? '120%' : '110%'}>{event.customer_name}</Box>
        </Row>

        {(notShort || isWide) && (
          <Row
            width={isWide ? '50%' : '100%'}
            justifyContent={isWide ? 'flex-end' : 'flex-start'}
            alignItems="center"
          >
            <Box>{utils.prettyPhoneNumber(event.customer_phone)}</Box>
          </Row>
        )}
      </Row>

      {isWide && notShort && event.instructions && (
        <Row alignItems="center">
          <Box fontSize={11} whiteSpace="pre-wrap">
            {event.instructions}
          </Box>
        </Row>
      )}

      {event.status === 'canceled' && (
        <Box
          position="absolute"
          textAlign="center"
          bottom={0}
          left={0}
          right={0}
          bg="red.400"
          color="white"
          borderRadius={2}
          fontWeight={500}
          py={1}
        >
          CANCELED
        </Box>
      )}
    </Column>
  )
}

// EVENTS | DAY (MOBILE)
///////////////////////////////////////////////////////////////////

export const EventDayMobile = ({
  isAdmin,
  event,
  driver,
  updateDelivery,
  setEditDialog,
  setStatusConfirmModal,
}) => {
  let toast = useToast()
  let [isAttemptingDelete, setIsAttemptingDelete] = React.useState(false)
  let [isDeleting, setIsDeleting] = React.useState(false)
  let [isUpdatingPayment, setIsUpdatingPayment] = React.useState(false)
  let { deleteDelivery } = useDeliveryMutations()

  // let duration = moment(event.ends_at).diff(moment(event.starts_at), 'hours')
  let hasImages = !!event.images?.length

  const { usesSubscriptionBilling, usesOnlineBilling } = deliveryUtils.getTaskBillingFacts(event)

  return (
    <>
      <Stack justifyContent="flex-start" bg="white" borderRadius={3} boxShadow="md" pb={2}>
        {/******* HEADER *******/}
        <Row
          alignItems="center"
          bg={driver?.avatar_color ? `#${driver?.avatar_color}` : undefined}
          color="gray.700"
          p={2}
          css={{
            borderTopLeftRadius: 3,
            borderTopRightRadius: 3,
          }}
        >
          <Box flex={1} fontWeight={500}>
            {utils.formatLocal(event.starts_at, 'h:mm')}
            {' - '}
            {utils.formatLocal(event.ends_at, 'h:mm A')}
          </Box>

          {event.distance && (
            <Box px={4}>
              {event.distance}, {event.duration}
            </Box>
          )}

          {isAdmin && <Box fontWeight={500}>{event.driver_name}</Box>}
        </Row>

        <Stack px={2}>
          {/******* META *******/}
          <Row justifyContent="space-between" alignItems="center">
            <Row flexWrap="wrap">
              {event.is_corporate && (
                <Badge mr={2} colorScheme="purple">
                  Corporate
                </Badge>
              )}

              {event.has_return && (
                <Badge mr={2} colorScheme="red">
                  Return
                </Badge>
              )}

              {event.has_exchange && (
                <Badge mr={2} colorScheme="red">
                  Exchange
                </Badge>
              )}
              {event.invoice && (
                <Box mr={2}>
                  <strong>Invoice</strong> {event.invoice}
                </Box>
              )}
            </Row>

            <Box fontSize="md" fontWeight={500}>
              {event.price ? moneyFormatter.format(event.price) : '$ -'}
            </Box>
          </Row>

          {/******* VENDOR *******/}
          <Row alignItems="flex-start">
            <Row width="66.66%" alignItems="center" fontSize="md" fontWeight={500}>
              {event.vendor_name}
            </Row>

            <Row width="33.33%" justifyContent="flex-end" alignItems="center">
              {utils.prettyPhoneNumber(event.vendor_phone)}
            </Row>
          </Row>

          {event.description && (
            <Row alignItems="center" fontSize={12}>
              {event.description}
            </Row>
          )}

          {/******* CUSTOMER *******/}
          <Row alignItems="center">
            <Row width="66.66%" alignItems="center" fontSize="md" fontWeight={500}>
              {event.customer_name}
            </Row>

            <Row width="33.33%" justifyContent="flex-end" alignItems="center">
              <Box>{utils.prettyPhoneNumber(event.customer_phone)}</Box>
            </Row>
          </Row>

          <Row alignItems="center" fontSize={15}>
            {event.delivery_address}
          </Row>

          {event.instructions && (
            <Row alignItems="center" fontSize={12}>
              {event.instructions}
            </Row>
          )}
        </Stack>

        {/******* FOOTER *******/}
        <Row justifyContent="space-between" alignItems="center" px={2} mb={0}>
          <Box>
            {usesSubscriptionBilling ? (
              <Badge colorScheme="green">Paid - Subscription</Badge>
            ) : (
              <>
                {usesOnlineBilling ? (
                  <Badge colorScheme="green">Paid - Online</Badge>
                ) : (
                  <PaymentStatusMenuButton
                    obj={event}
                    isLoading={isUpdatingPayment}
                    updateObject={(args) => {
                      setIsUpdatingPayment(true)
                      updateDelivery(args).then(() => setIsUpdatingPayment(false))
                    }}
                  />
                )}
              </>
            )}
          </Box>

          <Box>
            <Menu>
              <MenuButtonFixed as={Button} size="sm" width="130px" rightIcon={<ChevronDownIcon />}>
                {TASK_STATUSES[event.status]}
              </MenuButtonFixed>

              <MenuList placement="bottom-end" fontSize={14}>
                {Object.keys(TASK_STATUSES)
                  .filter((status) => status !== event.status)
                  .map((status) => (
                    <MenuItem
                      key={status}
                      onClick={() => setStatusConfirmModal({ id: event.id, status })}
                    >
                      {TASK_STATUSES[status]}
                    </MenuItem>
                  ))}
              </MenuList>
            </Menu>
          </Box>
        </Row>

        {isAdmin && (
          <Row justifyContent="space-between" alignItems="center" px={2} mb={0}>
            <Box>
              <IconButton
                width="50px"
                size="sm"
                aria-label="Delete"
                icon={<DeleteIcon />}
                mr={2}
                onClick={() => setIsAttemptingDelete(true)}
              />
            </Box>

            <Row alignItems="center">
              <Button
                width="130px"
                size="sm"
                colorScheme="purple"
                aria-label="Edit"
                rightIcon={<FaEdit />}
                onClick={() => setEditDialog(event)}
              >
                Edit
              </Button>
            </Row>
          </Row>
        )}

        <Box height="1px" borderBottom="1px solid #eee" />

        <Row justifyContent="space-between" alignItems="center" px={2} mb={0}>
          <Box fontSize={16} color="#888" fontWeight={500}>
            {!hasImages && 'No'} Images
          </Box>

          <Box>
            <ImageDropzone deliveryId={event.id} showEmptyState={false} />
          </Box>
        </Row>
        {hasImages && (
          <>
            <Box px={2}>
              <Images delivery={event} />
            </Box>
          </>
        )}
      </Stack>

      <ConfirmDialog
        title="Delete Delivery"
        body="Are you sure you want to delete this delivery?"
        isOpen={isAttemptingDelete}
        isLoading={isDeleting}
        onClose={() => {
          setIsDeleting(false)
          setIsAttemptingDelete(false)
        }}
        onConfirm={async () => {
          setIsDeleting(true)

          await deleteDelivery(
            { id: event.id },
            {
              onSuccess: async (_response, _args) => {
                await queryCache.invalidateQueries('deliveries')

                toast({
                  title: 'Success',
                  description: 'Delivery Deleted',
                  status: 'success',
                  duration: 4000,
                  isClosable: false,
                  position: 'top',
                })
              },

              onError: async (...args) => {
                toast({
                  title: 'Whoops',
                  description: 'Unable to delete delivery',
                  status: 'error',
                  duration: 4000,
                  isClosable: false,
                  position: 'top',
                })
              },

              onSettled: async (...args) => {
                setIsDeleting(false)
                setIsAttemptingDelete(false)
              },
            }
          )
        }}
      />
    </>
  )
}

function PaymentStatusMenuButton({ obj, isLoading, updateObject }) {
  return (
    <Menu>
      <MenuButton
        as={Button}
        size="sm"
        isLoading={isLoading}
        rightIcon={<ChevronDownIcon />}
        colorScheme={obj.is_paid ? 'green' : undefined}
      >
        {obj.is_paid ? 'Paid' : 'Unpaid'} - {PAYMENT_METHODS[obj.payment_method] || 'Unknown'}
      </MenuButton>

      <MenuList placement="bottom-end" fontSize={14}>
        {!obj.is_paid && (
          <MenuItem onClick={() => updateObject({ id: obj.id, is_paid: true })}>Mark Paid</MenuItem>
        )}
        {obj.is_paid && (
          <MenuItem onClick={() => updateObject({ id: obj.id, is_paid: false })}>
            Mark Unpaid
          </MenuItem>
        )}

        <MenuDivider />

        {Object.keys(PAYMENT_METHODS)
          .filter((method) => method !== obj.payment_method)
          .map((method) => (
            <MenuItem
              key={method}
              onClick={() => updateObject({ id: obj.id, payment_method: method })}
            >
              {PAYMENT_METHODS[method]}
            </MenuItem>
          ))}
      </MenuList>
    </Menu>
  )
}

function PaymentDistanceRow({ event, isWide = true }) {
  return (
    <Row flexDirection={isWide ? 'row' : 'column'} justifyContent="space-between" pb={1}>
      <Box mb={!isWide ? 1 : undefined}>
        <Badge
          variant="solid"
          colorScheme={event.is_paid ? 'green' : undefined}
          fontSize="10px"
          px="4px"
          py="2px"
        >
          {event.is_paid ? 'Paid' : 'Unpaid'}
          {/* - {PAYMENT_METHODS[event.payment_method] || 'Unknown'} */}
        </Badge>
      </Box>
      {event.distance && (
        <Box>
          {event.distance}, {event.duration}
        </Box>
      )}
    </Row>
  )
}

const MenuButtonFixed = (props) => <MenuButton {...props} />

interface CustomEventWrapperProps {
  title: string
  event: CalendarEvent
}

export const CustomEventWrapper = ({ title, event }: CustomEventWrapperProps) => {
  return (
    <CustomEvent
      event={event}
      isDayView={event.view === 'day'}
      isThreeDayView={event.view === '3day'}
      isWeekView={event.view === 'week'}
    />
  )
}
