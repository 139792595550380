import React from 'react'
import { AddIcon, DeleteIcon } from '@chakra-ui/icons'
import { Box, Row, Button, IconButton, Table, useTable, TimeDate } from 'src/ui'
import { prettyPhoneNumber } from 'src/utils'

interface ListProps {
  vendors: Vendor[]
  isLoading: boolean
  startCreate: () => void
  startEdit: (obj: any) => void
  startDelete: (args: any) => void
}

const VendorList: React.SFC<ListProps> = ({
  vendors = [],
  isLoading = true,
  startCreate,
  startEdit,
  startDelete,
}) => {
  let columns = React.useMemo(
    () => [
      {
        id: 'id',
        Header: 'ID',
        accessor: 'id',
      },
      {
        id: 'name',
        Header: 'Name',
        accessor: 'name',
        sortType: ({ original: rowA }, { original: rowB }) =>
          rowA.name.toLowerCase() < rowB.name.toLowerCase() ? -1 : 1,
      },
      {
        id: 'phone',
        Header: 'Phone',
        accessor: row =>
          row.phone ? <Box whiteSpace="nowrap">{prettyPhoneNumber(row.phone)}</Box> : undefined,
      },
      {
        id: 'email',
        Header: 'Email',
        accessor: row => row.email || undefined,
      },
      {
        id: 'contact',
        Header: 'Contact',
        accessor: row => row.contact || undefined,
      },
      {
        id: 'address',
        Header: 'Address',
        accessor: row => row.address || undefined,
      },
      {
        id: 'rate',
        Header: 'Rate',
        accessor: row => row.rate || undefined,
      },
      {
        id: 'created_at',
        Header: 'Created',
        accessor: 'created_at',
        Cell: ({ cell }) => <TimeDate date={cell.value} />,
      },
      {
        Header: 'Updated',
        accessor: 'updated_at',
        id: 'updated_at',
        Cell: ({ cell }) => (cell.value ? <TimeDate date={cell.value} /> : '-'),
      },
      {
        id: '_actions',
        Header: '',
        accessor: '_actions',
        disableSorting: true,
        Cell: ({ row }) => {
          let obj = row.original

          return (
            <Row justifyContent="flex-end">
              <IconButton
                size="sm"
                color="gray.400"
                variant="ghost"
                icon={<DeleteIcon />}
                aria-label="Delete"
                children={null}
                onClick={e => {
                  e.stopPropagation()
                  startDelete(obj)
                }}
              />
            </Row>
          )
        },
      },
    ],
    [startDelete]
  )

  let {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    // rows,
    page,
    pagination,
    setHiddenColumns,
    totalCount,
  } = useTable({
    columns,
    data: vendors,
    tableOptions: {
      initialState: {
        sortBy: [{ id: 'name' }],
      },
    },
  })

  return (
    <>
      <Row alignItems="center" mb={2}>
        <Box flex={1} color="gray.500" px={2}>
          {/* <Heading size="md">Vendors</Heading> */}
        </Box>

        <Box>
          <Button
            size="sm"
            color="gray.400"
            variant="ghost"
            leftIcon={<AddIcon />}
            onClick={startCreate}
          >
            New
          </Button>
        </Box>
      </Row>

      <Table {...getTableProps()}>
        <Table.Header headerGroups={headerGroups} />
        <Table.Body
          isLoading={isLoading}
          rows={page}
          getTableBodyProps={getTableBodyProps}
          prepareRow={prepareRow}
          onRowClick={obj => startEdit(obj)}
        />
      </Table>

      {!isLoading && (
        <Table.Pagination
          totalCount={totalCount}
          {...pagination}
          showColumnSelection
          columns={columns}
          setHiddenColumns={setHiddenColumns}
        />
      )}
    </>
  )
}

export default VendorList
