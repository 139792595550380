import React, { useState, useEffect, useRef } from 'react'
import { WarningIcon } from '@chakra-ui/icons'
import { RouteComponentProps } from '@reach/router'
import { Formik, Form, Field, FormikProps } from 'formik'
import * as Yup from 'yup'
import qs from 'query-string'
import useAuth from 'src/store/auth'
import {
  Box,
  Column,
  Row,
  Stack,
  Button,
  ButtonLink,
  Heading,
  Spinner,
  Icon,
  Alert,
  AlertIcon,
  FormikElements,
} from 'src/ui'
import { useConfig } from 'src/utils/config'

const LoginSchema = Yup.object().shape({
  email: Yup.string().required('Required'),
})

interface FormValues {
  email: string
}

export function CustomerLogin(_props: RouteComponentProps) {
  const emailRef = useRef(null)
  const [message, setMessage] = useState(null)
  const authenticateCustomer = useAuth((state) => state.authenticateCustomer)
  const { text } = useConfig()

  useEffect(() => {
    if (emailRef.current) emailRef.current.focus()
  }, [])

  return (
    <Column height="100vh" justifyContent="center" alignItems="center">
      <Box
        d="flex"
        flexDirection="column"
        width="100%"
        maxWidth="400px"
        p={6}
        bg="white"
        boxShadow="md"
        borderRadius={3}
      >
        <Heading mb={4} textAlign="center">
          {text('app_title')}
        </Heading>

        <Box mb={4} textAlign="center">
          A login link will be emailed to you to access your account.
        </Box>

        <Formik
          validateOnBlur={false}
          initialValues={{ email: '' }}
          validationSchema={LoginSchema}
          onSubmit={async ({ email }: FormValues, formikActions: FormikProps<FormValues>) => {
            let [err, message] = await authenticateCustomer({ email })

            if (err) {
              formikActions.setSubmitting(false)
              formikActions.setFieldError('email', err)
            } else {
              setMessage(message)
            }
          }}
        >
          {(formikBag: FormikProps<FormValues>) => (
            <Form>
              <Column mb={4}>
                <Box height="95px" mb={2}>
                  <Field
                    label="Email"
                    name="email"
                    id="email"
                    autoComplete="off"
                    component={FormikElements.Input}
                    inputRef={(ref) => (emailRef.current = ref)}
                  />
                </Box>

                <Row justifyContent="flex-end" alignItems="center">
                  <Button
                    type="submit"
                    variant="solid"
                    colorScheme="purple"
                    isLoading={formikBag.isSubmitting}
                  >
                    Log In
                  </Button>
                </Row>
              </Column>
            </Form>
          )}
        </Formik>

        {message && (
          <Alert status="success">
            <AlertIcon />
            {message}
          </Alert>
        )}
      </Box>
    </Column>
  )
}

export function CustomerAuthenticate() {
  const authenticate = useAuth((state) => state.authenticate)
  const [error, setError] = useState(null)

  useEffect(() => {
    const [err, parsed] = parseAndValidateHash()

    if (err) {
      setError(err)
      return
    }

    const { email, emailAuthToken } = parsed

    async function run() {
      const [err] = await authenticate({
        type: 'cus',
        email,
        emailAuthToken,
        successRedirectPath: '/customer',
      })

      if (err) {
        setError('Failed to log you in. Please try again.')
      }
    }

    run()
  }, [authenticate])

  if (error) return <ErrorMessage message={error} />

  return (
    <>
      <Row w="100%" mt="20vh" justifyContent="center">
        <Spinner speed="0.65s" size="xl" />
      </Row>
    </>
  )
}

function parseAndValidateHash(): [string, { emailAuthToken: string; email: string }] {
  const parsed = qs.parse(window.location.hash)

  if (!parsed.et) return ['Missing token', {} as any]
  if (!parsed.email) return ['Missing email', {} as any]

  const email = parsed.email as string
  const emailAuthToken = parsed.et as string

  return [null, { email, emailAuthToken }]
}

const ErrorMessage = ({ message }: { message: string }) => (
  <Box position="relative" width="100%" height="100vh">
    <Stack spacing={6} width="420px" mt="25%" mx="auto" textAlign="center">
      <Row justifyContent="center" alignItems="center">
        <Icon as={WarningIcon} fontSize="32px" color="yellow.700" />

        <Box fontSize="2em" color="gray.600" ml={4}>
          Oops
        </Box>
      </Row>

      <Box fontSize="1.125rem">{message}</Box>

      <Box>
        <ButtonLink to="/customer/login">Try again</ButtonLink>
      </Box>
    </Stack>
  </Box>
)
