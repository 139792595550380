import React from 'react'
import {
  ModalProps as ChakraModalProps,
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  // ModalCloseButtonr,
} from '@chakra-ui/react'

interface ModalProps {
  initialRef?: any
  isOpen?: boolean
  size?: ChakraModalProps['size']
  scrollBehavior?: ChakraModalProps['scrollBehavior']
  title: any
  footer?: any
  onClose: any
}

const Modal: React.FC<ModalProps> = ({
  initialRef,
  isOpen = false,
  size = 'lg',
  scrollBehavior = 'outside',
  title,
  footer,
  onClose,
  children,
}) => {
  return (
    <ChakraModal
      initialFocusRef={initialRef}
      isOpen={isOpen}
      size={size}
      scrollBehavior={scrollBehavior}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent
        mt={size === 'full' ? 0 : undefined}
        borderRadius={size === 'full' ? 0 : undefined}
      >
        <ModalHeader px={4}>{title}</ModalHeader>
        {/* <ModalCloseButton /> */}

        <ModalBody pt={0} px={4}>
          {children}
        </ModalBody>

        {footer && <ModalFooter p={2}>{footer}</ModalFooter>}
      </ModalContent>
    </ChakraModal>
  )
}

export default Modal
