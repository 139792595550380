import React, { useEffect, useState } from 'react'
import { useLocation, navigate } from '@reach/router'
import { Box, Row, Stack, Button, Heading, Badge, useToast } from 'src/ui'
import * as api from 'src/api'

interface CustomerDocumentsProps {
  customer: Customer
}

export default function CustomerDocuments({ customer }: CustomerDocumentsProps) {
  useCallbackToasts()

  return (
    <>
      <Row justifyContent="space-between" alignItems="center" mb={4}>
        <Heading fontWeight={400} fontSize="2xl">
          Documents
        </Heading>
      </Row>

      {(!customer?.documents || !customer?.documents?.length) && (
        <Row my={4} color="gray.600" fontStyle="italic">
          You have no signed or pending documents
        </Row>
      )}

      <Stack spacing={4}>
        {customer?.documents?.map((document) => (
          <Document key={document.id} document={document} />
        ))}
      </Stack>
    </>
  )
}

function useCallbackToasts() {
  let toast = useToast()
  const loc = useLocation()

  useEffect(() => {
    const cb = new URLSearchParams(loc.search).get('cb')
    if (!cb) return

    const topts = {
      duration: 10 * 10000,
      isClosable: true,
      position: 'top' as any,
    }

    if (cb === 'sign_success') {
      toast({
        title: 'Success',
        description: 'Document signed, thank you!',
        status: 'success',
        ...topts,
      })
    }

    if (cb === 'sign_decline') {
      toast({
        title: 'Canceled',
        description: 'Document has not been signed, please try again',
        status: 'error',
        ...topts,
      })
    }

    // TODO: The callback may not have hit yet, so we should refresh the `useCustomer()` hook
    // for data until we see a signed status

    navigate('/customer', { replace: true })
  }, [toast, loc.search])
}

function Document({ document }: { document: TaskSignRequest }) {
  const isSigned = !!document.statuses?.signed

  return (
    <Stack border="1px solid" borderColor="gray.100" borderRadius={4} boxShadow="md" p={3}>
      <Row alignItems="center">
        <Box pr={2} color="gray.600">
          {new Date(document.created_at).toLocaleDateString()}
        </Box>

        <DocumentTitle isSigned={isSigned} document={document} />

        <Box>
          <StatusBadge isSigned={isSigned} />
        </Box>
      </Row>

      {!isSigned && (
        <Row justifyContent="space-between" alignItems="center">
          <Box flex={1} color="gray" textAlign="left">
            Please check your email for a document sign request
          </Box>
        </Row>
      )}
    </Stack>
  )
}

function StatusBadge({ isSigned }: { isSigned: boolean }) {
  const [color, label] = isSigned ? ['green', 'Signed'] : ['red', 'Awaiting Signature']

  return <Badge colorScheme={color}>{label}</Badge>
}

function DocumentTitle({ isSigned, document }: { isSigned: boolean; document: TaskSignRequest }) {
  const [isLoading, setIsLoading] = useState(false)

  return (
    <Box flex={1}>
      {isSigned ? (
        <Button
          size="sm"
          variant="link"
          isLoading={isLoading}
          color="black"
          onClick={async () => {
            setIsLoading(true)
            const [err, response] = await api.getClientDocumentPdf(document.id)
            setIsLoading(false)

            if (err) return console.log('err', err)

            let link = window.document.createElement('a')
            link.href = response.data.link
            link.target = '_blank'
            link.click()
            setTimeout(() => link.remove(), 0)
          }}
        >
          {document.name}
        </Button>
      ) : (
        <strong>{document.name}</strong>
      )}
    </Box>
  )
}

// function Loading() {
//   return (
//     <Row w="100%" mt="20vh" justifyContent="center">
//       <Spinner speed="0.65s" size="xl" />
//     </Row>
//   )
// }
// function Error() {
//   return (
//     <Row w="100%" mt="20vh" justifyContent="center">
//       There was an error loading billing information.
//     </Row>
//   )
// }
