import axios, { AxiosError, AxiosResponse, AxiosRequestHeaders } from 'axios'
import { navigate } from '@reach/router'
import queryString from 'query-string'
import { LS_KEYS, getLocalStorageKey } from 'src/utils/local-storage'

const ENV_API_URL = process.env.REACT_APP_API_URL

export enum STATUS {
  idle = 'idle',
  confirming = 'confirming',
  loading = 'loading',
  reloading = 'reloading',
  loaded = 'loaded',
  failed = 'failed',
}

export type ApiReturn<T> = Promise<[AxiosError, AxiosResponse<T>]>

///////////////////////////////////////////////////////////////////////////////
// INSTANCE SETUP & HELPERS
///////////////////////////////////////////////////////////////////////////////

let storedAuth = getLocalStorageKey(LS_KEYS.AUTH)
let authInterceptor: number

export function apiUrl() {
  if (ENV_API_URL) return ENV_API_URL

  const host = window?.location?.host || ''

  switch (true) {
    case host.indexOf('dev.app.falcon.vegas') === 0:
      return 'https://dev.api.falcon.vegas'

    case host.indexOf('app.falcon.vegas') === 0:
      return 'https://api.falcon.vegas'

    case host.indexOf('app.dev.panelarmor.com') === 0:
      return 'https://api.dev.panelarmor.com'

    case host.indexOf('app.panelarmor.com') === 0:
      return 'https://api.panelarmor.com'

    default:
      return 'https://dev.api.falcon.vegas'
  }
}

export const api = axios.create({
  baseURL: apiUrl(),
  headers: (storedAuth && storedAuth.token
    ? { common: { Authorization: `Bearer ${storedAuth.token}` } }
    : {}) as AxiosRequestHeaders,
})

export const addAxiosAuthInterceptor = () => {
  authInterceptor = api.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      if (error.response.status === 401) {
        navigate('/logout') // @todo: not stoked about this being here
      }

      return Promise.reject(error)
    }
  )
}

export const removeAxiosAuthInterceptor = () => {
  if (authInterceptor) api.interceptors.request.eject(authInterceptor)
  authInterceptor = undefined
}

if (storedAuth) {
  addAxiosAuthInterceptor()
}

export const setAxiosHeader = (key: string, value: string) =>
  (api.defaults.headers.common[key] = value)

export const removeAxiosHeader = (key: string) => delete api.defaults.headers.common[key]

export const makeCancelToken = (ref) => new axios.CancelToken((token) => (ref.current = token))

export const isCancel = (err) => axios.isCancel(err)

const asyncify = async <T>(request): ApiReturn<T> => {
  try {
    let response = await request()
    return [null, response]
  } catch (err) {
    return [err, null]
  }
}

///////////////////////////////////////////////////////////////////////////////
// API REQUESTS
///////////////////////////////////////////////////////////////////////////////

// AUTH
/////////////////////////////////////////////////////////////////////

export const authenticate = async ({
  type,
  email,
  password,
  email_auth_token,
}: {
  type?: string
  email: string
  password?: string
  email_auth_token?: string
}) => {
  return asyncify<{ user: User; token: string }>(() =>
    api.post('/authenticate', { type, email, password, email_auth_token })
  )
}

export const authenticateEmailToken = async ({ email }) => {
  return asyncify<{ message: string; link: string }>(() =>
    api.post('/authenticate/email-token', { email })
  )
}

export const me = async () => {
  return asyncify<{ user: Customer }>(() => api.get('/me'))
}
export const tenant = async () => {
  return asyncify<Tenant>(() => api.get('/tenant'))
}

// BATCH
/////////////////////////////////////////////////////////////////////

export const batchBootstrap = async () => {
  return asyncify<{ tenant: Tenant; users: User[]; vehicles: Vehicle[]; services: TaskService[] }>(
    () => api.get('/batch/bootstrap')
  )
}

// USERS
/////////////////////////////////////////////////////////////////////

export const getUsers = async () => {
  return asyncify<{ data: User[] }>(() => api.get('/users'))
}

export const updateUser = async ({ id, ...body }: Partial<User>) => {
  return asyncify<{ data: User }>(() => api.put(`/users/${id}`, body))
}

export const createUser = async (body: Partial<User>) => {
  return asyncify<{ data: User }>(() => api.post('/users', body))
}

export const deleteUser = async ({ id }: { id: string }) => {
  return asyncify<unknown>(() => api.delete(`/users/${id}`))
}

// VEHICLES
/////////////////////////////////////////////////////////////////////

export const getVehicles = async () => {
  return asyncify<{ data: Vehicle[] }>(() => api.get('/vehicles'))
}

export const updateVehicle = async ({ id, name }: Partial<Vehicle>) => {
  return asyncify<{ data: Vehicle }>(() => api.put(`/vehicles/${id}`, { name }))
}

export const createVehicle = async ({ name }: Partial<Vehicle>) => {
  return asyncify<{ data: Vehicle }>(() => api.post('/vehicles', { name }))
}

export const deleteVehicle = async ({ id }: { id: string }) => {
  return asyncify<unknown>(() => api.delete(`/vehicles/${id}`))
}

// VENDORS
/////////////////////////////////////////////////////////////////////

export const getVendors = async () => {
  return asyncify<{ data: Vendor[] }>(() => api.get('/vendors'))
}

export const updateVendor = async ({ id, ...body }: Partial<Vendor>) => {
  return asyncify<{ data: Vendor }>(() => api.put(`/vendors/${id}`, body))
}

export const createVendor = async (body: Partial<Vendor>) => {
  return asyncify<{ data: Vendor }>(() => api.post('/vendors', body))
}

export const deleteVendor = async ({ id }: { id: string }) => {
  return asyncify<unknown>(() => api.delete(`/vendors/${id}`))
}

// CUSTOMERS
/////////////////////////////////////////////////////////////////////

export const getCustomers = async () => {
  return asyncify<{ data: Customer[] }>(() => api.get('/customers'))
}
export const getCustomer = async (id: string) => {
  return asyncify<{ data: Customer }>(() => api.get(`/customers/${id}`))
}

export const updateCustomer = async ({ id, ...body }: Partial<Customer>) => {
  return asyncify<{ data: Customer }>(() => api.put(`/customers/${id}`, body))
}

export const createCustomer = async (body: Partial<Customer>) => {
  return asyncify<{ data: Customer }>(() => api.post('/customers', body))
}

export const deleteCustomer = async ({ id }: { id: string }) => {
  return asyncify<unknown>(() => api.delete(`/customers/${id}`))
}

// DOCUMENTS
/////////////////////////////////////////////////////////////////////

export const getDocuments = async () => {
  return asyncify<TaskDocument[]>(() => api.get('/documents'))
}

export const updateDocument = async ({ id, ...body }: Partial<TaskDocument>) => {
  return asyncify<TaskDocument>(() => api.put(`/documents/${id}`, body))
}

export const createDocument = async (body: Partial<TaskDocument>) => {
  return asyncify<TaskDocument>(() => api.post('/documents', body))
}

export const deleteDocument = async ({ id }: { id: string }) => {
  return asyncify<unknown>(() => api.delete(`/documents/${id}`))
}

// DELIVERIES
/////////////////////////////////////////////////////////////////////

export const getDeliveries = async ({ range_start, range_end }) => {
  return asyncify<{ data: Delivery[] }>(() =>
    api.get('/deliveries', { params: { range_start, range_end } })
  )
}

export const updateDelivery = async ({ id, ...body }: Partial<Delivery>) => {
  return asyncify<{ data: Delivery }>(() => api.put(`/deliveries/${id}`, body))
}

export const createDelivery = async (body: Partial<Delivery>) => {
  return asyncify<{ data: Delivery }>(() => api.post('/deliveries', body))
}

export const deleteDelivery = async ({ id }: { id: string }) => {
  return asyncify<unknown>(() => api.delete(`/deliveries/${id}`))
}

export const createDeliveryImage = async ({ id, ...body }) => {
  return asyncify<{ data: Delivery }>(() => api.post(`/deliveries/${id}/image`, body))
}

export const deleteDeliveryImage = async ({ deliveryId, imageId }) => {
  return asyncify<{ data: Delivery }>(() =>
    api.delete(`/deliveries/${deliveryId}/image/${imageId}`)
  )
}

export const createCustomerDocument = async ({ customer_id, ...body }) => {
  return asyncify<{ data: Delivery }>(() => api.post(`/customers/${customer_id}/document`, body))
}

// DRIVER SCHEDULES
/////////////////////////////////////////////////////////////////////

export const getDriverSchedules = async ({
  range_start,
  range_end,
}: {
  range_start: string
  range_end: string
}) => {
  return asyncify<{ data: Schedule[] }>(() =>
    api.get('/schedules', { params: { range_start, range_end } })
  )
}

export const updateDriverSchedule = async ({ id, ...body }: { id: string; config: boolean[] }) => {
  return asyncify<{ data: Schedule }>(() => api.put(`/schedules/${id}`, body))
}

export const updateDriverScheduleEvent = async ({
  id,
  ...body
}: {
  id: string
  date: string
  type: string
}) => {
  return asyncify<{ data: any }>(() => api.put(`/schedules/${id}/events`, body))
}

// REPORTS
/////////////////////////////////////////////////////////////////////

export const getReportsHeadline = async () => {
  return asyncify<HeadlineReport>(() => api.get('/reports/headline2'))
}
export const getReportsSeries = async ({ start, end }: { start: string; end: string }) => {
  return asyncify<SeriesReport>(() => api.get('/reports/series', { params: { start, end } }))
}

// CHECKLISTS
/////////////////////////////////////////////////////////////////////

export const getChecklistItems = async () => {
  return asyncify<{ data: ChecklistItem[] }>(() => api.get('/checklist-items'))
}

export const getChecklistEntries = async () => {
  return asyncify<{ data: ChecklistEntry[] }>(() => api.get('/checklist-entries'))
}

export const getChecklistEntriesByDateAndDriver = async ({ date_driver_id }) => {
  return asyncify<{ data: ChecklistEntry[] }>(() =>
    api.get('/checklist-entries', { params: { date_driver_id } })
  )
}

export const updateChecklistEntry = async ({ id, ...body }: Partial<ChecklistEntry>) => {
  return asyncify<ChecklistEntry>(() => api.put(`/checklist-entries/${id}`, body))
}

export const createChecklistEntry = async (body: Partial<ChecklistEntry>) => {
  return asyncify<ChecklistEntry>(() => api.post('/checklist-entries', body))
}

// ADDRESSES
/////////////////////////////////////////////////////////////////////

export const getAddressSearch = async (query: string) => {
  return asyncify<{ data: AddressSearchResult[] }>(() =>
    api.get('/address-search', { params: { query } })
  )
}

// BILLING
/////////////////////////////////////////////////////////////////////

interface BillingResponse {
  customer: Customer
  history: StripeEvent[]
  subscriptions: CustomerSubscription[]
  activity: BillingActivity[]
  availableSubscriptions: {
    variant_id: string
    variant_name: string
    service_id: string
    service_name: string
  }[]
}

export const getCustomerBillingById = async (customerId: string) => {
  return asyncify<BillingResponse>(() => api.get(`/customer/${customerId}/billing`))
}

export interface BillingInfoResponse {
  breakdown: PricingBreakdown
  service: TaskService
  candidateSubscription: CustomerSubscription
  serviceVariant: TaskServiceVariant
}

export const getCustomerBillingInfoById = async ({
  customerId,
  serviceId,
  serviceVariantId,
}: {
  customerId: string
  serviceId?: string
  serviceVariantId?: string
}) => {
  return asyncify<BillingInfoResponse>(() =>
    api.get(
      `/customer/${customerId}/billing-info?` +
        queryString.stringify({
          service_id: serviceId,
          service_variant_id: serviceVariantId,
        })
    )
  )
}

export const getCustomerBillingVariants = async ({
  customerId,
  serviceId,
}: {
  customerId: string
  serviceId?: string
}) => {
  return asyncify<{ options: { value: string; label: string }[] }>(() =>
    api.get(
      `/customer/${customerId}/billing-variants?` + queryString.stringify({ service_id: serviceId })
    )
  )
}

export const createCustomerSubscription = async ({
  customer_id,
  ...body
}: {
  customer_id: string
  service_variant_id: string
}) => {
  return asyncify<{ ok: boolean }>(() =>
    api.post(`/customer/${customer_id}/billing/subscription`, body)
  )
}

export const cancelCustomerSubscriptionById = async ({
  customer_id,
  subscription_id,
}: {
  customer_id: string
  subscription_id: string
}) => {
  return asyncify<{ ok: boolean }>(() =>
    api.post(`/customer/${customer_id}/subscription/${subscription_id}/cancel`)
  )
}

// CLIENT
/////////////////////////////////////////////////////////////////////

export const getClientBilling = async () => {
  return asyncify<BillingResponse>(() => api.get(`/client/billing`))
}
export const getClientDocumentPdf = async (id: string) => {
  return asyncify<{ link: string }>(() => api.get(`/client/documents/${id}/pdf`))
}

export const updateClient = async (body: Partial<Customer>) => {
  return asyncify<{ data: Customer }>(() => api.put('/client/customer', body))
}

export const postSessionComplete = async (body: { session_id: string; invoice_id?: string }) => {
  return asyncify<Record<string, any>>(() => api.post(`/client/billing/session/complete`, body))
}
