import { useMemo } from 'react'
import { get, merge } from 'lodash'
import { useTenant } from 'src/queries'

const BASE_TEXT = {
  task_label: 'Task',
  task_labe_plural: 'Tasks',
  task_form: {
    user_label: 'UserLabel',
    vendor_label: 'VendorLabel',
    customer_label: 'CustomerLabel',
  },
}

export function useConfig() {
  const tenantQuery = useTenant()

  return useMemo(() => {
    return {
      clientConfig: (key: string, defaultValue: any = null) => {
        return get(tenantQuery.data?.clientConfig, key, defaultValue)
      },

      text: (path: string, defaultValue: string = '') => {
        const CONFIG_TEXT = tenantQuery.data?.clientConfig?.text
        return get(merge(BASE_TEXT, CONFIG_TEXT), path, defaultValue)
      },
    }
  }, [tenantQuery.data])
}

const THE_CONFIG = {
  NODE_ENV: process.env.NODE_ENV,
}

export default THE_CONFIG
