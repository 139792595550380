import React, { useCallback, useEffect, useRef } from 'react'
import { RouteComponentProps } from '@reach/router'
import useComponentSize from '@rehooks/component-size'
import { Column } from 'src/ui'
import { FlexRouter } from 'src/routing'
import { useCalendar } from 'src/store/calendar'
import * as utils from 'src/utils'
import { useSettings } from 'src/utils/settings'
import { useSchedule, useUrlSync } from 'src/schedule/hooks'
import ScheduleToolbar from 'src/schedule/schedule-toolbar'
import ScheduleMobile from 'src/schedule/schedule-mobile'
import ScheduleDesktop from 'src/schedule/schedule-desktop'
import ScheduleSidebar from 'src/schedule/schedule-sidebar'

export default function Schedule() {
  const containerRef = useRef(null)
  const { width } = useComponentSize(containerRef)
  const isMobile = width !== 0 && width < 600

  return (
    <Column height="100%" flex={1} ref={containerRef}>
      <FlexRouter styles={{ height: '100%' }}>
        <Calendar path="view/:routeId" isMobile={isMobile} />
        <Calendar path=":routeView/:routeDate" isMobile={isMobile} />
        <Calendar path=":routeView" isMobile={isMobile} />
        <Calendar default isMobile={isMobile} />
      </FlexRouter>
    </Column>
  )
}

///////////////////////////////////////////////////////////////////////////////////////////////////

const DEFAULT_STATUS_FILTERS = ['scheduled', 'in-progress', 'completed', 'canceled']

const calendarSelector = ({ view, setView, markerDate }) => ({ view, setView, markerDate })

type CalendarView = 'week' | '3day' | 'day' | 'list'

interface CalendarProps extends RouteComponentProps {
  routeId?: string
  routeView?: CalendarView
  routeDate?: string
  isMobile?: boolean
}

function Calendar({ routeId, routeView, routeDate, isMobile }: CalendarProps) {
  const { get: getSetting, set: setSetting } = useSettings()
  const statusFilters =
    getSetting('deliveries.calendar.status_filter', DEFAULT_STATUS_FILTERS) ?? []
  const setStatusFilters = useCallback(
    (filters) => setSetting('deliveries.calendar.status_filter', filters),
    [setSetting]
  )
  const { view, setView, markerDate } = useCalendar(calendarSelector)
  const { anyLoading, deliveries, resources, events } = useSchedule({ statusFilters })

  useUrlSync({ view, markerDate, routeId })

  // Force "day" view on mobile
  useEffect(() => {
    if (!isMobile || view === 'day') return
    setView('day')
  }, [isMobile, view, setView])

  return (
    <>
      <ScheduleToolbar {...{ isMobile, statusFilters, setStatusFilters }} />

      {isMobile ? (
        <ScheduleMobile
          isLoading={anyLoading}
          events={events.filter((e) =>
            utils.momentLocalizedFromUtcString(e.starts_at).isSame(markerDate, 'date')
          )}
        />
      ) : (
        <ScheduleDesktop
          isLoading={anyLoading}
          date={utils.momentLocalizedToDate(markerDate)}
          {...{ view, events, resources }}
        />
      )}

      <ScheduleSidebar deliveries={deliveries} />
    </>
  )
}
