import React from 'react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { Button, Menu, MenuButton, MenuList, MenuOptionGroup, MenuItemOption, Text } from 'src/ui'

const sortByName = items =>
  [...items].sort(({ name: na }, { name: nb }) => (na.toLowerCase() < nb.toLowerCase() ? -1 : 1))

interface ItemMenuProps {
  width?: number
  label: string
  items: {
    id: string
    name: string
  }[]
  buttonLabel?: (args: { selected: number; total: number; label: string }) => string
  selectedItems: string[]
  setSelectedItems: (args: any) => void
}

export const ItemMenu: React.FC<ItemMenuProps> = ({
  width = 150,
  label,
  items,
  buttonLabel,
  selectedItems,
  setSelectedItems,
}) => {
  return (
    <Menu closeOnSelect={false}>
      <MenuButton
        as={Button}
        {...{
          size: 'sm',
          rightIcon: <ChevronDownIcon />,
          colorScheme: selectedItems.length > 0 ? 'purple' : undefined,
        }}
      >
        {buttonLabel ? (
          buttonLabel({ selected: selectedItems.length, total: items.length, label })
        ) : (
          <>
            {selectedItems.length} / {items.length} {label}
          </>
        )}
      </MenuButton>

      <MenuList
        width={width}
        minWidth={width}
        maxHeight="300px"
        overflow="auto"
        placement="bottom-end"
      >
        <MenuOptionGroup
          // title="Vendor"
          type="checkbox"
          value={selectedItems}
          onChange={value => setSelectedItems(value)}
        >
          {sortByName(items).map(item => (
            <MenuItemOption key={item.id} value={item.id}>
              <Text maxWidth={width - 55} isTruncated>
                {item.name}
              </Text>
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  )
}
