import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { Formik, FormikProps, Field } from 'formik'
import * as Yup from 'yup'
import { Table, Thead, Tbody, Tr, Th, Td, Spinner, BoxProps } from '@chakra-ui/react'
import { SelectOption } from 'src/ui/select'
import {
  Box,
  Row,
  Button,
  Divider,
  Stack,
  Heading,
  Collapse,
  Badge,
  FormikElements,
  useDisclosure,
} from 'src/ui'
import { useDocuments, useCustomerMutations } from 'src/queries'
import { useMutationHandlers } from 'src/utils/queries'

interface DocumentsProps {
  customer: Customer
}

export default function Documents({ customer }: DocumentsProps) {
  const [isAdding, setIsAdding] = useState(false)
  const itemQuery = useDocuments()

  return (
    <>
      <Row mb={2} h="32px" justifyContent="space-between" alignItems="center">
        <Heading size="sm">{isAdding ? 'Add Document' : 'Documents'}</Heading>

        {!isAdding && (
          <Button size="xs" width={140} colorScheme="purple" onClick={() => setIsAdding(!isAdding)}>
            Add Document
          </Button>
        )}
      </Row>

      {isAdding && (
        <>
          {itemQuery.isSuccess ? (
            <>
              <AddDocumentForm
                customer={customer}
                documents={itemQuery.data}
                setIsAdding={setIsAdding}
              />
              <Divider my={4} />
            </>
          ) : (
            <Loading />
          )}
        </>
      )}

      {customer.documents?.length > 0 && (
        <Stack>
          {customer.documents.map((document) => (
            <DocumentItem key={document.id} document={document} />
          ))}
        </Stack>
      )}
    </>
  )
}

///////////////////////////////////////////////////////////////////////////////////////////////////

interface DocumentItemProps {
  document: TaskSignRequest
}

function DocumentItem({ document }: DocumentItemProps) {
  const { isOpen, onToggle } = useDisclosure()
  const currentStatusLabel = currentStatus(document)

  return (
    <Stack spacing={4} p={4} borderRadius={3} bg="gray.100">
      <Row justifyContent="space-between">
        <Box fontWeight="bold">{document.name}</Box>
        <Box>
          <a href={document.document_url} target="_blank" rel="noopener noreferrer">
            View Document
          </a>
        </Box>
      </Row>

      <Row justifyContent="space-between" alignItems="center">
        <Box>
          <Button
            size="small"
            variant="link"
            leftIcon={isOpen ? <ChevronDownIcon /> : <ChevronRightIcon />}
            onClick={onToggle}
          >
            View History
          </Button>
        </Box>
        <Box>
          <Badge colorScheme={isSigned(currentStatusLabel) ? 'purple' : undefined} variant="subtle">
            {currentStatusLabel}
          </Badge>
        </Box>
      </Row>

      <Collapse in={isOpen} animateOpacity>
        <Table size="sm" bg="white">
          <Thead>
            <Tr>
              <Th w="40%">Event</Th>
              <Th>Time</Th>
            </Tr>
          </Thead>
          <Tbody>
            {statusesAsList(document.statuses).map((row) => (
              <Tr key={row.key}>
                <Td>{row.label}</Td>
                <Td whiteSpace="pre">{formatDate(row.ts)}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Collapse>
    </Stack>
  )
}

const STATUS_LABELS = {
  // created: 'Created',
  // converted: 'Converted',
  sent: 'Sent',
  // signer_viewed_email: 'Viewed Email',
  signer_viewed: 'Viewed',
  // signer_signed: 'Signed',
  signed: 'Signed',
}

function currentStatus(document: TaskSignRequest) {
  const [status] = statusesAsList(document.statuses).slice(-1)

  return status?.label
}

function isSigned(label) {
  return STATUS_LABELS.signed === label
}

function statusesAsList(statuses: TaskSignRequest['statuses']) {
  const list = Object.keys(statuses)
    .filter((key) => !!STATUS_LABELS[key])
    .map((key) => ({ key, label: STATUS_LABELS[key], ts: statuses[key] }))

  return [...list].sort((a, b) => (a.ts < b.ts ? -1 : 1))
}

function formatDate(ts: string) {
  return new Date(ts).toLocaleString()
}

///////////////////////////////////////////////////////////////////////////////////////////////////

const FormSchema = Yup.object().shape({
  customer_id: Yup.string().required('Required'),
  document_id: Yup.string().required('Required'),
  name: Yup.string().required('Required'),
  subject: Yup.string().required('Required'),
  prefill_tags: Yup.object(),
})

export type FormValues = DocumentCreateParams
export type FormikBag = FormikProps<FormValues>

interface AddDocumentFormProps {
  customer: Customer
  documents: TaskDocument[]
  setIsAdding: (a: boolean) => void
}

function AddDocumentForm({ customer, documents, setIsAdding }: AddDocumentFormProps) {
  const { createCustomerDocument } = useCustomerMutations()
  const { onSuccess, onError } = useMutationHandlers({
    invalidateQueries: [['customers', { id: customer.id }]],
    successMessage: 'Document sign request successfully sent',
    errorMessage: 'Error while creating document to sign',
  })

  const onSubmit = useCallback(
    async (values: FormValues, _formikBag: FormikBag) => {
      await createCustomerDocument(
        { customer_id: customer.id, ...values },
        {
          onSuccess: async (...args) => {
            await onSuccess(...args)
            setIsAdding(false)
          },
          onError,
          // onSettled: () => setIsLoading(false),
        }
      )
    },
    [createCustomerDocument, customer, onError, onSuccess, setIsAdding]
  )

  return (
    <Formik
      initialValues={{
        customer_id: customer.id,
        document_id: undefined,
        name: '',
        subject: '',
        prefill_tags: {},
      }}
      validationSchema={FormSchema}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      {(formikBag: FormikBag) => <TheForm {...{ formikBag, customer, documents, setIsAdding }} />}
    </Formik>
  )
}

function TheForm({
  formikBag,
  customer,
  documents,
  setIsAdding,
}: {
  customer: Customer
  formikBag: FormikBag
  documents: TaskDocument[]
  setIsAdding: (a: boolean) => void
}) {
  const { setFieldValue } = formikBag
  const documentOptions: SelectOption[] =
    documents?.map((doc) => ({ label: doc.name, value: doc.id })) ?? []
  const selectedDocumentId = formikBag.values.document_id
  const selectedDocument = useMemo(
    () => documents.find((d) => d.id === selectedDocumentId),
    [documents, selectedDocumentId]
  )

  useEffect(() => {
    if (!selectedDocument) return

    setFieldValue('name', `${customer.name} | ${selectedDocument.name}`)
    setFieldValue('subject', selectedDocument.default_subject)
  }, [customer, selectedDocument, setFieldValue])

  return (
    <Stack spacing={3}>
      <Row justifyContent="space-between">
        <Box>
          <Field
            id="document_id"
            name="document_id"
            component={FormikElements.Select}
            options={documentOptions}
            selectProps={{
              width: 300,
              isClearable: false,
              placeholder: 'Select a Document',
            }}
          />
        </Box>
      </Row>

      <Box>
        <Field
          label="Document Name"
          name="name"
          id="name"
          component={FormikElements.Input}
          size="sm"
        />
      </Box>

      <Box>
        <Field
          label="E-mail Subject"
          name="subject"
          id="subject"
          component={FormikElements.Input}
          size="sm"
        />
      </Box>

      {selectedDocument && selectedDocument.prefill_tags?.length > 0 && (
        <>
          <Row flexWrap="wrap">
            {selectedDocument?.prefill_tags.map((pt, index) => (
              <Box key={pt.key} width="50%" mb={4} pr={index % 2 === 0 ? 2 : undefined}>
                <Field
                  id={`prefill_tags.${pt.key}`}
                  name={`prefill_tags.${pt.key}`}
                  label={pt.label}
                  size="sm"
                  component={
                    pt.type === 'checkbox' ? FormikElements.Checkbox : FormikElements.Input
                  }
                />
              </Box>
            ))}
          </Row>
        </>
      )}

      <Row justifyContent="flex-end">
        <Button
          size="sm"
          variant="ghost"
          mr={2}
          isDisabled={formikBag.isSubmitting}
          onClick={() => setIsAdding(false)}
        >
          Cancel
        </Button>

        <Button
          size="sm"
          colorScheme="purple"
          width="70px"
          isLoading={formikBag.isSubmitting}
          onClick={formikBag.submitForm}
        >
          Add
        </Button>
      </Row>

      {/* <Box fontSize={12} as="pre">
        {JSON.stringify(formikBag.values, null, 2)}
      </Box> */}
    </Stack>
  )
}

function Loading(props: BoxProps) {
  return (
    <Row w="100%" justifyContent="center" {...props}>
      <Spinner speed="0.65s" size="sm" />
    </Row>
  )
}
