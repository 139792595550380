import React from 'react'
import { RouteComponentProps } from '@reach/router'
import { useDisclosure } from 'src/ui'
import { DeleteDialog } from 'src/manage/dialogs'
import DocumentsList from 'src/manage/documents/list'
import DocumentForm from 'src/manage/documents/form'
import { useDocuments, useDocumentMutations } from 'src/queries'
import { useMutationHandlers } from 'src/utils/queries'

interface DocumentsProps extends RouteComponentProps {}

export default function Documents(props: DocumentsProps) {
  let [objectToEdit, setObjectToEdit] = React.useState<TaskDocument>()
  let { isOpen, onOpen, onClose } = useDisclosure()
  let { deleteDialog, setDeleteDialog, resetDeleteDialog } = useDeleteDialog()
  let itemQuery = useDocuments()
  let { createDocument, updateDocument, deleteDocument } = useDocumentMutations()
  let { onSuccess, onError } = useMutationHandlers({
    invalidateQueries: ['documents'],
    successMessage: 'Document Deleted',
    errorMessage: 'Failed to delete documents',
  })

  return (
    <>
      <DocumentsList
        data={itemQuery.data || []}
        isLoading={itemQuery.status === 'loading'}
        startCreate={onOpen}
        startEdit={user => {
          setObjectToEdit(user)
          onOpen()
        }}
        startDelete={obj => setDeleteDialog(state => ({ ...state, obj }))}
      />

      <DocumentForm
        isOpen={isOpen}
        obj={objectToEdit}
        saveObject={objectToEdit ? updateDocument : createDocument}
        onDismiss={() => {
          setObjectToEdit(null)
          onClose()
        }}
      />

      <DeleteDialog
        isOpen={!!deleteDialog.obj}
        isLoading={deleteDialog.fetching}
        title="Delete Document"
        body={
          <>Are you sure you want to delete "{deleteDialog.obj ? deleteDialog.obj.name : ''}"?</>
        }
        onDismiss={resetDeleteDialog}
        onConfirm={async () => {
          setDeleteDialog(state => ({ ...state, fetching: true }))

          await deleteDocument({ id: deleteDialog.obj.id }, { onSuccess, onError })

          resetDeleteDialog()
        }}
      />
    </>
  )
}

///////////////////////////////////////////////////////////////////////////////////////////////////

export const useDeleteDialog = () => {
  let mountedRef = React.useRef(true)

  let dialogInitial = { fetching: false, obj: null }
  let [deleteDialog, setDeleteDialog] = React.useState(dialogInitial)

  React.useEffect(() => {
    return () => (mountedRef.current = false)
  }, [])

  return {
    deleteDialog,
    setDeleteDialog,
    resetDeleteDialog: () => {
      if (!mountedRef.current) return
      setDeleteDialog(dialogInitial)
    },
  }
}
