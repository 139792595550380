import React, { useState, useEffect } from 'react'
import { groupBy } from 'lodash'
import { Stat, StatLabel, StatNumber, StatGroup, Tooltip } from 'src/ui'
import * as utils from 'src/utils'
import { useConfig } from 'src/utils/config'
import { calcDeliveryStats } from 'src/deliveries/utils'

interface DeliveryStatsProps {
  isLoading: boolean
  deliveries: Delivery[]
}

export default function DeliveryStats({ isLoading, deliveries }: DeliveryStatsProps) {
  const stats = useDeliveryStats({ isLoading, deliveries })

  return (
    <StatGroup textAlign="center">
      {stats.map((stat) => (
        <Stat key={stat.label}>
          <Tooltip placement="top" label={stat.labelTooltip} aria-label={stat.labelTooltip}>
            <StatLabel color="gray.500">{stat.label}</StatLabel>
          </Tooltip>

          <StatNumber>{stat.value || '-'}</StatNumber>
        </Stat>
      ))}
    </StatGroup>
  )
}

function useDeliveryStats({ isLoading, deliveries }: DeliveryStatsProps) {
  const [stats, setStats] = useState(INITIAL_STATS)
  const { text } = useConfig()
  const taskLabelPlural = text('task_labe_plural')

  useEffect(() => {
    if (isLoading) {
      setStats(INITIAL_STATS)
      return
    }

    if (!deliveries) return

    const vendorCount = Object.keys(groupBy(deliveries, 'vendor_id')).length
    const { deliveryCount, corporateCount, pricedCount, averagePrice } = calcDeliveryStats(
      deliveries
    )

    setStats([
      {
        label: 'Vendors',
        labelTooltip: 'Unique vendors',
        value: vendorCount,
      },
      {
        label: taskLabelPlural,
        labelTooltip: 'Total deliveries across all vendors',
        value: deliveryCount,
      },
      {
        label: 'Corporate',
        labelTooltip: 'Total corporate deliveries',
        value: corporateCount,
      },
      {
        label: 'Priced',
        labelTooltip: 'Total priced deliveries',
        value: pricedCount,
      },
      {
        label: 'Average Price',
        labelTooltip: 'Average price across all priced deliveries',
        value: utils.formatMoney(Math.round(averagePrice)),
      },
    ])
  }, [isLoading, deliveries, taskLabelPlural])

  return stats
}

const INITIAL_STATS = [
  {
    label: 'Vendors',
    labelTooltip: 'Unique vendors',
    value: null,
  },
  {
    label: 'Deliveries',
    labelTooltip: 'Total deliveries across all vendors',
    value: null,
  },
  {
    label: 'Corporate',
    labelTooltip: 'Total corporate deliveries',
    value: null,
  },
  {
    label: 'Priced',
    labelTooltip: 'Total priced deliveries',
    value: null,
  },
  {
    label: 'Average Price',
    labelTooltip: 'Average price across all priced deliveries',
    value: null,
  },
]
