import React from 'react'
import { CheckIcon, WarningIcon, WarningTwoIcon, ChatIcon } from '@chakra-ui/icons'
import { RouteComponentProps } from '@reach/router'
import { useQuery } from 'react-query'
import moment from 'moment'
import { get, groupBy } from 'lodash'
import {
  Box,
  Row,
  Column,
  Button,
  Heading,
  Icon,
  Modal,
  Stack,
  Table,
  TimeDate,
  useTable,
} from 'src/ui'
import * as api from 'src/api'

const ChecklistIndex: React.FC<RouteComponentProps> = () => {
  let checklistQuery = useQuery(
    ['checklist-entries'],

    async (_key) => {
      let [err, response] = await api.getChecklistEntries()

      if (err) throw err

      return response.data.data
    }
  )

  return <Checklist fetching={checklistQuery.isLoading} data={checklistQuery.data || []} />
}

export default ChecklistIndex

interface ChecklistProps {
  fetching: boolean
  data?: any[]
}

const Checklist: React.FC<ChecklistProps> = ({ fetching, data }) => {
  let { checklistDialog, setChecklistDialog, resetChecklistDialog } = useChecklistDialog()

  let columns = React.useMemo(
    () => [
      {
        id: 'created_at',
        accessor: 'created_at',
        Header: 'Date',
        Cell: ({ cell }) => <TimeDate date={cell.value} />,
      },
      {
        id: 'vehicle_name',
        accessor: 'vehicle_name',
        Header: 'Vehicle',
      },
      {
        id: 'driver_name',
        accessor: 'driver_name',
        Header: 'Driver',
      },
      {
        id: 'date_driver_id',
        accessor: 'date_driver_id',
        Header: 'index',
      },
      {
        id: '_responses',
        Header: 'Responses',
        accessor: '_responses',
        disableSorting: true,
        Cell: ({ row }) => {
          let obj = row.original

          let warnCount = obj.responses.reduce(
            (acc, resp) => acc + (resp.response === 'warning' ? 1 : 0),
            0
          )
          let failCount = obj.responses.reduce(
            (acc, resp) => acc + (resp.response === 'fail' ? 1 : 0),
            0
          )
          let allOkay = warnCount + failCount === 0

          return (
            <Row alignItems="center" fontWeight={600} color="gray.600">
              {allOkay && (
                <Box mr={2}>
                  <Icon as={CheckIcon} color="green.600" /> All OK
                </Box>
              )}

              {warnCount > 0 && (
                <Box mr={2}>
                  <Icon as={WarningTwoIcon} color="yellow.600" /> {warnCount} Warning(s)
                </Box>
              )}

              {failCount > 0 && (
                <Box>
                  <Icon as={WarningIcon} color="red.600" /> {failCount} Failure(s)
                </Box>
              )}
            </Row>
          )
        },
      },
      {
        id: '_comments',
        accessor: '_comments',
        Header: 'Comments',
        disableSorting: true,
        Cell: ({ row }) => {
          let obj = row.original
          let commentCount = obj.responses.reduce((acc, resp) => acc + (!!resp.comments ? 1 : 0), 0)

          return (
            <Row alignItems="center" fontWeight={600} color="gray.600">
              <Box>
                <Icon as={ChatIcon} color="gray.600" /> {commentCount}
              </Box>
            </Row>
          )
        },
      },
    ],
    []
  )

  let {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    // rows,
    page,
    pagination,
    totalCount,
  } = useTable({
    data,

    tableOptions: {
      initialState: {
        sortBy: [{ id: 'created_at', desc: true }],
      },
    },

    columns,
  })

  return (
    <>
      <Heading size="md" mb={4} color="gray.600">
        Checklist Entries
      </Heading>

      <Table {...getTableProps()}>
        <Table.Header headerGroups={headerGroups} />
        <Table.Body
          isLoading={fetching}
          rows={page}
          getTableBodyProps={getTableBodyProps}
          prepareRow={prepareRow}
          onRowClick={(obj) => setChecklistDialog({ fetching: false, obj })}
        />
      </Table>

      {!fetching && <Table.Pagination totalCount={totalCount} {...pagination} />}

      <ChecklistDialog
        isOpen={!!checklistDialog.obj}
        isLoading={checklistDialog.fetching}
        title="Checklist Entry"
        onDismiss={resetChecklistDialog}
        onConfirm={resetChecklistDialog}
        entry={checklistDialog.obj}
      />
    </>
  )
}

// export default Checklist

///////////////////////////////////////////////////////////////////////////////

export const useChecklistDialog = () => {
  let mountedRef = React.useRef(true)
  let dialogInitial = { fetching: false, obj: null }
  let [checklistDialog, setChecklistDialog] = React.useState(dialogInitial)

  React.useEffect(() => () => (mountedRef.current = false), [])

  return {
    checklistDialog,
    setChecklistDialog,
    resetChecklistDialog: () => {
      if (!mountedRef.current) return
      setChecklistDialog(dialogInitial)
    },
  }
}

interface DeleteDialogProps {
  isOpen: boolean
  isLoading: boolean
  title: string | React.ReactNode
  onConfirm: () => void
  onDismiss: () => void
  entry: any
}

export const ChecklistDialog: React.FC<DeleteDialogProps> = ({
  isOpen,
  // isLoading,
  title,
  onDismiss,
  // onConfirm,
  entry,
}) => {
  let dialogRef = React.useRef(null)
  let responses = get(entry, 'responses', [])
  let itemGroups = groupBy(responses, 'group')

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      scrollBehavior="inside"
      onClose={onDismiss}
      footer={
        <Stack isInline spacing={3} align="center">
          <Button size="sm" variant="ghost" ref={dialogRef} onClick={onDismiss}>
            Close
          </Button>
        </Stack>
      }
    >
      <Row mb={4} color="gray.700">
        <Box flex={1} fontSize="lg" fontWeight={600}>
          {entry ? moment(entry.created_at).format('dddd, MMMM D, YYYY') : null}
        </Box>

        <Column fontWeight={600} textAlign="right">
          <Box fontSize="lg">{entry ? entry.vehicle_name : null}</Box>
          <Box fontSize="md">{entry ? entry.driver_name : null}</Box>
        </Column>
      </Row>

      <Stack spacing={4} shouldWrapChildren>
        {Object.keys(itemGroups).map((group) => (
          <ResponseGroup key={group} title={group} responses={itemGroups[group]} />
        ))}
      </Stack>
    </Modal>
  )
}

const ResponseGroup = ({ title, responses }) => {
  return (
    <>
      <Heading size="sm" mb={3} color="gray.600">
        {title}
      </Heading>

      <Stack spacing={2}>
        {sortResponses(responses).map((resp, index) => (
          <Box
            width="100%"
            key={resp.item_id}
            pb={2}
            borderBottom={responses.length - 1 !== index ? '1px solid #ddd' : undefined}
          >
            <Row>
              <Box width="32px">
                {resp.response === 'ok' && <Icon as={CheckIcon} color="green.600" />}
                {resp.response === 'warning' && <Icon as={WarningTwoIcon} color="yellow.600" />}
                {resp.response === 'fail' && <Icon as={WarningIcon} color="red.600" />}
              </Box>

              <Box flex={1}>{resp.text}</Box>
            </Row>

            {!!resp.comments && (
              <Row alignItems="center" pt={2} ml="32px">
                <Box mr={2}>
                  <Icon as={ChatIcon} size="0.875rem" color="gray.600" />
                </Box>

                <Box color="gray.600" fontSize="sm">
                  {resp.comments}
                </Box>
              </Row>
            )}
          </Box>
        ))}
      </Stack>
    </>
  )
}

const sortResponses = (responses) => [...responses].sort((a, b) => a - b)
