import React from 'react'
import { queryCache } from 'react-query'
import { Formik, Form, Field, FormikProps } from 'formik'
import * as Yup from 'yup'
import * as api from 'src/api'
import { Box, Row, Column, Button, ButtonGroup, FormikElements, Modal } from 'src/ui'
import * as utils from 'src/utils'

type FormValues = Partial<Vendor>

const FormSchema = Yup.object().shape({
  name: Yup.string().nullable().required('Required'),
  contact: Yup.string().nullable(),
  phone: Yup.string().nullable(),
  address: Yup.string().nullable(),
})

interface FormProps {
  isOpen: boolean
  obj?: Partial<Vendor>
  saveObject: any
  onDismiss: () => void
}

const VendorForm: React.SFC<FormProps> = ({ isOpen, obj, saveObject, onDismiss }) => {
  let dialogRef = React.useRef(null)

  return (
    <Formik
      enableReinitialize
      validationSchema={FormSchema}
      initialValues={{
        name: obj?.name || '',
        contact: obj?.contact || '',
        rate: obj?.rate || '',
        phone: obj?.phone ? utils.parseToNationalNumber(obj.phone) : '',
        email: obj?.email || '',
        address: obj?.address || '',
      }}
      onSubmit={async (values: FormValues, formikActions: FormikProps<FormValues>) => {
        await saveObject(
          {
            id: obj?.id,
            name: values.name,
            rate: values.rate,
            contact: values.contact,
            email: values.email,
            phone: utils.e164PhoneNumber(values.phone),
            address: values.address,
          },
          {
            onSuccess: async () => {
              await queryCache.invalidateQueries('vendors')

              formikActions.resetForm()
              onDismiss()
            },
            onError: async () => null,
            onSettled: () => {
              formikActions.setSubmitting(false)
            },
          }
        )
      }}
    >
      {(formikBag: FormikProps<FormValues>) => (
        <Modal
          isOpen={isOpen}
          title={obj?.id ? 'Update Vendor' : 'Create Vendor'}
          onClose={() => {
            formikBag.resetForm()
            onDismiss()
          }}
          footer={
            <ButtonGroup spacing={2}>
              <Button
                size="sm"
                variant="ghost"
                ref={dialogRef}
                onClick={() => {
                  formikBag.resetForm()
                  onDismiss()
                }}
              >
                Cancel
              </Button>

              <Button
                size="sm"
                colorScheme="purple"
                isLoading={formikBag.isSubmitting}
                onClick={formikBag.submitForm}
              >
                Save
              </Button>
            </ButtonGroup>
          }
        >
          <Form>
            <Row justifyContent="center">
              <Column width="400px">
                <Row justifyContent="flex-end" alignItems="center" pt={2} pb={4}>
                  {obj?.created_at && (
                    <Box>
                      <TimeLabel
                        label="Created"
                        value={new Date(obj?.created_at).toLocaleDateString()}
                      />
                      {obj?.updated_at && (
                        <>
                          &nbsp;&nbsp;-&nbsp;&nbsp;
                          <TimeLabel
                            label="Updated"
                            value={new Date(obj?.updated_at).toLocaleDateString()}
                          />
                        </>
                      )}
                    </Box>
                  )}
                </Row>

                <Box height="72px" mb={2}>
                  <Field
                    id="name"
                    name="name"
                    label="Name"
                    size="sm"
                    component={FormikElements.Input}
                  />
                </Box>

                <Row height="72px" mb={2}>
                  <Box flex={2}>
                    <Field
                      id="contact"
                      name="contact"
                      label="Contact"
                      size="sm"
                      component={FormikElements.Input}
                    />
                  </Box>

                  <Box width="1rem" />

                  <Box flex={1}>
                    <Field
                      id="rate"
                      name="rate"
                      label="Rate"
                      size="sm"
                      component={FormikElements.Input}
                    />
                  </Box>
                </Row>

                <Box height="72px" mb={2}>
                  <Field
                    id="phone"
                    name="phone"
                    label="Phone"
                    size="sm"
                    component={FormikElements.PhoneNumber}
                  />
                </Box>

                <Box height="72px" mb={2}>
                  <Field
                    id="email"
                    name="email"
                    label="Email"
                    size="sm"
                    component={FormikElements.Input}
                  />
                </Box>

                <Box height="72px" mb={2}>
                  <Field
                    id="address"
                    name="address"
                    label="Address"
                    component={FormikElements.SelectCreatableAsync}
                    selectProps={{
                      width: '100%',
                      loadOptions: addressLoadOptions,
                    }}
                  />
                </Box>
              </Column>
            </Row>
          </Form>
        </Modal>
      )}
    </Formik>
  )
}

export default VendorForm

///////////////////////////////////////////////////////////////////////////////

const TimeLabel = ({ label, value }) => {
  return (
    <small>
      <span css={{ color: '#777' }}>{label}</span> {value}
    </small>
  )
}
const addressLoadOptions = (value: string) => {
  return new Promise(async (yah, nah) => {
    let [err, response] = await api.getAddressSearch(value)
    if (err) return yah([])

    if (!response.data || response.data.data.length === 0) return yah([])

    return yah(response.data.data.map((item) => ({ label: item.label, value: item.label })))
  })
}
