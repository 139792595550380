import React from 'react'
import { css } from '@emotion/core'
import { RouteComponentProps } from '@reach/router'
import { queryCache } from 'react-query'
import moment, { Moment } from 'moment'
import { ChevronLeftIcon, ChevronRightIcon, ChevronDownIcon } from '@chakra-ui/icons'
import { FiTruck, FiHome, FiSun } from 'react-icons/fi'
import {
  chakra,
  Box,
  Row,
  Button,
  Icon,
  IconButton,
  Heading,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
} from 'src/ui'
import { useDriverSchedules, useDriverScheduleMutations } from 'src/queries'

const makeQueryRange = (startDate: Moment) => ({
  range_start: startDate.clone().utc().startOf('year').format(),
  range_end: startDate.clone().utc().endOf('year').format(),
})

const createWeek = (origDate: Moment) => {
  let date = origDate.clone()
  let endOfWeek = date.clone().endOf('week')
  let range = []

  while (date.isSameOrBefore(endOfWeek)) {
    range.push(date.clone())
    date.add(1, 'days')
  }

  return range
}

const useDriverSchedule = () => {
  let [startDate, setStartDate] = React.useState<Moment>(() => moment().startOf('week'))
  let [range, setRange] = React.useState<Moment[]>(() => createWeek(startDate))

  let driverSchedulesQuery = useDriverSchedules(makeQueryRange(startDate))
  let { updateSchedule, updateScheduleEvent } = useDriverScheduleMutations()

  React.useEffect(() => setRange(createWeek(startDate)), [startDate])

  return {
    driverSchedulesQuery,
    startDate,
    range,
    updateSchedule,
    updateScheduleEvent,
    moveWeekForwards: () => setStartDate((state) => state.clone().add(1, 'weeks')),
    moveWeekBackwards: () => setStartDate((state) => state.clone().subtract(1, 'weeks')),
  }
}

const DriverSchedules: React.FC<RouteComponentProps> = () => {
  let {
    startDate,
    range,
    driverSchedulesQuery,
    updateSchedule,
    updateScheduleEvent,
    moveWeekForwards,
    moveWeekBackwards,
  } = useDriverSchedule()

  if (driverSchedulesQuery.isLoading) return null

  let drivers = driverSchedulesQuery.data || []

  return (
    <>
      <Box my={12}>
        <Row alignItems="flex-end" mb={3}>
          <Box color="gray.500" px={2}>
            <Heading size="md" fontWeight={400}>
              Base Schedule
            </Heading>
          </Box>

          <Box flex={1} pl={2} textAlign="right" color="gray.600">
            These settings will be used as the base for all future schedules.
          </Box>
        </Row>

        <Table
          styles={{
            th: {
              '&:not(:first-of-type)': {
                textAlign: 'center',
                width: '12%',
              },
            },

            td: {
              '&:first-of-type': {
                // padding: 8,
              },
              '&:not(:first-of-type)': {
                padding: '0.5rem 0.5rem',
                textAlign: 'center',
                width: '12%',
              },
            },
          }}
        >
          <thead>
            <tr>
              <th>Driver</th>
              {range.map((date, i) => (
                <th key={i}>{date.format('ddd')}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {sortByDriverName(drivers).map(({ driver, schedule }) => (
              <tr key={driver.id}>
                <td css={{ fontWeight: 500 }}>{driver.name}</td>

                {range.map((_, i) => (
                  <BaseScheduleDay
                    key={i}
                    index={i}
                    driver={driver}
                    schedule={schedule}
                    updateSchedule={updateSchedule}
                  />
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </Box>

      <Box>
        <Row position="relative" mb={3}>
          <Box flex={1} color="gray.500" px={2}>
            <Heading size="md" fontWeight={400}>
              {formatRange(startDate)}
            </Heading>
          </Box>

          <Row position="absolute" justifyContent="space-between" width="90px" top={-7} right={0}>
            <Tooltip label="Previous Week">
              <IconButton
                size="md"
                variant="ghost"
                aria-label="Previous Week"
                icon={<ChevronLeftIcon />}
                children={null}
                onClick={moveWeekBackwards}
              />
            </Tooltip>

            <Tooltip label="Next Week">
              <IconButton
                size="md"
                variant="ghost"
                aria-label="Next Week"
                icon={<ChevronRightIcon />}
                children={null}
                onClick={moveWeekForwards}
              />
            </Tooltip>
          </Row>
        </Row>

        <Table
          styles={{
            th: {
              '&:not(:first-of-type)': {
                textAlign: 'center',
                width: '12%',
              },
            },
            td: {
              '&:first-of-type': {
                // padding: 8,
              },
              '&:not(:first-of-type)': {
                padding: '8px 4px',
                textAlign: 'center',
                width: '12%',
              },
            },
          }}
        >
          <thead>
            <tr>
              <th>Driver</th>
              {range.map((date, i) => (
                <th key={i}>{date.format('ddd D')}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {sortByDriverName(drivers).map(({ driver, schedule, events }) => (
              <tr key={driver.id}>
                <td css={{ fontWeight: 500 }}>{driver.name}</td>

                {range.map((date, i) => (
                  <td key={i}>
                    <ScheduleDay
                      date={date}
                      driver={driver}
                      events={events}
                      scheduled={schedule ? schedule[i] : false}
                      updateScheduleEvent={updateScheduleEvent}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </Box>
    </>
  )
}

export default DriverSchedules

///////////////////////////////////////////////////////////////////////////////

const uppercase = (string) => string.charAt(0).toUpperCase() + string.slice(1)

const formatRange = (startDate) => {
  let endDate = startDate.clone().add(6, 'days')
  let endStr = startDate.isSame(endDate, 'month') ? endDate.format('D') : endDate.format('MMMM D')

  return `${startDate.format('MMMM D')} - ${endStr}`
}

const sortByDriverName = (drivers) =>
  [...drivers].sort(({ driver: { name: na } }, { driver: { name: nb } }) =>
    na.toLowerCase() < nb.toLowerCase() ? -1 : 1
  )

///////////////////////////////////////////////////////////////////////////////

const Tooltip = ({ children, label: _label }) => children

interface BaseScheduleDayProps {
  driver: User
  schedule: boolean[]
  index: number
  updateSchedule: any
}

const BaseScheduleDay: React.FC<BaseScheduleDayProps> = ({
  driver,
  schedule,
  index: i,
  updateSchedule,
}) => {
  let [isLoading, setIsLoading] = React.useState(false)

  schedule = schedule || new Array(7).fill(false)
  let isWorking = schedule[i] || false

  return (
    <td key={i} css={{ fontWeight: 500 }}>
      <Tooltip label={isWorking ? 'Working' : 'Off'}>
        <Button
          size="sm"
          variant={!isWorking ? 'outline' : undefined}
          width="100%"
          isLoading={isLoading}
          px={0}
          onClick={async () => {
            let config = [...schedule]
            config[i] = !isWorking

            setIsLoading(true)

            await updateSchedule(
              { id: driver.id, config },
              {
                onSuccess: async () => {
                  await queryCache.invalidateQueries('driver-schedules')
                },
                // onError: async () => {},
                onSettled: () => {
                  setIsLoading(false)
                },
              }
            )
          }}
        >
          {isWorking ? <FiTruck /> : <FiHome />}
        </Button>
      </Tooltip>
    </td>
  )
}

interface ScheduleDayProps {
  date: Moment
  driver: User
  events: ScheduleEvent[]
  scheduled: boolean
  updateScheduleEvent: any
}

const ScheduleDay: React.FC<ScheduleDayProps> = ({
  date,
  driver,
  events,
  scheduled,
  updateScheduleEvent,
}) => {
  let [isLoading, setIsLoading] = React.useState(false)

  let event = events.find((e) => date.isSame(moment(e.date), 'day'))
  let value = event ? event.type : scheduled ? 'working' : 'off'

  const handleUpdate = async (args: { id: string; date: string; type: string }) => {
    setIsLoading(true)

    await updateScheduleEvent(args, {
      onSuccess: async () => {
        await queryCache.invalidateQueries('driver-schedules')
      },
      // onError: async () => {},
      onSettled: () => {
        setIsLoading(false)
      },
    })
  }

  return (
    <Menu>
      <MenuButton
        as={Button}
        size="sm"
        width="100%"
        flex={1}
        isLoading={isLoading}
        rightIcon={<ChevronDownIcon boxSize={5} />}
        css={css`
          @media screen and (max-width: 1200px) {
            .label-text {
              display: none;
            }
          }
        `}
      >
        {value === 'working' && <Icon as={FiTruck} />}
        {value === 'off' && <Icon as={FiHome} />}
        {value === 'vacation' && <Icon as={FiSun} />}
        {value === 'holiday' && <Icon as={FiSun} />}
        <chakra.span className="label-text" fontSize="sm">
          &nbsp;&nbsp;{uppercase(value)}
        </chakra.span>
      </MenuButton>

      <MenuList width="120px">
        <MenuItem
          onClick={() => {
            handleUpdate({
              id: driver.id,
              date: date.format('YYYY-MM-DD'),
              type: 'off',
            })
          }}
        >
          <Row alignItems="center">
            <Box mr={2}>
              <FiHome />
            </Box>
            <Box>Off</Box>
          </Row>
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleUpdate({
              id: driver.id,
              date: date.format('YYYY-MM-DD'),
              type: 'working',
            })
          }}
        >
          <Row alignItems="center">
            <Box mr={2}>
              <FiTruck />
            </Box>
            <Box>Working</Box>
          </Row>
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleUpdate({
              id: driver.id,
              date: date.format('YYYY-MM-DD'),
              type: 'vacation',
            })
          }}
        >
          <Row alignItems="center">
            <Box mr={2}>
              <FiSun />
            </Box>
            <Box>Vacation</Box>
          </Row>
        </MenuItem>
      </MenuList>
    </Menu>
  )
}
