import React from 'react'
import { queryCache } from 'react-query'
import { Formik, Form, Field, FormikProps } from 'formik'
import * as Yup from 'yup'
import * as api from 'src/api'
import { Box, Row, Column, Button, ButtonGroup, FormikElements } from 'src/ui'
import * as utils from 'src/utils'
import { useConfig } from 'src/utils/config'

interface FormValues {
  name: string
  phone: string
  email: string
  address: string
  meta?: { [key: string]: string }
}

const FormSchema = Yup.object().shape({
  name: Yup.string().nullable().required('Required'),
  phone: Yup.string().nullable(),
  email: Yup.string().nullable(),
  address: Yup.string().nullable(),
  meta: Yup.object().nullable(),
})

interface FormProps {
  obj?: Customer
  saveObject: any
  setSidebarId: (id: string) => void
  stopEditing: () => void
  onDismiss: () => void
}

export const CustomerForm: React.FC<FormProps> = ({
  obj,
  saveObject,
  setSidebarId,
  stopEditing,
  onDismiss,
}) => {
  const { clientConfig } = useConfig()
  const customerMetaKeys = clientConfig('customer_meta_keys', [])

  return (
    <>
      <Formik
        initialValues={{
          name: obj?.name || '',
          phone: obj?.phone ? utils.parseToNationalNumber(obj?.phone) : '',
          email: obj?.email || '',
          address: obj?.address || '',
          meta: obj?.meta || {},
        }}
        validationSchema={FormSchema}
        enableReinitialize
        onSubmit={async (values: FormValues, formikActions: FormikProps<FormValues>) => {
          await saveObject(
            {
              id: obj?.id, // will be undefined on new users
              name: values.name,
              phone: utils.e164PhoneNumber(values.phone),
              email: values.email,
              address: values.address,
              meta: values.meta,
            },
            {
              onSuccess: async (customer: Customer, _args) => {
                await queryCache.invalidateQueries('customers')
                formikActions.resetForm()

                setSidebarId(customer.id)
                stopEditing()
              },
              onError: async () => null,
              onSettled: () => {
                formikActions.setSubmitting(false)
              },
            }
          )
        }}
      >
        {(formikBag: FormikProps<FormValues>) => (
          <>
            <Form>
              <Column>
                <Box height="72px" mb={2}>
                  <Field
                    id="name"
                    name="name"
                    label="Name"
                    size="sm"
                    component={FormikElements.Input}
                  />
                </Box>

                <Row>
                  <Box flex={1} height="72px" mb={2} mr={2}>
                    <Field
                      id="phone"
                      name="phone"
                      label="Phone"
                      size="sm"
                      component={FormikElements.PhoneNumber}
                    />
                  </Box>

                  <Box flex={1.5} height="72px" mb={2}>
                    <Field
                      id="email"
                      name="email"
                      label="Email"
                      size="sm"
                      component={FormikElements.Input}
                    />
                  </Box>
                </Row>

                <Box height="72px" mb={2}>
                  <Field
                    id="address"
                    name="address"
                    label="Address"
                    component={FormikElements.SelectCreatableAsync}
                    selectProps={{
                      width: '100%',
                      loadOptions: addressLoadOptions,
                    }}
                  />
                </Box>

                {customerMetaKeys.map((meta) => (
                  <Box key={meta.key} height="72px" mb={2}>
                    <Field
                      id={`meta_${meta.key}`}
                      name={`meta.${meta.key}`}
                      label={meta.label}
                      size="sm"
                      component={FormikElements.Input}
                    />
                  </Box>
                ))}
              </Column>

              <Row justifyContent="flex-end">
                <ButtonGroup spacing={2}>
                  <Button
                    size="sm"
                    variant="ghost"
                    onClick={() => {
                      formikBag.resetForm()
                      if (obj?.id) {
                        stopEditing()
                      } else {
                        onDismiss()
                      }
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    size="sm"
                    colorScheme="purple"
                    isLoading={formikBag.isSubmitting}
                    onClick={formikBag.submitForm}
                  >
                    Save
                  </Button>
                </ButtonGroup>
              </Row>
            </Form>
          </>
        )}
      </Formik>
    </>
  )
}

///////////////////////////////////////////////////////////////////////////////

// const Loading = () => (
//   <Row width="100%" height="250px" alignItems="center" justifyContent="center">
//     <Spinner speed="0.65s" size="xl" />
//   </Row>
// )

function addressLoadOptions(value: string) {
  return new Promise(async (yah, nah) => {
    let [err, response] = await api.getAddressSearch(value)
    if (err) return yah([])

    if (!response.data || response.data.data.length === 0) return yah([])

    return yah(response.data.data.map((item) => ({ label: item.label, value: item.label })))
  })
}
