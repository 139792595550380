import React, { useState, useMemo, useCallback } from 'react'
import { AddIcon, DeleteIcon } from '@chakra-ui/icons'
// import { Link } from '@reach/router'
import matchSorter from 'match-sorter'
import { useCustomers } from 'src/queries'
import { Box, Row, Button, IconButton, Input, Table, useTable, TimeDate } from 'src/ui'
import { prettyPhoneNumber } from 'src/utils'

interface ListProps {
  startCreate: () => void
  startEdit: (obj: any) => void
  startDelete: (args: any) => void
}

// const PLAN_LABELS = {
//   'on-demand': 'On Demand',
//   annual: 'Annual',
//   'bi-annual': 'Bi-Annual',
// }

const STATUS_LABELS = {
  active: 'Active',
  canceled: 'Canceled',
  // init_sub_await_pay: 'Subscription (Balance Due)',
  init: 'Setting up',
  overdue: 'Overdue',
}

function fuzzyTextFilterFn(rows, id: string, filterValue: string) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] })
}
// remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val: string) => !val

function useColumns({ startDelete }) {
  const columns = React.useMemo(
    () => [
      // {
      //   id: 'id',
      //   Header: 'ID',
      //   accessor: 'id',
      // },
      {
        id: 'name',
        Header: 'Name',
        accessor: 'name',
        Cell: ({ cell }) => <Box whiteSpace="pre">{cell.value}</Box>,
      },
      {
        id: 'phone',
        Header: 'Phone',
        accessor: (row) => (row.phone ? prettyPhoneNumber(row.phone) : undefined),
        Cell: ({ cell }) =>
          cell.value ? <Box whiteSpace="pre">{prettyPhoneNumber(cell.value)}</Box> : null,
      },
      // {
      //   id: 'address',
      //   Header: 'Address',
      //   accessor: (row) => (row.address ? row.address : undefined),
      // },
      {
        id: 'billing_status',
        Header: 'Billing Status',
        accessor: (row) => {
          return (
            // {PLAN_LABELS[row.billing_plan]} -
            <>{STATUS_LABELS[row.billing_status] ?? '-'}</>
          )
        },
      },
      {
        id: 'created_at',
        Header: 'Created',
        accessor: 'created_at',
        Cell: ({ cell }) => <TimeDate date={cell.value} />,
      },
      {
        Header: 'Updated',
        accessor: 'updated_at',
        id: 'updated_at',
        Cell: ({ cell }) => (cell.value ? <TimeDate date={cell.value} /> : '-'),
      },
      // {
      //   Header: 'Billing',
      //   // accessor: 'updated_at',
      //   id: 'billing',
      //   Cell: ({ cell }) => {
      //     const customerId = cell.row.original.id

      //     return (
      //       <Link
      //         to={`/customer/billing/${customerId}`}
      //         css={{
      //           display: 'block',
      //           width: '100%',
      //           padding: '0.5rem',
      //           textDecoration: 'none',
      //           '&:hover': { textDecoration: 'none' },
      //         }}
      //       >
      //         Billing Test
      //       </Link>
      //     )
      //   },
      // },
      {
        id: '_actions',
        Header: '',
        accessor: '_actions',
        disableSorting: true,
        Cell: ({ row }) => {
          let obj = row.original

          return (
            <Row justifyContent="flex-end">
              <IconButton
                size="sm"
                color="gray.400"
                variant="ghost"
                icon={<DeleteIcon />}
                aria-label="Delete"
                children={null}
                onClick={(e) => {
                  e.stopPropagation()
                  startDelete(obj)
                }}
              />
            </Row>
          )
        },
      },
    ],
    [startDelete]
  )

  return columns
}

const CustomerList: React.FC<ListProps> = ({ startCreate, startEdit, startDelete }) => {
  const customersQuery = useCustomers()
  const isLoading = customersQuery.isLoading
  const customers = customersQuery.data ?? []
  const columns = useColumns({ startDelete })
  const onRowClick = useCallback((obj) => startEdit(obj), [startEdit])

  return <CustomerTable {...{ isLoading, columns, customers, startCreate, onRowClick }} />
}

function CustomerTable({ isLoading, columns, customers, startCreate, onRowClick }) {
  const [searchValue, setSearchValue] = useState('')
  const filterTypes = useMemo(() => ({ fuzzyText: fuzzyTextFilterFn }), [])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pagination,
    totalCount,
    globalFilter: { isFiltered, setGlobalFilter, preFilterCount },
  } = useTable({
    data: customers,
    tableOptions: {
      initialState: { sortBy: [{ id: 'name' }] },
      filterTypes,
    },
    columns,
  })

  return (
    <>
      <Row alignItems="center" mb={2}>
        <Box flex={1}>
          <Input
            type="text"
            size="sm"
            placeholder="Search..."
            width="50%"
            bg="white"
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value)
              setGlobalFilter(e.target.value)
            }}
          />
        </Box>

        <Box>
          <Button
            size="sm"
            color="gray.400"
            variant="ghost"
            leftIcon={<AddIcon />}
            onClick={startCreate}
          >
            New
          </Button>
        </Box>
      </Row>

      <Table {...getTableProps()}>
        <Table.Header headerGroups={headerGroups} />
        <Table.Body
          isLoading={isLoading}
          rows={page}
          getTableBodyProps={getTableBodyProps}
          prepareRow={prepareRow}
          onRowClick={onRowClick}
        />
      </Table>

      {!isLoading && (
        <Table.Pagination
          totalCount={totalCount}
          isFiltered={isFiltered}
          preFilterCount={preFilterCount}
          {...pagination}
        />
      )}
    </>
  )
}

export default CustomerList
