import React, { useMemo, useCallback } from 'react'
import useComponentSize from '@rehooks/component-size'
import moment from 'moment-timezone'
import {
  Calendar as BigCalendar,
  Event as BigCalendarEvent,
  Views as BigCalendarViewsType,
} from 'react-big-calendar'
import { Box, Spinner } from 'src/ui'
import * as utils from 'src/utils'
import { useCommons } from 'src/schedule/hooks'
import { useCalendar } from 'src/store/calendar'
import { useAuthState } from 'src/store/auth'
import {
  CustomEventWrapper,
  ThreeDayWeek,
  Empty,
  CustomResourceHeader,
  CustomHeader,
} from 'src/schedule/calendars/parts'
import {
  localizer,
  decideHeight,
  eventPropGetter,
  createNewObject,
  driverDaySchedule,
} from 'src/schedule/calendars/utils'

const BigCalendarViews: BigCalendarViewsType = {
  MONTH: 'month',
  WEEK: 'week',
  WORK_WEEK: 'work_week',
  DAY: 'day',
  AGENDA: 'agenda',
}

// CALENDAR
///////////////////////////////////////////////////////////////////

const STEP = 30
const TIME_RANGE_DEFAULT = {
  MIN: new Date(2000, 0, 1, 8, 0, 0),
  MAX: new Date(2000, 0, 1, 18, 0, 0),
}

const calcTimeRange = (events = []) => {
  if (events.length === 0) return TIME_RANGE_DEFAULT

  let result = events.reduce(
    (acc, event: CalendarEvent) => {
      let actualStart = moment(event.starts_at)
      let normStart = moment().hour(actualStart.hour()).minute(actualStart.minute()).second(0)

      let actualEnd = moment(event.ends_at)
      let normEnd = moment().hour(actualEnd.hour()).minute(actualEnd.minute()).second(0)

      return {
        MIN: !acc.MIN || normStart.isBefore(acc.MIN) ? normStart : acc.MIN,
        MAX: !acc.MAX || normEnd.isAfter(acc.MAX) ? normEnd : acc.MAX,
      }
    },
    { MIN: null, MAX: null }
  )

  return {
    MIN: result.MIN.isAfter(TIME_RANGE_DEFAULT.MIN) ? TIME_RANGE_DEFAULT.MIN : result.MIN.toDate(),
    MAX: result.MAX.isBefore(TIME_RANGE_DEFAULT.MAX) ? TIME_RANGE_DEFAULT.MAX : result.MAX.toDate(),
  }
}

interface CalendarEvent extends Delivery, BigCalendarEvent {}

interface CalendarResource {
  driver_id: string
  driver_name: string
  events: ScheduleEvent[]
  schedule: boolean[]
  avatar_color: string
}

interface ScheduleDesktopProps {
  isLoading: boolean
  view: string
  date: Date
  events: CalendarEvent[]
  resources: CalendarResource[]
}

// adds `resourceId` which was missing
interface onSelectSlotArgs {
  start: Date
  end: Date
  slots: Date[] | string[]
  action: 'select' | 'click' | 'doubleClick'
  resourceId?: string
}

const calendarSelector = ({ setDelivery, setDeliveryToEdit }) => ({
  setDelivery,
  setDeliveryToEdit,
})

export default function ScheduleDesktop({
  isLoading,
  view,
  date,
  events,
  resources,
}: ScheduleDesktopProps) {
  const containerRef = React.useRef(null)
  const containerSize = useComponentSize(containerRef)
  const calendarHeight = decideHeight(containerSize.height)
  const { setDelivery, setDeliveryToEdit } = useCalendar(calendarSelector)
  const { isAdmin } = useAuthState()
  const { drivers, vehicles } = useCommons()

  let TIME_RANGE = useMemo(() => calcTimeRange(events), [events])

  let COMPONENTS = useMemo(() => {
    return {
      toolbar: Empty,
      event: CustomEventWrapper,
      // dateCellWrapper: props => null,
      // eventContainerWrapper: props => null,
      eventWrapper: ({ children, event, ...rest }) => {
        return (
          <Box
            css={{
              '.rbc-event-label': {
                backgroundColor: event.driver ? `#${event.driver.avatar_color}80` : undefined,
              },
            }}
          >
            {children}
          </Box>
        )
      },
      resourceHeader: (props) => {
        return <CustomResourceHeader {...props} view={view} drivers={resources} date={date} />
      },
      header: (props) => {
        return <CustomHeader {...props} view={view} drivers={resources} />
      },
    }
  }, [date, resources, view])

  const onSelectSlot = useCallback(
    ({ start, end, resourceId }: onSelectSlotArgs) => {
      setDeliveryToEdit(
        createNewObject({
          start: utils.momentLocalizedParseDate(start),
          end: utils.momentLocalizedParseDate(end),
          driver_id: resourceId,
          drivers,
          vehicles,
        })
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [drivers, vehicles, setDeliveryToEdit]
  )

  const localizerFormats = useMemo(
    () => ({
      eventTimeRangeFormat: ({ start, end }, culture, localizer) =>
        // @ts-ignore
        localizer.format(start, 'LT', culture).replace(':00', '') +
        ' – ' +
        // @ts-ignore
        localizer.format(end, 'LT', culture).replace(':00', ''),
    }),
    []
  )

  return (
    <Box
      ref={containerRef}
      display="flex"
      flexDirection="column"
      position="relative"
      flex={1}
      width="100%"
      maxWidth={view === 'day' ? 1200 : undefined}
      height={calendarHeight}
      mx="auto"
      view={view}
      pb={6}
      // disabledColumns={disabledColumns}
    >
      {view === 'week' && (
        <BigCalendar
          selectable={isAdmin}
          events={events}
          localizer={localizer}
          views={['week']}
          defaultView={BigCalendarViews.WEEK}
          step={STEP}
          date={date}
          onNavigate={() => null}
          min={TIME_RANGE.MIN}
          max={TIME_RANGE.MAX}
          // @ts-ignore
          components={COMPONENTS}
          formats={localizerFormats}
          eventPropGetter={eventPropGetter({ events, resources })}
          onSelectEvent={setDelivery}
          onSelectSlot={onSelectSlot}
        />
      )}

      {view === '3day' && (
        <BigCalendar
          selectable={isAdmin}
          events={events}
          localizer={localizer}
          views={{ week: ThreeDayWeek } as any}
          defaultView={BigCalendarViews.WEEK}
          step={STEP}
          date={date}
          onNavigate={() => null}
          min={TIME_RANGE.MIN}
          max={TIME_RANGE.MAX}
          // @ts-ignore
          components={COMPONENTS}
          formats={localizerFormats}
          eventPropGetter={eventPropGetter({ events, resources })}
          onSelectEvent={setDelivery}
          onSelectSlot={onSelectSlot}
        />
      )}

      {view === 'day' && (
        <BigCalendar
          selectable={isAdmin}
          events={events}
          localizer={localizer}
          views={['day']}
          defaultView={BigCalendarViews.DAY}
          step={STEP}
          date={date}
          onNavigate={() => null}
          min={TIME_RANGE.MIN}
          max={TIME_RANGE.MAX}
          eventPropGetter={eventPropGetter({ events, resources, clampWidth: false })}
          slotPropGetter={
            ((date: Date, driverId: string) => {
              let figured = driverDaySchedule({
                date: utils.momentLocalizedParseDate(date),
                driver: resources.find((r) => r.driver_id === driverId),
              })

              return {
                style: {
                  backgroundColor: figured !== 'working' ? '#f7f7f7' : undefined,
                },
              }
            }) as any
          }
          // @ts-ignore
          components={COMPONENTS}
          formats={localizerFormats}
          onSelectEvent={setDelivery}
          onSelectSlot={onSelectSlot}
          // custom for this view
          resources={resources}
          resourceAccessor="driver_id"
          resourceIdAccessor="driver_id"
          resourceTitleAccessor="driver_name"
        />
      )}

      {isLoading && (
        <Box
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height={calendarHeight}
          borderRadius={4}
          bg="rgba(0,0,0,0.25)"
          color="#fff"
          css={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box>
            <Spinner speed="0.65s" size="xl" />
          </Box>
        </Box>
      )}
    </Box>
  )
}
