import React from 'react'
import { CheckIcon, WarningIcon, WarningTwoIcon, MinusIcon } from '@chakra-ui/icons'
import { RouteComponentProps } from '@reach/router'
import { queryCache } from 'react-query'
import { groupBy } from 'lodash'
import moment, { Moment } from 'moment'
import { Formik, Form, Field, FormikProps } from 'formik'
import * as Routing from 'src/routing'
import { useAuthState } from 'src/store/auth'
import * as utils from 'src/utils'
import * as queryUtils from 'src/utils/queries'
import {
  useVehicles,
  useChecklistItems,
  useChecklistEntries,
  useChecklistMutations,
} from 'src/queries'
import {
  Box,
  Row,
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Stack,
  FormikElements,
  useToast,
} from 'src/ui'

const ChecklistIndex = () => {
  let auth = useAuthState()

  return (
    <Routing.FlexRouter styles={{ height: '100%' }}>
      <Checklist path=":routeDate" auth={auth} />
      <Checklist default auth={auth} />
    </Routing.FlexRouter>
  )
}

export default ChecklistIndex

///////////////////////////////////////////////////////////////////////////////////////////////////

const checklistKey = ({ user, markerDate }) =>
  `${(markerDate || moment()).format('YYYY-MM-DD')}#${user.id}`

interface ChecklistProps extends RouteComponentProps {
  auth: AuthState
  routeDate?: string
}

const Checklist: React.FC<ChecklistProps> = ({ auth: { user }, routeDate }) => {
  let [markerDate] = React.useState<Moment>(() => utils.momentLocalizedParse(routeDate))
  let date_driver_id = checklistKey({ user, markerDate })

  let vehiclesQuery = useVehicles()
  let checklistQuery = useChecklistEntries({ date_driver_id })
  let checklistItemsQuery = useChecklistItems()
  let allQueries = [vehiclesQuery, checklistQuery, checklistItemsQuery]

  if (queryUtils.areAnyLoading(...allQueries)) return <Loading />
  if (queryUtils.areAnyFailed(...allQueries)) return <ErrorMessage />

  return (
    <Stack spacing={4}>
      {/* <Row px={4}>
        <ButtonLink to="/schedule" variant="link" leftIcon="chevron-left">
          Back
        </ButtonLink>
      </Row> */}

      <Row justifyContent="space-between" alignItems="center" px={4} fontSize="lg">
        <Box fontSize="xl">Safety Checklist</Box>
        <Box fontWeight={500}>{markerDate.format('dddd, MMMM D')}</Box>
      </Row>

      <ChecklistForm
        user={user}
        date_driver_id={date_driver_id}
        vehicles={vehiclesQuery.data}
        checklist={checklistQuery.data}
        checklistItems={checklistItemsQuery.data}
      />
    </Stack>
  )
}

///////////////////////////////////////////////////////////////////////////////////////////////////

interface FormValues {
  responses: { [key: string]: string }
  comments: { [key: string]: string }
}

const makeInitialValues = (checklist) => {
  if (!checklist) return { responses: {}, comments: {} }

  return {
    responses: checklist.responses.reduce(
      (acc, r) => (r.response ? { ...acc, [r.item_id]: r.response } : acc),
      {}
    ),
    comments: checklist.responses.reduce(
      (acc, r) => (r.comments ? { ...acc, [r.item_id]: r.comments } : acc),
      {}
    ),
  }
}

interface ChecklistFormProps {
  user: User
  date_driver_id: string
  checklist: ChecklistEntry
  checklistItems: ChecklistItem[]
  vehicles: Vehicle[]
}

const ChecklistForm: React.FC<ChecklistFormProps> = ({
  user,
  date_driver_id,
  checklist,
  checklistItems,
  vehicles,
}) => {
  let toast = useToast()
  let [isSaving, setIsSaving] = React.useState(false)
  let [commentsVisible, setCommentsVisible] = React.useState(() =>
    checklist ? checklist.responses.filter((r) => !!r.comments).map((r) => r.item_id) : []
  )
  let { createChecklistEntry, updateChecklistEntry } = useChecklistMutations()
  let groupedChecklistItems = groupBy(checklistItems, (i) => i.group)

  const onSuccess = React.useCallback(
    async (_response, args) => {
      if (!args.id) {
        await queryCache.invalidateQueries('checklist-entry')
      }

      toast({
        title: 'Success',
        description: 'Checklist Saved',
        status: 'success',
        duration: 4000,
        isClosable: false,
        position: 'top',
      })
    },
    [toast]
  )

  const onError = React.useCallback(
    async (...args) => {
      toast({
        title: 'Whoops',
        description: 'Unable to save checklist',
        status: 'error',
        duration: 4000,
        isClosable: false,
        position: 'top',
      })
    },
    [toast]
  )

  const onSettled = React.useCallback(async (...args) => {
    setIsSaving(false)
  }, [])

  return (
    <Formik
      enableReinitialize
      initialValues={makeInitialValues(checklist)}
      onSubmit={async (values: FormValues, formikActions: FormikProps<FormValues>) => {
        setIsSaving(true)

        let responses = checklistItems.map((item) => ({
          item_id: item.id,
          group: item.group,
          text: item.text,
          index: item.index,
          response: values.responses[item.id] || null,
          comments: values.comments[item.id] || null,
        }))

        if (checklist) {
          await updateChecklistEntry(
            { id: checklist.id, responses },
            { onSuccess, onError, onSettled }
          )
        } else {
          let defaultVehicle = vehicles.find((v) => v.id === user.default_vehicle_id)

          await createChecklistEntry(
            {
              // date_driver_id,
              date: date_driver_id.split('#')[0],
              driver_id: user.id,
              driver_name: user.name,
              vehicle_id: defaultVehicle ? defaultVehicle.id : null,
              vehicle_name: defaultVehicle ? defaultVehicle.name : null,
              responses,
            },
            { onSuccess, onError, onSettled }
          )
        }
      }}
    >
      {(formikBag: FormikProps<FormValues>) => (
        <Form>
          <Stack spacing={4} overflow="scroll" mb="80px">
            {Object.keys(groupedChecklistItems).map((groupKey) => (
              <Stack key={groupKey} spacing={4}>
                <Row width="100%" justifyContent="space-between" px={4} py={2} bg="gray.200">
                  <Box flex="1" fontSize="lg">
                    {groupKey}
                  </Box>

                  <Box>
                    <MultiEntryMenu
                      onChange={({ response }) => {
                        groupedChecklistItems[groupKey].forEach((item) => {
                          formikBag.setFieldValue(`responses.${item.id}`, response)
                        })
                      }}
                    />
                  </Box>
                </Row>

                {groupedChecklistItems[groupKey].map((item) => {
                  let areCommentsVisible = commentsVisible.includes(item.id)

                  return (
                    <Stack key={item.id} isInline spacing={2} alignItems="flex-start" px={4}>
                      <Box flex="0 0 3.5rem">
                        <Box minHeight="2rem">
                          <Field name={`responses.${item.id}`} component={EntryMenu} />
                        </Box>
                      </Box>

                      <Stack flex="1 1">
                        <Row
                          minHeight="2rem"
                          alignItems="center"
                          onClick={() => {
                            if (formikBag.values.comments[item.id]) return

                            setCommentsVisible((state) =>
                              areCommentsVisible
                                ? state.filter((id) => id !== item.id)
                                : [...state, item.id]
                            )
                          }}
                        >
                          {item.text}
                        </Row>

                        {areCommentsVisible && (
                          <Box>
                            <Field
                              // label="Comments"
                              name={`comments.${item.id}`}
                              component={FormikElements.Textarea}
                              textareaProps={{
                                size: 'sm',
                                placeholder: 'Comments',
                                css: { minHeight: 58 },
                              }}
                            />
                          </Box>
                        )}
                      </Stack>
                    </Stack>
                  )
                })}
              </Stack>
            ))}

            {/* <Box>
              <pre css={{ fontSize: 11 }}>{JSON.stringify(formikBag, null, 2)}</pre>
            </Box> */}
          </Stack>

          <Row
            justifyContent="flex-end"
            position={['fixed', 'fixed', 'relative']}
            bottom={0}
            right={0}
            width="100%"
            bg="gray.50"
            px={4}
            py={2}
          >
            <Button type="submit" colorScheme="purple" isLoading={isSaving}>
              Save
            </Button>
          </Row>
        </Form>
      )}
    </Formik>
  )
}

///////////////////////////////////////////////////////////////////////////////////////////////////

const menuStyle = (value: string) => {
  switch (value) {
    case 'ok':
      return { color: 'green.500', icon: <Icon as={CheckIcon} /> }

    case 'warning':
      return { color: 'yellow.500', icon: <Icon as={WarningTwoIcon} /> }

    case 'fail':
      return { color: 'red.600', icon: <Icon as={WarningIcon} /> }

    default:
      return { color: 'gray.300', icon: <Icon as={MinusIcon} /> }
  }
}

const EntryMenu = ({ field, form }) => {
  let { icon, color } = menuStyle(field.value)

  return (
    <Menu>
      <MenuButton
        as={Button}
        {...{ size: 'sm', width: '100%', isLoading: false }}
        color="white"
        bg={color}
      >
        <Row flex={1} justifyContent="center" alignItems="center">
          {icon}
        </Row>
      </MenuButton>

      <MenuList width="120px">
        <MenuItem onClick={() => form.setFieldValue(field.name, 'ok')}>
          <Row alignItems="center">
            <Box mr={2}>
              <Icon as={CheckIcon} />
            </Box>
            <Box>Okay</Box>
          </Row>
        </MenuItem>

        <MenuItem onClick={() => form.setFieldValue(field.name, 'warning')}>
          <Row alignItems="center">
            <Box mr={2}>
              <Icon as={WarningTwoIcon} />
            </Box>
            <Box>Warning</Box>
          </Row>
        </MenuItem>

        <MenuItem onClick={() => form.setFieldValue(field.name, 'fail')}>
          <Row alignItems="center">
            <Box mr={2}>
              <Icon as={WarningIcon} />
            </Box>
            <Box>Fail</Box>
          </Row>
        </MenuItem>
      </MenuList>
    </Menu>
  )
}

const MultiEntryMenu = ({ onChange }) => {
  return (
    <Menu>
      <MenuButton as={Button} {...{ size: 'sm', width: '100%', isLoading: false }}>
        <Row flex={1} justifyContent="center" alignItems="center">
          <Icon as={MinusIcon} />
        </Row>
      </MenuButton>

      <MenuList width="120px" placement="left">
        <MenuItem onClick={() => onChange({ response: 'ok' })}>
          <Row alignItems="center">
            <Box mr={2}>
              <Icon as={CheckIcon} />
            </Box>
            <Box>Okay</Box>
          </Row>
        </MenuItem>

        <MenuItem onClick={() => onChange({ response: 'warning' })}>
          <Row alignItems="center">
            <Box mr={2}>
              <Icon as={WarningTwoIcon} />
            </Box>
            <Box>Warning</Box>
          </Row>
        </MenuItem>

        <MenuItem onClick={() => onChange({ response: 'fail' })}>
          <Row alignItems="center">
            <Box mr={2}>
              <Icon as={WarningIcon} />
            </Box>
            <Box>Fail</Box>
          </Row>
        </MenuItem>
      </MenuList>
    </Menu>
  )
}

///////////////////////////////////////////////////////////////////////////////////////////////////

const Loading = () => (
  <Row width="100%" height="250px" alignItems="center" justifyContent="center">
    <Spinner speed="0.65s" size="xl" />
  </Row>
)

const ErrorMessage = () => (
  <Box position="relative" width="100%" height="100vh">
    <Stack spacing={6} width="420px" mt="25%" mx="auto" textAlign="center">
      <Box fontSize="lg" color="red.700">
        <Icon as={WarningIcon} size="2.25em" color="red.700" />
      </Box>

      <Box fontSize="2em" color="gray.600">
        An error occurred
      </Box>

      <Box>
        <Button colorScheme="gray" onClick={() => window.location.reload()}>
          Reload Page
        </Button>
      </Box>
    </Stack>
  </Box>
)
