import { useState, useEffect, useMemo } from 'react'
import { Moment } from 'moment'
import { replace } from 'src/routing'
import {
  useBatchBootstrap,
  useVendors,
  useCustomers,
  useDriverSchedules,
  useDeliveries,
} from 'src/queries'
import { useAuthState } from 'src/store/auth'
import { useCalendar } from 'src/store/calendar'
import { mapEvents, mapResources } from 'src/schedule/calendars/utils'
import * as utils from 'src/utils'
import * as queryUtils from 'src/utils/queries'

const createDateRange = (marker: Moment) => ({
  startDate: marker.clone().utc().subtract(1, 'month').format(),
  endDate: marker.clone().utc().add(1, 'month').format(),
})

///////////////////////////////////////////////////////////////////////////////////////////////////

const calendarSelector = ({ view, setView, markerDate }) => ({ view, setView, markerDate })

export const useSchedule = ({ statusFilters }: { statusFilters: string[] }) => {
  const { isAdmin, user } = useAuthState()
  const { view, markerDate } = useCalendar(calendarSelector)
  const [{ startDate, endDate }, setQueryDates] = useState(() => createDateRange(markerDate))

  const { batchQuery, drivers } = useCommons()
  const driverSchedulesQuery = useDriverSchedules({ range_start: startDate, range_end: endDate })
  const deliveriesQuery = useDeliveries({ range_start: startDate, range_end: endDate })

  const allQueries = [batchQuery, driverSchedulesQuery, deliveriesQuery]
  const filterUserId = isAdmin ? false : user.id

  const deliveries = useMemo(
    () =>
      (deliveriesQuery.data || [])
        .filter((d) => !filterUserId || d.driver_id === filterUserId)
        .filter((d) => statusFilters.includes(d.status)),
    [filterUserId, deliveriesQuery.data, statusFilters]
  )

  useEffect(() => {
    const start = utils.momentLocalizedParse(startDate)
    const end = utils.momentLocalizedParse(endDate)
    const isInsideRange = markerDate.isAfter(start) && markerDate.isBefore(end)

    let shouldUpdate = false

    if (isInsideRange) {
      // moved around inside the currently-loaded range
      const daysToStart = Math.abs(markerDate.diff(start, 'days'))
      const daysToEnd = Math.abs(markerDate.diff(end, 'days'))

      if (daysToStart < 7 || daysToEnd < 7) {
        // currently < 1wk away from currently-loaded edges, update
        shouldUpdate = true
      }
    } else {
      // jumped outside the currently-loaded range, update
      shouldUpdate = true
    }

    if (shouldUpdate) setQueryDates(createDateRange(markerDate))
  }, [markerDate, endDate, startDate])

  return {
    anyLoading: queryUtils.areAnyLoading(...allQueries),
    anyFailed: queryUtils.areAnyFailed(...allQueries),
    allLoaded: queryUtils.areAllLoaded(...allQueries),

    deliveries,

    resources: useMemo(
      () => mapResources(drivers, driverSchedulesQuery.data),
      [drivers, driverSchedulesQuery.data]
    ),

    events: useMemo(
      () => mapEvents({ drivers, list: deliveries, view }),
      [drivers, deliveries, view]
    ),
  }
}

export function useCommons() {
  const batchQuery = useBatchBootstrap()

  return {
    batchQuery,

    tenant: batchQuery.data?.tenant,

    drivers: useMemo(
      () => (batchQuery.data?.users || []).filter((u) => u.role === 'driver'),
      [batchQuery.data]
    ),

    vehicles: useMemo(() => batchQuery.data?.vehicles || [], [batchQuery.data]),

    services: batchQuery.data?.services,
  }
}

export function useVendorsAndCustomers() {
  const vendorsQuery = useVendors()
  const customersQuery = useCustomers()

  return {
    vendorsQuery,
    vendors: useMemo(() => vendorsQuery.data || [], [vendorsQuery.data]),

    customersQuery,
    customers: useMemo(() => customersQuery.data || [], [customersQuery.data]),
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////

interface useUrlSyncArgs {
  view: string
  // markerDate: Moment
  markerDate: any
  routeId: string
}

export function useUrlSync({ view, markerDate, routeId }: useUrlSyncArgs) {
  useEffect(() => {
    if (routeId) return
    replace(`/schedule/${view}/${markerDate.format('YYYY-MM-DD')}`)
  }, [view, markerDate, routeId])
}
