import React, { useState, useEffect } from 'react'
import { replace } from 'src/routing'
import queryString from 'query-string'
import { Row, Spinner } from 'src/ui'
import * as api from 'src/api'

export function SessionCallback() {
  const [{ status }, setState] = useState({ status: 'loading', result: null })

  useEffect(() => {
    const queryParams = queryString.parse(window.location.search)
    const session_id = queryParams?.session_id as string
    const invoice_id = queryParams?.invoice_id as string

    async function run() {
      const [err, response] = await api.postSessionComplete({ session_id, invoice_id })

      if (err) {
        setState({ status: 'error', result: response?.data })
        return
      }

      replace('/customer')
    }

    run()
  }, [])

  if (status === 'loading') {
    return (
      <Row w="100%" mt="20vh" justifyContent="center">
        <Spinner speed="0.65s" size="xl" />
      </Row>
    )
  }

  if (status === 'error') {
    return (
      <Row w="100%" mt="20vh" justifyContent="center">
        There was an error completing your checkout.
      </Row>
    )
  }

  return null
}
