import React from 'react'
import { get } from 'lodash'
import { FieldProps } from 'formik'
import DatePicker from 'src/ui/date-picker'
import { FormControl, FormLabel, FormErrorMessage } from '@chakra-ui/react'
import * as utils from 'src/utils'

interface FormikDatePickerProps extends FieldProps<any> {
  disabled: boolean
  id: string
  label: string
  datePickerProps: any
  inputProps: any
}

const FormikDatePicker: React.FC<FormikDatePickerProps> = ({
  field,
  form,
  disabled,
  id,
  label,
  datePickerProps,
  inputProps,
}) => {
  let isTouched = true === get(form.touched, field.name)
  let errorMessage = get(form.errors, field.name)
  let hasError = isTouched && !!errorMessage
  let dateValue = field.value
    ? utils.utcStringToLocalDate(field.value)
    : utils.utcStringToLocalDate()

  return (
    <FormControl isInvalid={hasError}>
      {label && <FormLabel htmlFor={id || field.name}>{label}</FormLabel>}

      <DatePicker
        id={field.name}
        name={field.name}
        value={dateValue}
        showTimeSelect
        disabled={disabled}
        onChange={(date: Date) =>
          form.setFieldValue(field.name, utils.momentLocalizedParseDate(date) as any)
        }
        onBlur={field.onBlur}
        inputProps={inputProps}
        {...datePickerProps}
      />
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  )
}

export default FormikDatePicker
