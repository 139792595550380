import React from 'react'
import { get } from 'lodash'
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Radio as ChakraRadio,
  RadioGroup as ChakraRadioGroup,
  // HStack,
} from '@chakra-ui/react'

// type Option = {
//   value: string | number
//   label: string
// }

const FormikRadioGroup = ({ field, form, options, disabled, id, label }) => {
  let isTouched = true === get(form.touched, field.name)
  let errorMessage = get(form.errors, field.name)
  let hasError = isTouched && !!errorMessage

  return (
    <FormControl isInvalid={hasError}>
      {label && (
        <FormLabel htmlFor={id || field.name} fontSize="sm">
          {label}
        </FormLabel>
      )}

      <ChakraRadioGroup
        id={id}
        name={field.name}
        value={field.value}
        onBlur={field.onBlur}
        onChange={(item) => {
          form.setFieldValue(field.name, item ?? '')
        }}
      >
        {/* <HStack spacing={2} justify={options.length > 2 ? 'space-between' : 'flex-start'}></HStack> */}

        {options.map((opt) => (
          <ChakraRadio key={opt.value} isInvalid={hasError} value={opt.value} size="sm" isFullWidth>
            {opt.label}
          </ChakraRadio>
        ))}
      </ChakraRadioGroup>

      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  )
}

export default FormikRadioGroup
