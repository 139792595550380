import React, { useEffect, useCallback, useMemo, useRef } from 'react'
import { FormikProps } from 'formik'
import { queryCache, useQuery } from 'react-query'
import { Box, useToast } from 'src/ui'
import * as api from 'src/api'
import * as utils from 'src/utils'
import { useCommons, useVendorsAndCustomers } from 'src/schedule/hooks'

export type FormValues = Partial<Delivery>
export type FormikBag = FormikProps<FormValues>

export function useFormData() {
  const { drivers, vehicles, services, batchQuery } = useCommons()
  const { vendors, customers, vendorsQuery, customersQuery } = useVendorsAndCustomers()
  const queries = [batchQuery, vendorsQuery, customersQuery]

  const isLoading = queries.some((q) => q.isLoading)
  const isSuccess = queries.every((q) => q.isSuccess)

  return {
    isLoading,
    isSuccess,

    drivers,
    vehicles,
    vendors,
    customers,
    services,

    driverOptions: useMemo(
      () =>
        drivers.map((driver) => ({
          value: driver.id,
          label: driver.name,
        })),
      [drivers]
    ),

    vehicleOptions: useMemo(
      () =>
        vehicles.map((vehicle) => ({
          value: vehicle.id,
          label: vehicle.name,
        })),
      [vehicles]
    ),

    vendorNameOptions: useMemo(
      () => vendors.map((v) => ({ label: v.name, value: v.name })),
      [vendors]
    ),

    customerNameOptions: useMemo(
      () => customers.map((v) => ({ label: v.name, value: v.name })),
      [customers]
    ),
  }
}

export function useSubmitHandlers({ stopEditing }) {
  const toast = useToast()

  return {
    onSuccess: useCallback(
      async (_response, args) => {
        await queryCache.invalidateQueries('deliveries')

        stopEditing()

        toast({
          title: 'Success',
          description: 'Delivery Saved',
          status: 'success',
          duration: 4000,
          isClosable: false,
          position: 'top',
        })
      },
      [toast, stopEditing]
    ),

    onError: useCallback(
      async (...args) => {
        toast({
          title: 'Whoops',
          description: 'Unable to save delivery',
          status: 'error',
          duration: 4000,
          isClosable: false,
          position: 'top',
        })
      },
      [toast]
    ),

    // onSettled: useCallback(async (...args) => {}, []),
  }
}

export function getVendorRate({ id, vendors }) {
  if (!id) return null

  const vendor = vendors.find((v) => v.id === id)
  const rate = vendor ? vendor.rate || null : null

  if (!rate) return null

  return (
    <Box as="span" fontStyle="italic">
      Rate{' '}
      <Box as="span" fontWeight={500}>
        {utils.formatMoney(rate)}
      </Box>
    </Box>
  )
}

///////////////////////////////////////////////////////////////////////////////

export function addressLoadOptions(value) {
  return new Promise(async (yah, nah) => {
    let [err, response] = await api.getAddressSearch(value)
    if (err) return yah([])

    if (!response.data || response.data.data.length === 0) return yah([])

    return yah(response.data.data.map((item) => ({ label: item.label, value: item.label })))
  })
}

///////////////////////////////////////////////////////////////////////////////

export async function handleVendorChange({ selected, vendors, formikBag, createVendor }) {
  if (!selected) return

  let nextVendor = vendors.find((v) => v.name === selected.label)

  if (nextVendor) {
    formikBag.setFieldValue('vendor_id', nextVendor.id)
    formikBag.setFieldValue('vendor_contact', nextVendor.contact)
    formikBag.setFieldValue('pickup_address', nextVendor.address)
    formikBag.setFieldValue('vendor_phone', utils.prettyPhoneNumber(nextVendor.phone))

    return
  }

  // create one
  formikBag.setFieldValue('vendor_contact', '')
  formikBag.setFieldValue('pickup_address', '')
  formikBag.setFieldValue('vendor_phone', '')

  await createVendor(
    {
      name: selected.label,
    },
    {
      onSuccess: (vendor: Vendor, _values) => {
        formikBag.setFieldValue('vendor_id', vendor?.id)
      },
      onError: (...args) => {
        console.log('onError', { ...args })
      },
    }
  )
}

///////////////////////////////////////////////////////////////////////////////////////////////////

function useBillingQueries({ customerId, serviceId, serviceVariantId }) {
  return {
    billingQuery: useQuery(
      ['billing-info', { customerId, serviceId, serviceVariantId }],

      async (_key) => {
        const [err, response] = await api.getCustomerBillingInfoById({
          customerId,
          serviceId,
          serviceVariantId,
        })

        if (err) throw err
        return response.data
      },
      { enabled: !!customerId }
    ),

    variantsQuery: useQuery(
      ['billing-variants', { customerId, serviceId }],

      async (_key) => {
        const [err, response] = await api.getCustomerBillingVariants({ customerId, serviceId })

        if (err) throw err
        return response.data.options
      },
      { enabled: !!(customerId && serviceId) }
    ),
  }
}

export function useBilling({ formikBag }: { formikBag: FormikBag }) {
  const { values, setFieldValue } = formikBag

  const customerId = values.customer_id
  const serviceId = values.service_id
  const serviceVariantId = values.service_variant_id
  const price = values.price as unknown as string

  const { billingQuery, variantsQuery } = useBillingQueries({
    customerId,
    serviceId,
    serviceVariantId,
  })

  const billingInfo = billingQuery.data
  const candidateSubscription = billingInfo?.candidateSubscription ?? null
  const selectedService = billingInfo?.service
  const selectedServiceVariant = billingInfo?.serviceVariant
  const pricePerService = billingInfo?.breakdown?.price_per_service
  const usesCustomPrice = !!selectedServiceVariant?.uses_custom_price
  const isSelectedVariantSubscription = !!selectedServiceVariant?.is_subscription

  useEffect(() => {
    if (!serviceVariantId && candidateSubscription) {
      setFieldValue('service_variant_id', `subscription#${candidateSubscription.id}`)
      setFieldValue('payment_method', 'subscription')
    }
  }, [serviceVariantId, candidateSubscription, setFieldValue])

  useEffect(() => {
    if (selectedService && selectedService.variants?.length === 1) {
      setFieldValue('service_variant_id', selectedService.variants?.[0]?.id)
    }
  }, [selectedService, setFieldValue])

  useEffect(() => {
    if (isSelectedVariantSubscription) {
      setFieldValue('payment_method', 'subscription')
      setFieldValue('price', '')
    } else {
      setFieldValue('payment_method', null)
    }
  }, [isSelectedVariantSubscription, setFieldValue])

  useEffect(() => {
    const priceAsString = pricePerService?.toFixed(2)

    if (!usesCustomPrice && pricePerService && price !== priceAsString) {
      setFieldValue('price', pricePerService.toFixed(2))
    }
  }, [price, usesCustomPrice, pricePerService, setFieldValue])

  return {
    billingQuery,
    variantOptions: variantsQuery.data ?? [],
    billingInfo: billingQuery.data,
    candidateSubscription: billingInfo?.candidateSubscription ?? null,
    selectedService: billingInfo?.service,
    selectedServiceVariant: billingInfo?.serviceVariant,
    serviceBreakdown: billingInfo?.breakdown,
    pricePerService: billingInfo?.breakdown?.price_per_service,
    isSelectedVariantSubscription: !!selectedServiceVariant?.is_subscription,
    usesCustomPrice: !!selectedServiceVariant?.uses_custom_price,
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////

export function useCustomerChange({
  delivery,
  formikBag,
  customers,
  createCustomer,
}: {
  delivery: Delivery
  formikBag: FormikBag
  customers: Customer[]
  createCustomer: (arg1: any, arg2: any) => void
}) {
  const isMountedRef = useRef(false)
  const { values, setValues } = formikBag
  const deliveryCustomerName = delivery?.customer_name
  const selectedCustomerName = values.customer_name

  const origDeliveryCustomerBits = useMemo(() => {
    if (!delivery) return {}

    return {
      customer_id: delivery.customer_id,
      customer_name: delivery.customer_name,
      delivery_address: delivery.delivery_address,
      customer_phone: utils.prettyPhoneNumber(delivery.customer_phone),
      customer_email: delivery.customer_email,
    }
  }, [delivery])

  const handleCustomerChange = useCallback(
    (selectedCustomerName: string) => {
      if (selectedCustomerName === deliveryCustomerName) {
        // back to delivery's original values
        setValues((state: FormValues) => ({ ...state, ...origDeliveryCustomerBits }))
        return
      }

      const nextCustomer = customers.find((v) => v.name === selectedCustomerName)

      if (nextCustomer) {
        // auto-fill other customer fields
        setValues((state: FormValues) => ({
          ...state,
          customer_id: nextCustomer.id,
          customer_name: selectedCustomerName,
          delivery_address: nextCustomer.address,
          customer_phone: utils.prettyPhoneNumber(nextCustomer.phone),
          customer_email: nextCustomer.email,
        }))
      } else {
        // create new customer
        handleCreateCustomer({
          setValues,
          customer: { name: selectedCustomerName },
          createCustomer,
        })
      }
    },
    [customers, deliveryCustomerName, origDeliveryCustomerBits, createCustomer, setValues]
  )

  useEffect(() => {
    if (!isMountedRef.current) {
      isMountedRef.current = true
      return
    }

    if (!selectedCustomerName) return

    handleCustomerChange(selectedCustomerName)
  }, [selectedCustomerName, handleCustomerChange])
}

async function handleCreateCustomer({ setValues, customer, createCustomer }) {
  setValues((state: FormValues) => ({
    ...state,
    customer_id: '',
    customer_phone: '',
    customer_email: '',
    delivery_address: '',
  }))

  await createCustomer(
    //
    customer,
    {
      onSuccess: async (customer: Customer, _values) => {
        setValues((state: FormValues) => ({ ...state, customer_id: customer.id }))
        await queryCache.invalidateQueries('customers')
      },
      onError: (...args) => {
        console.log('onError', { ...args })
      },
    }
  )
}

///////////////////////////////////////////////////////////////////////////////////////////////////

export async function handleCustomerUpdate({ apiKey, formKey, formikBag, updateCustomer }) {
  if (!formikBag.values.customer_id) {
    console.log('cannot update customer -- no id')
    return
  }

  await updateCustomer(
    {
      id: formikBag.values.customer_id,
      [apiKey]: formikBag.values[formKey],
    },
    {
      onSuccess: (customer: Customer, _values) => {
        formikBag.setFieldValue(formKey, customer[apiKey])
      },
      onError: (...args) => {
        console.log('onError', { ...args })
      },
    }
  )
}
