import React from 'react'
import { Helmet } from 'react-helmet'
import { ReactQueryConfigProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query-devtools'
import { ChakraProvider } from '@chakra-ui/react'
import * as Routing from 'src/routing'
import ErrorBoundary from 'src/app/error-boundary'
import Layout from 'src/app/layout'
import Login from 'src/auth/login'
import Schedule from 'src/schedule'
import Customer from 'src/customer'
import Deliveries from 'src/deliveries'
import Manage from 'src/manage'
import Checklist from 'src/checklist'
import { GlobalStyles } from 'src/styles'
import { useConfig } from 'src/utils/config'

const ReportsIndex = React.lazy(() => import('src/reports'))

const ReportsLazy = (props) => (
  <React.Suspense fallback={null}>
    <ReportsIndex {...props} />
  </React.Suspense>
)

const App = () => {
  const { text } = useConfig()

  return (
    <ChakraProvider>
      <GlobalStyles />
      <Helmet>
        <title>{text('app_title')}</title>
      </Helmet>

      <ErrorBoundary>
        <Layout>
          <Routing.FlexRouter styles={{ height: '100%' }}>
            <Customer path="/customer/*" />
            <Routing.PublicRoute path="/login" as={Login} />
            <Routing.AuthRoute path="/logout" as={Routing.Logout} />
            <Routing.AuthRoute path="/" as={Routing.RootRedirect} />
            <Routing.EmployeeRoute path="/schedule/*" as={Schedule} />
            <Routing.DriverRoute path="/checklist/*" as={Checklist} />
            <Routing.AdminRoute path="/deliveries/*" as={Deliveries} />
            <Routing.AdminRoute path="/reports/*" as={ReportsLazy} />
            <Routing.AdminRoute path="/manage/*" as={Manage} />
            <Routing.NotFound default />
          </Routing.FlexRouter>
        </Layout>
      </ErrorBoundary>
    </ChakraProvider>
  )
}

const REACT_QUERY_CONFIG = {
  queries: {
    refetchOnWindowFocus: false,
    retry: false,
  },
}

const AppWrapper = () => (
  <>
    <ReactQueryConfigProvider config={REACT_QUERY_CONFIG}>
      <App />
    </ReactQueryConfigProvider>

    <ReactQueryDevtools />
  </>
)

export default AppWrapper
