import React, { useState, useCallback, ReactElement, PropsWithChildren } from 'react'
import moment from 'moment'
import { CheckCircleIcon, ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { Table, Thead, Tbody, Tr, Th, Td, Badge } from '@chakra-ui/react'
import { Box, Row, Stack, Button, IconButton, Icon, Divider, Collapse, Heading } from 'src/ui'
import { LS_KEYS, getLocalStorageKey } from 'src/utils/local-storage'
import { formatMoney } from 'src/utils'
import * as api from 'src/api'

const API_BASE_URL = api.apiUrl()

const BILLING_ITEM_COMPONENTS = {
  updated_billing: UpdatedBilling,
  subscription_deleted: SubscriptionDeleted,
  invoice_subscription_start: InvoiceItem,
  invoice_on_demand: InvoiceItem,
  invoice_recurring: InvoicePaid,
}

export function BillingActivity({
  items,
  allowActions = false,
}: {
  items: BillingActivity[]
  allowActions?: boolean
}) {
  if (!items || items.length === 0) {
    return (
      <Row my={4} color="gray.600" fontStyle="italic">
        No billing activity yet
      </Row>
    )
  }

  return (
    <>
      <Divider my={4} />

      <Heading size="xs" mb={4}>
        Activity
      </Heading>

      <Stack spacing={0}>
        {items?.map((item, i) => {
          const EventComponent = BILLING_ITEM_COMPONENTS[item.type]

          if (!EventComponent) {
            return (
              <Box key={`unknown-${i}`} py={4}>
                unknown event "{item.type}"
              </Box>
            )
          }

          return (
            <EventComponent
              key={`${item.type}#${item.created_at}`}
              item={item}
              index={i}
              allowActions={allowActions}
            />
          )
        })}
      </Stack>
    </>
  )
}

///////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////

function UpdatedBilling({ item, index }: { item: BillingActivity; index: number }) {
  return (
    <ItemCard
      key={item.type}
      index={index}
      header={
        <ItemHeader timestamp={formatTimestamp(item.created_at)} title="Updated Payment Method" />
      }
    />
  )
}

///////////////////////////////////////////////////////////////////////////////////////////////////

function SubscriptionDeleted({ item, index }: { item: BillingActivity; index: number }) {
  return (
    <ItemCard
      key={item.type}
      index={index}
      header={
        <ItemHeader timestamp={formatTimestamp(item.created_at)} title="Subscription Canceled" />
      }
    />
  )
}

///////////////////////////////////////////////////////////////////////////////////////////////////

function InvoicePaid({ item, index }: { item: BillingActivity; index: number }) {
  const [isExpanded, setIsExpanded] = useState(false)
  const toggleExpand = useCallback(() => setIsExpanded((v) => !v), [])

  const data = item.data
  const createdAtFormatted = formatTimestamp(item.created_at)
  const amountPaidFormatted = formatAmount(data.amount_paid)
  // const amountFormatted = formatAmount(event.amount)

  return (
    <ItemCard
      key={item.type}
      index={index}
      isExpanded={isExpanded}
      header={
        <ItemHeader
          timestamp={createdAtFormatted}
          title="Invoice - Monthly Payment"
          amount={amountPaidFormatted}
          isExpandable
          isExpanded={isExpanded}
          toggleExpand={toggleExpand}
        />
      }
    >
      <Collapse in={isExpanded}>
        <Row justifyContent="flex-end" alignItems="center" my={2}>
          <Box>
            <Button size="xs" variant="link" as="a" href={data?.hosted_invoice_url} target="_blank">
              View Invoice
            </Button>
          </Box>
        </Row>

        <Table size="sm">
          <Thead>
            <Tr>
              <Th>Line Items</Th>
              <Th></Th>
              <Th textAlign="right">Price</Th>
            </Tr>
          </Thead>

          <Tbody>
            {data?.lines?.map((line) => (
              <Tr key={line.id}>
                <Td>{line.type}</Td>
                <Td>
                  {line.description}
                  {line.type === 'subscription' && (
                    <>
                      <br />
                      {formatEpoch(line.period.start)}
                      {' - '}
                      {formatEpoch(line.period.end)}
                    </>
                  )}
                </Td>
                <Td textAlign="right">{formatAmount(line.amount)}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Collapse>
    </ItemCard>
  )
}

///////////////////////////////////////////////////////////////////////////////////////////////////

function InvoiceItem({
  item,
  index,
  allowActions,
}: {
  item: BillingActivity
  index: number
  allowActions: boolean
}) {
  const [isExpanded, setIsExpanded] = useState(false)
  const toggleExpand = useCallback(() => setIsExpanded((v) => !v), [])

  const data = item.data
  const isOnDemand = item.type === 'invoice_on_demand'
  const isPaid = data.invoice_status === 'paid'
  const invoiceAmount = data.invoice_amount

  // const createdAtFormatted = formatTimestamp(item.created_at)
  // const amountFormatted = formatAmount(event.amount)
  // const amountPaidFormatted = formatAmount(event.amount_paid)

  return (
    <ItemCard
      index={index}
      isExpanded={isExpanded}
      header={
        <ItemHeader
          timestamp={formatTimestamp(item.created_at)}
          title={
            <>
              Invoice
              {' | '}
              {data.service_name}
              {' | '}
              {isOnDemand ? 'On-Demand' : 'Subscription Started'}
              {false && (
                <>
                  &nbsp;&nbsp;
                  <Badge textTransform="unset">{data.invoice_id}</Badge>
                </>
              )}
            </>
          }
          right={
            <>
              {data.invoice_status === 'unpaid' &&
                (allowActions ? (
                  <CheckoutButton invoiceId={data.invoice_id}>Pay ${invoiceAmount}</CheckoutButton>
                ) : (
                  <Badge size="xs" colorScheme="purple">
                    Unpaid ${invoiceAmount}
                  </Badge>
                ))}
              {data.invoice_status === 'paid' && (
                <Button size="xs" w="100px" disabled leftIcon={<CheckCircleIcon />}>
                  Paid ${invoiceAmount}
                </Button>
              )}
            </>
          }
          isExpandable={isPaid}
          isExpanded={isPaid && isExpanded}
          toggleExpand={isPaid ? toggleExpand : undefined}
        />
      }
    >
      <Collapse in={isExpanded}>
        <Row justifyContent="flex-end" alignItems="center" my={2}>
          <Box>
            <Button size="xs" variant="link" as="a" href={data?.hosted_invoice_url} target="_blank">
              View Invoice
            </Button>
          </Box>
        </Row>

        <Table size="sm">
          <Thead>
            <Tr>
              <Th>Line Items</Th>
              <Th></Th>
              <Th textAlign="right">Price</Th>
            </Tr>
          </Thead>

          <Tbody>
            {data?.lines?.map((line) => (
              <Tr key={line.id}>
                <Td>{line.type || 'item'}</Td>
                <Td>
                  {line.description}
                  {line.type === 'subscription' && (
                    <>
                      <br />
                      {formatEpoch(line.period.start)}
                      {' - '}
                      {formatEpoch(line.period.end)}
                    </>
                  )}
                </Td>
                <Td textAlign="right">{formatAmount(line.amount || line.amount_total)}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Collapse>
    </ItemCard>
  )
}

///////////////////////////////////////////////////////////////////////////////////////////////////

function CheckoutButton({
  invoiceId,
  children = 'Pay now',
}: PropsWithChildren<{ invoiceId: string }>) {
  const [isLoading, setIsLoading] = useState(false)
  const storedAuth = getLocalStorageKey(LS_KEYS.AUTH)

  return (
    <form
      action={`${API_BASE_URL}/client/billing/session`}
      method="POST"
      onSubmit={() => setIsLoading(true)}
    >
      <input type="hidden" name="authorization" value={storedAuth?.token} />
      <input type="hidden" name="invoice_id" value={invoiceId} />
      <Button type="submit" size="xs" w="100px" colorScheme="purple" isLoading={isLoading}>
        {children}
      </Button>
    </form>
  )
}

///////////////////////////////////////////////////////////////////////////////////////////////////

function ItemCard({
  index,
  header,
  isExpanded = false,
  children = null,
}: PropsWithChildren<{ index: number; header: ReactElement; isExpanded?: boolean }>) {
  return (
    <Box
      p={2}
      mb={isExpanded ? 2 : undefined}
      bg={index % 2 === 0 ? 'gray.50' : undefined}
      boxShadow={isExpanded ? 'md' : undefined}
      borderRadius={2}
    >
      {header}
      {children}
    </Box>
  )
}

function ItemHeader({
  timestamp,
  title,
  right = null,
  amount = null,
  isExpandable = false,
  isExpanded = false,
  toggleExpand = undefined,
}) {
  return (
    <Row h="24px" alignItems="center" onClick={toggleExpand} cursor={isExpandable && 'pointer'}>
      <Row w="32px" alignItems="center" justifyContent="center">
        {isExpandable ? (
          <IconButton
            icon={<Icon as={isExpanded ? ChevronDownIcon : ChevronRightIcon} boxSize="1rem" />}
            size="xs"
            variant="ghost"
            aria-label="Expand"
          />
        ) : (
          <Box mt="-6px" color="gray.600" fontSize="2.5rem" lineHeight="0">
            ·
          </Box>
        )}
      </Row>
      <Box pr={2}>{timestamp}</Box>
      <Box flex={1} pr={2}>
        {title}
      </Box>
      {right && right}
      {amount && <Box fontWeight="bold">{amount}</Box>}
    </Row>
  )
}

///////////////////////////////////////////////////////////////////////////////////////////////////

function formatAmount(value: number, defaultValue: string = '-') {
  if (value === null || value === undefined) return defaultValue

  return formatMoney(value / 100).replace('.00', '')
  // return `$${value / 100}`
}

function formatEpoch(ts: number) {
  return moment.unix(ts).format('l')
}

function formatTimestamp(ts: string) {
  return moment(ts).format('l')
}
